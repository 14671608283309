import {
  CLEAR_CONTACT_US,
  CONTACT_US_FAILED,
  CONTACT_US_STARTED,
  CONTACT_US_SUCCEEDED,
  GET_CONTENTFUL_LOGO_FAILED,
  GET_CONTENTFUL_LOGO_STARTED,
  GET_CONTENTFUL_LOGO_SUCCEEDED,
  HIDE_MODAL,
  SHOW_MODAL,
  FETCH_EMPLOYEE_LIST_STARTED,
  FETCH_EMPLOYEE_LIST_SUCCEEDED,
  FETCH_EMPLOYEE_LIST_FAILED,
} from '../actions/commonAction';
import { Common, CommonActionTypes } from '../actions/actions';

import { LOGOUT_SUCCEEDED } from '../actions/authAction';
import { initialState } from '../store/configureStore';

export const commonReducer = (
  state: Common = {},
  action: CommonActionTypes
): Common => {
  switch (action.type) {
    case CONTACT_US_STARTED:
      return {
        ...state,
        contactUsLoading: true,
        isContactUsSuccess: false,
        isContactUsError: false,
        contactUsMessage: '',
      };
    case CONTACT_US_SUCCEEDED:
      return {
        ...state,
        contactUsLoading: false,
        isContactUsSuccess: true,
        isContactUsError: false,
        contactUsMessage: action.payload?.contactUsMessage,
      };
    case CONTACT_US_FAILED:
      return {
        ...state,
        contactUsLoading: false,
        isContactUsSuccess: false,
        isContactUsError: true,
        contactUsMessage: action.payload?.contactUsMessage,
      };
    case CLEAR_CONTACT_US:
      return {
        ...state,
        contactUsLoading: false,
        isContactUsSuccess: false,
        isContactUsError: false,
        contactUsMessage: '',
      };
    case SHOW_MODAL:
      return {
        ...state,
        isOpenDialog: true,
        modalName: action.payload?.modalName,
      };
    case HIDE_MODAL:
      return {
        ...state,
        isOpenDialog: false,
        modalName: action.payload?.modalName,
      };
    case GET_CONTENTFUL_LOGO_STARTED:
      return {
        logoLoading: true,
      };
    case GET_CONTENTFUL_LOGO_SUCCEEDED:
      return {
        ...state,
        logoLoading: false,
        logoElement: action.payload?.logoElement,
      };
    case GET_CONTENTFUL_LOGO_FAILED:
      return {
        ...state,
        logoLoading: false,
        logoErrorMessage: 'Something went wrong',
      };
    case FETCH_EMPLOYEE_LIST_STARTED:
      return {
        ...state,
        isEmployeeListLoading: true,
        isEmployeeListError: false,
        employeeListErrorMessage: '',
      };
    case FETCH_EMPLOYEE_LIST_SUCCEEDED:
      return {
        ...state,
        employeeList: action.payload?.employeeList,
        isEmployeeListLoading: false,
        isEmployeeListError: false,
        employeeListErrorMessage: '',
      };
    case FETCH_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        isEmployeeListLoading: false,
        isEmployeeListError: true,
        employeeListErrorMessage: 'Error retrieving employee list',
      };
    case LOGOUT_SUCCEEDED:
      return initialState.commonData
    default:
      return state;
  }
};
