import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { memberReducer } from './memberReducer';
import { commonReducer } from './commonReducer';
import { lionAdminReducer } from './lionAdminReducer';

const rootReducer = combineReducers({
  authData: authReducer,
  memberData: memberReducer,
  commonData: commonReducer,
  lionAdminData: lionAdminReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
