import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { ISearchInputProps } from '../../../types/searchInputProps.interface';
import './search-input.scss';
import { Image } from 'react-bootstrap';
import searchIconImg from '../../../../assets/icons-system-search.svg';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { CommonProps, components, ValueType } from 'react-select';
import { customStyles } from '../../../utils/global-functions';
import chevronDownImg from '../../../../assets/icons/icons-system-chevron-down.svg';
import chevronUpImg from '../../../../assets/icons/icons-system-chevron-up.svg';
import { useDispatch } from 'react-redux';
import { setSelectedBarId } from '../../../../actions/authAction';

type OptionType = { label: string; value: number };

export const SearchInput: React.FC<ISearchInputProps> = (props) => {
  const [inputValue, setInputValue] = useState<ValueType<OptionType>>(null);
  const [message, setMessage] = useState('Input min. 3 characters');
  const [selectedOption, setSelectedOption] = useState(-1);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [field] = useField(props);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.clear) {
      setInputValue(null);
      setSelectedOption(-1);
    }
  }, [props.clear]);

  useEffect(() => {
    if (props.selectValue)
      setInputValue({ label: props.selectValue!, value: props.selectKey!});
  }, [props.selectValue])

  const handleChange = (
    option: OptionType,
    fieldName: string,
    setFieldValue: (
      field: string,
      value: number,
      shouldValidate?: boolean | undefined
    ) => void,
    setClear?: (value: boolean) => void
  ) => {
    if (option) {
      setFieldValue(fieldName, option.value);
      setInputValue(option);
      setSelectedOption(option.value);
      setSearchIsActive(true);
    } else {
      setFieldValue(fieldName, -1);
      setInputValue(null);
      setSelectedOption(-1);
      setSearchIsActive(false);
      dispatch(setSelectedBarId(-1));
    }
    if (setClear) {
      setClear(false);
    }
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    props.setFieldTouched(props.name, true);
    setSelectedOption(0);
  };

  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      CommonProps<any> & {
        children: React.ReactElement<
          any,
          | string
          | ((
              props: any
            ) => React.ReactElement<
              any,
              | string
              | any
              | (new (props: any) => React.Component<any, any, any>)
            > | null)
          | (new (props: any) => React.Component<any, any, any>)
        >;
        innerProps: any;
        isFocused: boolean;
        isRtl: boolean;
        isDisabled: boolean;
      }
  ) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            className="select-dropdown-arrow"
            src={props.selectProps.menuIsOpen ? chevronUpImg : chevronDownImg}
            alt="select arrow"
          />
        </components.DropdownIndicator>
      )
    );
  };


  const LoadingMessage = (
    props: JSX.IntrinsicAttributes &
      CommonProps<any>
  ) => {
    return (
        <div
          style={props.getStyles('loadingMessage', props)}
        >
          {message}
        </div>
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: Array<{ value: number; label: string }>) => void
  ) => {
    if (!inputValue) {
      callback([]);
    } else if (inputValue.length <= 2){
      setMessage('Input min. 3 characters');
    }
    else {
      if (inputValue.length > 2) {
        setMessage('Loading...');
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}Bar/list?searchvalue=${inputValue}`
          )
          .then(function (response) {
            const options = response.data.map(
              (bars: { id: number; name: string }) => ({
                value: bars.id,
                label: bars.name,
              })
            );
            callback(options);
          });
      }
    }
  };

  return (
    <div className="form-group search-form-group">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      {!props.isBarSearch ? (
        <Image
          src={searchIconImg}
          className="icons-system-search"
          alt="Search Icon"
        />
      ) : (
        <button
          type="submit"
          className={
            searchIsActive ? 'icons-search-bar-active' : 'icons-search-bar'
          }
          disabled={!searchIsActive}
        />
      )}
      <AsyncSelect
        value={inputValue}
        components={!props.isBarSearch ? { DropdownIndicator: DropdownIndicator, LoadingMessage: LoadingMessage } : { LoadingMessage }}
        className={`search-input ${
          props.error && selectedOption === 0 ? 'input-error' : ''
        }`}
        loadOptions={loadOptions}
        onBlur={handleBlur}
        {...props}
        noOptionsMessage={() => 'No bars found'}
        onChange={(options: ValueType<OptionType>) =>
          handleChange(
            options as OptionType,
            field.name,
            props.setFieldValue,
            props.setClear
          )
        }
        classNamePrefix="select"
        styles={customStyles}
        isClearable={props.isBarSearch ? true : false}
      />
    </div>
  );
};
