import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import {
  setSelectedEmployeeId,
  showEmployeeDetails,
} from '../../../actions/lionAdminAction';
import { SearchEmployeeInput } from '../../../shared/components/ui/search-input/SearchEmployeeInput';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import './search-employee.scss';

const initFormValues: { employeeId: string } = {
  employeeId: '',
};

const SearchEmployee: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div className="search-employee" data-testid="search-employee">
      <Formik
        initialValues={initFormValues}
        onSubmit={(values) => {
          dispatch(setSelectedEmployeeId(parseInt(values.employeeId)));
          dispatch(showEmployeeDetails());
        }}
      >
        {({ setFieldValue, setFieldTouched, errors, touched }) => (
          <Form>
            <SearchEmployeeInput
              label=""
              isBarSearch={true}
              id="employeeId"
              name="employeeId"
              error={errors.employeeId}
              touched={touched.employeeId}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              placeholder="Search for a employee"
            />
            <ValidationErrorTemplate
              error={errors.employeeId}
              touched={touched.employeeId}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchEmployee;
