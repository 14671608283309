import {
  CHECK_ALREADY_LOGGED_IN,
  CLEAR_LOGIN,
  LOGIN_FAILED,
  LOGIN_STARTED,
  LOGIN_SUCCEEDED,
  LOGOUT_FAILED,
  LOGOUT_STARTED,
  LOGOUT_SUCCEEDED,
  SET_ABOVE_EIGHTEEN,
  SET_NOT_ABOVE_EIGHTEEN,
  SET_SELECTED_BAR_ID,
} from '../actions/authAction';
import { Auth, AuthActionTypes } from '../actions/actions';

export const authReducer = (
  state: Auth = {},
  action: AuthActionTypes
): Auth => {
  switch (action.type) {
    case LOGIN_STARTED:
      return {
        ...state,
        isAuth: false,
        isAuthLoading: true,
        isAuthError: false,
        authErrorMessage: '',
      };
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        isAuth: true,
        isAuthLoading: false,
        isAuthError: false,
        selectedBarId: action.payload?.selectedBarId,
        authErrorMessage: '',
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isAuth: false,
        isAuthLoading: false,
        isAuthError: true,
        authErrorMessage: action.payload?.authErrorMessage,
      };
    case CLEAR_LOGIN:
      return {
        ...state,
        isAuthLoading: false,
        isAuthError: false,
        authErrorMessage: '',
      };
    case LOGOUT_STARTED:
      return {
        ...state,
        isLogoutLoading: true,
        isAuth: false,
      };
    case LOGOUT_SUCCEEDED:
      return {
        ...state,
        isLogoutLoading: false,
        isAuth: false,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        isLogoutLoading: false,
        isAuth: false,
      };
    case SET_ABOVE_EIGHTEEN:
      return {
        ...state,
        isAgeVerified: true,
        ageErrorMessage: '',
      };
    case SET_NOT_ABOVE_EIGHTEEN:
      return {
        ...state,
        isAgeVerified: false,
        ageErrorMessage: action.payload?.ageErrorMessage,
      };
    case CHECK_ALREADY_LOGGED_IN:
      return {
        ...state,
        isAuth: action.payload?.isAuth,
      };
    case SET_SELECTED_BAR_ID:
      return {
        ...state,
        selectedBarId: action.payload?.selectedBarId,
      };
    default:
      return state;
  }
};
