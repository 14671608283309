import React from 'react';
import Button from 'react-bootstrap/Button';
import './register-users.scss';
import { useDispatch } from 'react-redux';
import { setSelectedBarId } from '../../../actions/authAction';
import {
  showBarCreate,
  hideBarCreate,
  hideBarDetails,
  setSelectedEmployeeId,
  showEmployeeCreate,
  hideEmployeeCreate,
  hideEmployeeDetails,
  setSearchBarEmployeePanel,
  setSearchBarPanel,
  hideMemberDetails, fetchMemberList, saveMemberListPagination,
  setSearchLionAdminPanel,
  showLionAdminCreate, clearBarCache, clearBarEmployeeCache, clearMemberCache
} from '../../../actions/lionAdminAction';

const RegisterUsers: React.FC = () => {
  const dispatch = useDispatch();

  const createBar = () => {
    dispatch(setSelectedBarId(-1));
    dispatch(clearBarCache());
    dispatch(setSearchBarPanel());
    dispatch(showBarCreate());
    dispatch(hideBarDetails());

    dispatch(setSelectedEmployeeId(-1));
    dispatch(clearBarEmployeeCache());
    dispatch(hideEmployeeDetails());
    dispatch(hideMemberDetails());
    dispatch(clearMemberCache());
    dispatch(
      saveMemberListPagination(
        1,
        10,
        '',
        'asc',
        'memberId'
      )
    );
    dispatch(fetchMemberList());
    window.scrollTo(0, 0);
  };

  const createBarEmployee = () => {
    dispatch(setSelectedEmployeeId(-1));
    dispatch(clearBarEmployeeCache());
    dispatch(setSearchBarEmployeePanel());
    dispatch(showEmployeeCreate());
    dispatch(hideEmployeeDetails());

    dispatch(setSelectedBarId(-1));
    dispatch(clearBarCache());
    dispatch(hideBarDetails());
    dispatch(hideMemberDetails());
    dispatch(clearMemberCache());
    dispatch(
      saveMemberListPagination(
        1,
        10,
        '',
        'asc',
        'memberId'
      )
    );
    dispatch(fetchMemberList());
    window.scrollTo(0, 0);
  };

  const createLionAdmin = () => {
    dispatch(setSelectedEmployeeId(-1));
    dispatch(clearBarEmployeeCache());
    dispatch(hideEmployeeCreate());
    dispatch(hideEmployeeDetails());

    dispatch(setSelectedBarId(-1));
    dispatch(clearBarCache());
    dispatch(hideBarDetails());
    dispatch(hideBarCreate());

    dispatch(hideMemberDetails());
    dispatch(clearMemberCache());
    dispatch(
      saveMemberListPagination(
        1,
        10,
        '',
        'asc',
        'memberId'
      )
    );
    dispatch(setSearchLionAdminPanel());
    dispatch(showLionAdminCreate())
    window.scrollTo(0, 0);
  };

  return (
    <div className="register-users-dashboard-section">
      <h3 className="register-users-dashboard-section__heading">
        REGISTER
      </h3>
      <div className="register-users-dashboard-section__register-bar-btn">
        <Button variant="primary" type="button" onClick={createBar}>
          Register Bar
        </Button>
        <Button variant="primary" type="button" onClick={createBarEmployee}>
          Register Bar Employee
        </Button>
        <Button variant="primary" type="button" onClick={createLionAdmin}>
          Register Lion Admin
        </Button>
      </div>
    </div>
  );
};

export default RegisterUsers;
