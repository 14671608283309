import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Axios from 'axios';
import { BASE_API_URL } from '../../shared/configs/appConfigs';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { RouteProps } from 'react-router';
import loadIcon from '../../assets/global-element-spinner.svg';
import './registration-confirm.scss'

export interface IRegistrationProps {
  location?: RouteProps['location'];
}

export const RegistrationConfirm: React.FC<IRegistrationProps> = (
  props: IRegistrationProps
) => {
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState(
    'Confirming your account ...'
  );
  const confirmPostAPI = async (
    key1: string,
    value1: string,
    key2: string,
    value2: string
  ) => {
    try {
      setLoading(true);
      await Axios.post(
        `${BASE_API_URL}user/registration/confirm?${qs.stringify({
          [key1]: value1,
          [key2]: value2,
        })}`
      );
      setLoading(false);
      setStatusMessage(
        `Thank you for registering with Guinness 1759 Club. Your account is active now. You can login at`
      );
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data) {
        setStatusMessage(`${err.response.data.message}`);
      } else {
        setStatusMessage('There is a problem with your confirmation email');
      }
    }
  };
  useEffect(() => {
    if (props.location!.pathname) {
      const strArray = props.location!.pathname.split('&');
      confirmPostAPI(
        'email',
        strArray[0].split('=')[1],
        strArray[1].split('=')[0],
        strArray[1].split('=')[1]
      );
    } else {
      setStatusMessage('There is a problem with your confirmation');
    }
  }, [props.location]);

  return (
    <div>
      {loading ? (
        <img
          className="spinner spinner-position-page"
          src={loadIcon}
          alt="Loading..."
        />
      ) : null}
      {statusMessage && (
        <Alert className="register-confirm-message" data-testid="confirmStatus">
          {statusMessage}{' '}
          {statusMessage !== 'Confirming your account ...' && (
            <Link to="/home">Home Page</Link>
          )}
        </Alert>
      )}
    </div>
  );
};
