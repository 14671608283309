import {
  ADD_MEMBER_PINTS_FAILED,
  ADD_MEMBER_PINTS_STARTED,
  ADD_MEMBER_PINTS_SUCCEEDED,
  CLEAR_BAR_ADMIN_BAR_MEMBER_CACHE,
  CLEAR_BAR_ADMIN_MEMBER_EDIT,
  CLEAR_MEMBER_INFO_CACHE,
  CLEAR_MEMBER_INFO_EDIT,
  EDIT_BAR_ADMIN_MEMBER_DETAILS_FAILED,
  EDIT_BAR_ADMIN_MEMBER_DETAILS_STARTED,
  EDIT_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED,
  EDIT_MEMBER_INFO_FAILED,
  EDIT_MEMBER_INFO_STARTED,
  EDIT_MEMBER_INFO_SUCCEEDED,
  EDIT_SHIRT_SIZE_FAILED,
  EDIT_SHIRT_SIZE_STARTED,
  EDIT_SHIRT_SIZE_SUCCEEDED,
  FETCH_BAR_ADMIN_MEMBER_DETAILS_FAILED,
  FETCH_BAR_ADMIN_MEMBER_DETAILS_STARTED,
  FETCH_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED,
  FETCH_MEMBER_DASHBOARD_DETAILS_FAILED,
  FETCH_MEMBER_DASHBOARD_DETAILS_STARTED,
  FETCH_MEMBER_DASHBOARD_DETAILS_SUCCEEDED,
  FETCH_MEMBER_INFO_FAILED,
  FETCH_MEMBER_INFO_STARTED,
  FETCH_MEMBER_INFO_SUCCEEDED,
  FETCH_MEMBER_LIST_FAILED,
  FETCH_MEMBER_LIST_STARTED,
  FETCH_MEMBER_LIST_SUCCEEDED,
  SAVE_MEMBER_PAGINATION,
  SAVE_SELECTED_MEMBER,
  SET_BAR_ADMIN_MEMBER_PINT_LEVEL,
  SET_DISPLAYING_MEMBER_FOR_BAR_ADMIN,
  SET_EDIT_MODE_BAR_ADMIN_MEMBER_DETAILS,
  SET_EDIT_MODE_MEMBER_INFO,
  SHOW_BAR_ADMIN_MEMBER_DETAILS,
  HIDE_BAR_ADMIN_MEMBER_DETAILS
} from '../actions/memberAction';
import { Member, MemberActionTypes } from '../actions/actions';
import { LOGOUT_SUCCEEDED } from '../actions/authAction';
import { initialState } from '../store/configureStore';

export const memberReducer = (
  state: Member = {},
  action: MemberActionTypes
): Member => {
  switch (action.type) {
    case FETCH_MEMBER_LIST_STARTED:
      return {
        ...state,
        memberList: [],
        isMemberListLoading: true,
        isMemberListError: false,
        memberListErrorMessage: '',
      };
    case FETCH_MEMBER_LIST_SUCCEEDED:
      return {
        ...state,
        memberList: action.payload?.memberList,
        totalMembers: action.payload?.totalMembers,
        overAllTotal: action.payload?.overAllTotal,
        isMemberListLoading: false,
        isMemberListError: false,
        memberListErrorMessage: '',
        selectedMember: action?.payload?.selectedMember,
      };
    case FETCH_MEMBER_LIST_FAILED:
      return {
        ...state,
        memberList: [],
        totalMembers: 0,
        isMemberListLoading: false,
        isMemberListError: true,
        memberListErrorMessage: action.payload?.memberListErrorMessage,
      };
    case ADD_MEMBER_PINTS_STARTED:
      return {
        ...state,
        isMemberPintsLoading: true,
        isMemberPintsError: false,
        memberPintsSuccessMessage: '',
        memberPintsErrorMessage: '',
      };
    case ADD_MEMBER_PINTS_SUCCEEDED:
      return {
        ...state,
        isMemberPintsLoading: false,
        isMemberPintsError: false,
        memberPintsSuccessMessage: 'Pints updated successfully',
        memberPintsErrorMessage: '',
      };
    case ADD_MEMBER_PINTS_FAILED:
      return {
        ...state,
        isMemberPintsLoading: false,
        isMemberPintsError: true,
        memberPintsSuccessMessage: '',
        memberPintsErrorMessage: 'Pint value incorrect',
      };
    case SAVE_SELECTED_MEMBER:
      return {
        ...state,
        memberPintsSuccessMessage: '',
        memberPintsErrorMessage: '',
        selectedMember: action.payload?.selectedMember,
      };
    case SAVE_MEMBER_PAGINATION:
      return {
        ...state,
        paginationHistory: action.payload?.paginationHistory,
      };
    case SET_DISPLAYING_MEMBER_FOR_BAR_ADMIN:
      return {
        ...state,
        displayingMember: action.payload?.displayingMember,
      };
    case FETCH_BAR_ADMIN_MEMBER_DETAILS_STARTED:
      return {
        ...state,
        isMemberDetailsLoading: true,
        isMemberDetailsError: false,
        memberDetailsErrorMessage: '',
      };
    case FETCH_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED:
      return {
        ...state,
        memberDetails: action.payload?.memberDetails,
        isMemberDetailsLoading: false,
        isMemberDetailsError: false,
        memberDetailsErrorMessage: '',
      };
    case FETCH_BAR_ADMIN_MEMBER_DETAILS_FAILED:
      return {
        ...state,
        isMemberDetailsLoading: false,
        isMemberDetailsError: true,
        memberDetailsErrorMessage: 'Error retrieving member details',
      };
    case EDIT_BAR_ADMIN_MEMBER_DETAILS_STARTED:
      return {
        ...state,
        isEditMemberLoading: true,
        isEditMemberSuccess: false,
        isEditMemberError: false,
        editMemberMessage: '',
      };
    case EDIT_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED:
      return {
        ...state,
        isEditMemberLoading: false,
        isEditMemberSuccess: true,
        isEditMemberError: false,
        editMemberMessage: 'Member updated successfully',
      };
    case EDIT_BAR_ADMIN_MEMBER_DETAILS_FAILED:
      return {
        ...state,
        isEditMemberLoading: false,
        isEditMemberSuccess: false,
        isEditMemberError: true,
        editMemberMessage: 'Error updating member',
      };
    case SET_EDIT_MODE_BAR_ADMIN_MEMBER_DETAILS:
      return {
        ...state,
        isEditMode: action.payload?.isEditMode
      };
    case SHOW_BAR_ADMIN_MEMBER_DETAILS:
      return {
        ...state,
        showMemberDetails: true,
      };
    case HIDE_BAR_ADMIN_MEMBER_DETAILS:
      return {
        ...state,
        showMemberDetails: false,
      };
    case CLEAR_BAR_ADMIN_MEMBER_EDIT:
      return {
        ...state,
        isEditMemberLoading: false,
        isEditMemberSuccess: false,
        isEditMemberError: false,
        editMemberMessage: '',
      };
    case SET_BAR_ADMIN_MEMBER_PINT_LEVEL:
      return {
        ...state,
        selectedMember: action.payload?.selectedMember,
      };
    case CLEAR_BAR_ADMIN_BAR_MEMBER_CACHE:
      return {
        ...state,
        memberDetails: action.payload?.memberDetails,
      }
    case FETCH_MEMBER_DASHBOARD_DETAILS_STARTED:
      return {
        ...state,
        isMemberDashboardDetailsLoading: true,
        isMemberDashboardDetailsError: false,
        memberDashboardDetailsErrorMessage: '',
      };
    case FETCH_MEMBER_DASHBOARD_DETAILS_SUCCEEDED:
      return {
        ...state,
        memberDashboardDetails: action.payload?.memberDashboardDetails,
        isMemberDashboardDetailsLoading: false,
        isMemberDashboardDetailsError: false,
        memberDashboardDetailsErrorMessage: '',
      };
    case FETCH_MEMBER_DASHBOARD_DETAILS_FAILED:
      return {
        ...state,
        isMemberDashboardDetailsLoading: false,
        isMemberDashboardDetailsError: true,
        memberDashboardDetailsErrorMessage: 'Error retrieving member details',
      };
    case FETCH_MEMBER_INFO_STARTED:
      return {
        ...state,
        isMemberInfoLoading: true,
        isMemberInfoError: false,
        memberInfoErrorMessage: '',
      };
    case FETCH_MEMBER_INFO_SUCCEEDED:
      return {
        ...state,
        memberInfo: action.payload?.memberInfo,
        isMemberInfoLoading: false,
        isMemberInfoError: false,
        memberInfoErrorMessage: '',
      };
    case FETCH_MEMBER_INFO_FAILED:
      return {
        ...state,
        isMemberInfoLoading: false,
        isMemberInfoError: true,
        memberInfoErrorMessage: 'Error retrieving member info',
      };
    case EDIT_MEMBER_INFO_STARTED:
      return {
        ...state,
        isEditMemberInfoLoading: true,
        isEditMemberInfoSuccess: false,
        isEditMemberInfoError: false,
        editMemberInfoMessage: '',
      };
    case EDIT_MEMBER_INFO_SUCCEEDED:
      return {
        ...state,
        isEditMemberInfoLoading: false,
        isEditMemberInfoSuccess: true,
        isEditMemberInfoError: false,
        editMemberInfoMessage: 'Member updated successfully',
      };
    case EDIT_MEMBER_INFO_FAILED:
      return {
        ...state,
        isEditMemberInfoLoading: false,
        isEditMemberInfoSuccess: false,
        isEditMemberInfoError: true,
        editMemberInfoMessage: 'Error updating member',
      };
    case SET_EDIT_MODE_MEMBER_INFO:
      return {
        ...state,
        isEditModeMemberInfo: action.payload?.isEditModeMemberInfo,
      }
    case CLEAR_MEMBER_INFO_EDIT:
      return {
        ...state,
        isEditMemberInfoLoading: false,
        isEditMemberInfoSuccess: false,
        isEditMemberInfoError: false,
        editMemberInfoMessage: '',
      };
    case CLEAR_MEMBER_INFO_CACHE:
      return {
        ...state,
        memberInfo: action.payload?.memberInfo,
      }
    case EDIT_SHIRT_SIZE_STARTED:
      return {
        ...state,
        isEditShirtSizeLoading: true,
        isEditShirtSizeSuccess: false,
        isEditShirtSizeError: false,
        editShirtSizeMessage: '',
      };
    case EDIT_SHIRT_SIZE_SUCCEEDED:
      return {
        ...state,
        isEditShirtSizeLoading: false,
        isEditShirtSizeSuccess: true,
        isEditShirtSizeError: false,
        editShirtSizeMessage: 'Member updated successfully',
      };
    case EDIT_SHIRT_SIZE_FAILED:
      return {
        ...state,
        isEditShirtSizeLoading: false,
        isEditShirtSizeSuccess: false,
        isEditShirtSizeError: true,
        editShirtSizeMessage: 'Error updating member',
      };
    case LOGOUT_SUCCEEDED:
      return initialState.memberData
    default:
      return state;
  }
};
