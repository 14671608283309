import React, {useEffect, useState} from 'react';
import {
    getUserFullName,
    getUserRole,
    getBarName
} from '../../shared/utils/global-functions';
import MemberList from './member-list/MemberList';
import BarElement from './bar-details/BarElement';
import TotalBars from './bar-details/TotalBars';
import RegisterUsers from './bar-details/RegisterUsers';
import './dashboard.scss';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from '../../reducers/rootReducer';
import EmployeeElement from './employee-details/EmployeeElement';
import lionLogoImage from '../../assets/icons-lion-logo-gold.svg';
import MemberElement from './member-details/MemberElement';
import LionAdminElement from './lion-admin-details/LionAdminElement';
import EmployeeList from './employee-list/EmployeeList';
import MemberDetails from './member-list/MemberDetails';
import MemberView from './member-details/MemberView';
import MemberPintView from './member-details/MemberPintView';
import {MemberRewards} from './member-details/MemberRewards';
import editIcon from '../../assets/icons/icons-system-edit-circle-large.svg';
import {fetchMemberDashboardDetails} from '../../actions/memberAction';
import MemberInfoModal from './member-details/MemberInfoModal';
import { showModal } from '../../actions/commonAction';
import { MembershipView } from './bar-admin-details/MembershipView';
import { MODAL_DIALOG_NAMES } from '../../shared/configs/appConfigs';
import {LionAdminMembershipView} from "./lion-admin-details/LionAdminMembershipView";
import TotalMembers from './bar-admin-details/TotalMembers';
import { isMobile } from 'react-device-detect';
import { MemberSearch } from './bar-admin-details/MemberSearch';

export const Dashboard: React.FC = () => {

    const selectedBarId = useSelector<AppState,
        AppState['authData']['selectedBarId']>((state) => state.authData.selectedBarId);

    const searchBarPanelVisible = useSelector<AppState,
        AppState['lionAdminData']['searchBarPanelVisible']>((state) => state.lionAdminData.searchBarPanelVisible);

    const searchBarEmployeePanelVisible = useSelector<AppState,
        AppState['lionAdminData']['searchBarEmployeePanelVisible']>((state) => state.lionAdminData.searchBarEmployeePanelVisible);

    const searchBarMemberPanelVisible = useSelector<AppState,
        AppState['lionAdminData']['searchBarMemberPanelVisible']>((state) => state.lionAdminData.searchBarMemberPanelVisible);

    const searchLionAdminPanelVisible = useSelector<AppState,
        AppState['lionAdminData']['searchLionAdminPanelVisible']>((state) => state.lionAdminData.searchLionAdminPanelVisible);

    const showMemberDetails = useSelector<AppState,
        AppState['memberData']['showMemberDetails']>((state) => state.memberData.showMemberDetails);

    const memberDashboardDetails = useSelector<AppState,
        AppState['memberData']['memberDashboardDetails']>((state) => state.memberData.memberDashboardDetails);

    const isMemberDashboardDetailsLoading = useSelector<AppState,
        AppState['memberData']['isMemberDashboardDetailsLoading']>((state) => state.memberData.isMemberDashboardDetailsLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (getUserRole() && getUserRole().toLowerCase() === 'Member'.toLowerCase()) {
            dispatch(fetchMemberDashboardDetails());
        }
    }, []);

    const memberInfoEdit = () => {
        dispatch(showModal(MODAL_DIALOG_NAMES.memberInfo));
    }

    const renderMemberDashboard = () => {
        if (getUserRole() && getUserRole().toLowerCase() === 'Member'.toLowerCase() && !isMemberDashboardDetailsLoading) {
            return (
                <div className='user-section page-first-section-padding container-md' style={{marginBottom: '-40px'}}>
                    <h1 className='d-flex justify-content-center align-items-start align-items-md-center user-section__heading'>
                        {memberDashboardDetails?.firstName} {memberDashboardDetails?.lastName}
                        <img className="edit-icon" src={editIcon} onClick={() => memberInfoEdit()}/>
                    </h1>
                    <div className='divider-center'/>
                    <MemberView barName={memberDashboardDetails?.barName}
                                shirtSize={memberDashboardDetails?.shirtSize}/>
                    <MemberPintView pints={memberDashboardDetails?.pints}/>
                    <MemberInfoModal/>
                </div>
            );
        }
    };

    const renderEmployeeDashboard = () => {
        if (getUserRole() && getUserRole().toLowerCase() === 'Employee'.toLowerCase()) {
            return (
                <>
                    <div className='user-section page-first-section-padding container-md'>
                        <h1 className='d-flex justify-content-center align-items-center user-section__heading'>
                            {getBarName()}
                        </h1>
                        <div className='divider-center'/>
                    </div>
                    {
                      isMobile && <MemberSearch/>
                    }
                    {showMemberDetails && <MemberDetails/>}
                    {selectedBarId !== -1 && !isMobile && (<MemberList/>)}
                </>
            );
        }
    }

    const renderBarAdminDashboard = () => {
        if (getUserRole() && getUserRole().toLowerCase() === 'BarAdmin'.toLowerCase()) {
            return (
                <>
                    <div className='user-section page-first-section-padding container-md'>
                        <h1 className='d-flex justify-content-center align-items-center user-section__heading'>
                            {getBarName()}
                        </h1>
                        <div className='divider-center'/>
                    </div>
                    <TotalMembers/>
                    {
                      isMobile && <MemberSearch/>
                    }
                    <MembershipView/>
                    {showMemberDetails && <MemberDetails/>}
                    {selectedBarId !== -1 && !isMobile && (<MemberList/>)}
                    {selectedBarId !== -1 && (<EmployeeList/>)}
                </>
            );
        }
    };

    const renderAdminDashboard = () => {
        if (getUserRole() && getUserRole().toLowerCase() === 'Admin'.toLowerCase()) {
            return (
                <>
                    <div className='user-section page-first-section-padding container-md'>
                        <h1 className='d-flex justify-content-center align-items-center user-section__heading'>
                            <img className='lion-logo-image' src={lionLogoImage}/>
                            <span>Lion Admin</span>
                        </h1>
                        <div className='divider-center'/>
                    </div>
                    <TotalBars/>
                    <LionAdminMembershipView/>
                    {getVisiblePanel()}
                    <div className='divider-full'></div>
                    <RegisterUsers/>
                </>
            );
        }
    };

    const getVisiblePanel = () => {
        if (searchBarPanelVisible) {
            return (
                <>
                    <BarElement/>
                    {showMemberDetails && !isMobile && <MemberDetails/>}
                    {selectedBarId !== -1 && !isMobile && (<MemberList isBarDetailsPage={true}/>)}
                    {selectedBarId !== -1 && (<EmployeeList/>)}
                </>
            );
        } else if (searchBarEmployeePanelVisible) {
            return <EmployeeElement/>;
        } else if (searchBarMemberPanelVisible) {
            return <MemberElement/>;
        } else if (searchLionAdminPanelVisible) {
            return <LionAdminElement/>;
        }
    };

    return (
        <div>
            <div className='dashboard-section'>
                {renderMemberDashboard()}
                {renderEmployeeDashboard()}
                {renderBarAdminDashboard()}
                {renderAdminDashboard()}
            </div>
            {
                (getUserRole() && getUserRole().toLowerCase() === 'Member'.toLowerCase() && !isMemberDashboardDetailsLoading)
                    ? <MemberRewards pints={memberDashboardDetails?.pints}/> : null
            }
        </div>
    );
};
