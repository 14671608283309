import React from 'react';
import { CustomModal } from 'src/shared/components/ui/modal/modal';
import { IModalConfig } from 'src/shared/types/modalInterface';
import { IMemberInfoPayload } from './MemberInfoPayload';

const SubmitEditEmployeeModal: React.FC<{
  hideModalShow: () => void;
  onSubmit: () => void;
  values: IMemberInfoPayload;
  memberInfo: IMemberInfoPayload;
}> = (props) => {

  const submitEditMemberConfig: IModalConfig = {
    title: 'Confirm Submit',
    showModal: true,
    modalSize: 'smallModal',
    closeModal: hideModal,
    body: <p style={{ textAlign: 'center' }}>Are you sure you want to update details?</p>,
    primaryBtnText: 'Yes',
    secondaryBtnText: 'No',
    submitModal: submitValues,
  };

  function hideModal() {
    props.hideModalShow();
  }

  function submitValues() {
    props.onSubmit();
  }

  return (
    <div>
      <CustomModal config={submitEditMemberConfig}/>
    </div>
  );
};

export default SubmitEditEmployeeModal;
