import React, { useEffect } from 'react';
import './total-members.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';

const TotalMembers: React.FC = () => {

  const totalMembers = useSelector<
    AppState,
    AppState['memberData']['overAllTotal']
    >((state) => state.memberData.overAllTotal);

  return <div className="total-members__heading">TOTAL MEMBERS: {totalMembers}</div>;

};

export default TotalMembers;