import React from 'react';
import Alert from 'react-bootstrap/Alert';

interface IAlertStatus {
  status: string;
  isApiError: boolean;
}
export const AlertStatus: React.FC<IAlertStatus> = (props) => {
  return (
    <div style={{marginTop: '20px'}}>
      {props.status && (
        <Alert variant={props.isApiError ? 'danger' : 'success'}>
          {props.status}
        </Alert>
      )}
    </div>
  );
};
