import React, { useState } from 'react';
import { useField } from 'formik';
import { ISearchInputProps } from '../../../types/searchInputProps.interface';
import './search-input.scss';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { CommonProps, ValueType } from 'react-select';
import { customStyles } from '../../../utils/global-functions';
import { useDispatch } from 'react-redux';
import { setSelectedEmployeeId } from '../../../../actions/lionAdminAction';

type OptionType = { label: string; value: number };

export const SearchEmployeeInput: React.FC<ISearchInputProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState(-1);
  const [message, setMessage] = useState('Input min. 3 characters');
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [field] = useField(props);

  const dispatch = useDispatch();

  const handleChange = (
    option: OptionType,
    fieldName: string,
    setFieldValue: (
      field: string,
      value: number,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (option) {
      setFieldValue(fieldName, option.value);
      setSelectedOption(option.value);
      setSearchIsActive(true);
    } else {
      setFieldValue(fieldName, -1);
      setSelectedOption(-1);
      setSearchIsActive(false);
      dispatch(setSelectedEmployeeId(-1));
    }
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    props.setFieldTouched(props.name, true);
    setSelectedOption(0);
  };

  const LoadingMessage = (
    props: JSX.IntrinsicAttributes &
      CommonProps<any>
  ) => {
    return (
      <div
        style={props.getStyles('loadingMessage', props)}
      >
        {message}
      </div>
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: Array<{ value: number; label: string }>) => void
  ) => {
    if (!inputValue) {
      callback([]);
    } else if (inputValue.length <= 2){
      setMessage('Input min. 3 characters');
    } else {
      setMessage('Loading...');
      if (inputValue.length > 2) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}Bar/employee/list?searchvalue=${inputValue}`
          )
          .then(function (response) {
            const options = response.data.map(
              (employee: { id: number; name: string }) => ({
                value: employee.id,
                label: employee.name,
              })
            );
            callback(options);
          });
      }
    }
  };
  return (
    <div className="form-group search-form-group">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <button
        type="submit"
        className={
          searchIsActive ? 'icons-search-bar-active' : 'icons-search-bar'
        }
        disabled={!searchIsActive}
      />
      <AsyncSelect
        components={{ LoadingMessage }}
        className={`search-input ${
          props.error && selectedOption === 0 ? 'input-error' : ''
        }`}
        loadOptions={loadOptions}
        onBlur={handleBlur}
        {...props}
        noOptionsMessage={() => 'No employees found'}
        onChange={(options: ValueType<OptionType>) =>
          handleChange(options as OptionType, field.name, props.setFieldValue)
        }
        classNamePrefix="select"
        styles={customStyles}
        isClearable={true}
      />
    </div>
  );
};
