import React, { useState, useEffect } from "react";
import pintLogo from '../../../assets/guinness_pint_level.png';
import './member-pint-view.scss';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


const MemberView: React.FC<{pints?: number}> = (props) => {

  const [pintLevel, setPintLevel] = useState(props.pints || 0);
  const [levelPercent, setLevelPercent] = useState('0%');

  useEffect(() => {
    if (pintLevel <= 100) {
      const pintLevelDiff = pintLevel - 0;
      const pintSectionDiff = 100;
      const pintRatio = pintLevelDiff / pintSectionDiff;
      const pintPercent = pintRatio * 19;
      setLevelPercent(pintPercent.toString());
    } else if (pintLevel <= 500) {
      const pintLevelDiff = pintLevel - 100;
      const pintSectionDiff = 400;
      const pintRatio = pintLevelDiff / pintSectionDiff;
      const pintPercent = pintRatio * 19 + 19 + 0.8;
      setLevelPercent(pintPercent.toString());
    } else if (pintLevel <= 1000) {
      const pintLevelDiff = pintLevel - 500;
      const pintSectionDiff = 500;
      const pintRatio = pintLevelDiff / pintSectionDiff;
      const pintPercent = pintRatio * 19 + 38 + 1.2;
      setLevelPercent(pintPercent.toString());
    } else if (pintLevel <= 2500) {
      const pintLevelDiff = pintLevel - 1000;
      const pintSectionDiff = 1500;
      const pintRatio = pintLevelDiff / pintSectionDiff;
      const pintPercent = pintRatio * 19 + 57 + 2;
      setLevelPercent(pintPercent.toString());
    } else {
      const pintPercent = 88;
      setLevelPercent(pintPercent.toString());
    }

  }, [])

  return(
    <div className="club-journey-container">
      <h3 className="club-journey-container__heading">YOUR CLUB JOURNEY</h3>
      <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight: 300 }}>
      <div className="club-journey-container__level-with-labels">
        <div className="start-label">START</div>
        <div className="club-journey-level">

          <div className="club-journey-level__image-container" style={{left: `${levelPercent}%`}}>
            <div className="image-and-tooltip">
              <div className="pint-tooltip" style={{ left: pintLevel > 800 ? '-28px' : '-24px'}}>
                <div className="text">{pintLevel} PINTS</div>
                <div className="triangle"></div>
              </div>

              <div className="pint-image">
                <img style={{height: '73px'}} src={pintLogo}/>
              </div>
            </div>
          </div>

          <div className="club-journey-level__0-pints-milestone"></div>

          <div className="club-journey-level__100-pints-scale">
            <div className="part" style={{opacity: pintLevel < 10 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 20 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 30 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 40 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 50 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 60 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 70 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 80 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 90 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 100 ? 0.4 : 1}}></div>
          </div>

          <div className="club-journey-level__100-pints-milestone">
            <div className="circle" style={{opacity: pintLevel <= 100 ? 0.4 : 1}}></div>
            <div className="label">100 PINTS</div>
          </div>

          <div className="club-journey-level__500-pints-scale">
            <div className="part" style={{opacity: pintLevel < 140 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 180 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 220 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 260 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 300 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 340 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 380 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 420 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 460 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 500 ? 0.4 : 1}}></div>
          </div>

          <div className="club-journey-level__500-pints-milestone">
            <div className="circle" style={{opacity: pintLevel <= 500 ? 0.4 : 1}}></div>
            <div className="label">500 PINTS</div>
          </div>

          <div className="club-journey-level__1000-pints-scale">
            <div className="part" style={{opacity: pintLevel < 550 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 600 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 650 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 700 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 750 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 800 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 850 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 900 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 950 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 1000 ? 0.4 : 1}}></div>
          </div>

          <div className="club-journey-level__1000-pints-milestone">
            <div className="circle" style={{opacity: pintLevel <= 1000 ? 0.4 : 1}}></div>
            <div className="label">1000 PINTS</div>
          </div>

          <div className="club-journey-level__2500-pints-scale">
            <div className="part" style={{opacity: pintLevel < 1150 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 1300 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 1450 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 1600 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 1750 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 1900 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 2050 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 2200 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 2350 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: pintLevel < 2500 ? 0.4 : 1}}></div>
          </div>

          <div className="club-journey-level__2500-pints-milestone">
            <div className="circle" style={{opacity: pintLevel <= 2500 ? 0.4 : 1}}></div>
            <div className="label">2500 PINTS</div>
            <div className="legend-label">YOU&#39;RE A LEGEND</div>
          </div>

          <div className="club-journey-level__2500-beyond-pints-scale">
            <div className="part" style={{opacity: pintLevel <= 2500 ? 0.4 : 1}}></div>
            <div className="part" style={{opacity: 0.4}}></div>
          </div>

          <div className="club-journey-level__2500-beyond-pints-milestone">
            <div className="circle" style={{opacity: 0.4}}></div>
          </div>
        </div>

      </div>
      </SimpleBar>
    </div>


  )
}

export default MemberView
