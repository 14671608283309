import React from 'react';
import { Form, Formik } from 'formik';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import { SearchInput } from '../../../shared/components/ui/search-input/SearchInput';
import './search-bar.scss';
import { setSelectedBarId } from '../../../actions/authAction';
import { showBarDetails } from '../../../actions/lionAdminAction';
import { useDispatch } from 'react-redux';

const initFormValues: { barId: string } = {
  barId: '',
};

const SearchBar: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div className="search-bar">
      <Formik
        initialValues={initFormValues}
        onSubmit={(values) => {
          dispatch(setSelectedBarId(parseInt(values.barId)));
          dispatch(showBarDetails());
        }}
      >
        {({ setFieldValue, setFieldTouched, errors, touched }) => (
          <Form>
            <SearchInput
              label=""
              isBarSearch={true}
              id="barId"
              name="barId"
              error={errors.barId}
              touched={touched.barId}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              placeholder="Search for a bar"
            />
            <ValidationErrorTemplate
              error={errors.barId}
              touched={touched.barId}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchBar;
