import React, { useEffect, useState } from 'react';
import { IModalConfig } from '../../shared/types/modalInterface';
import { ContactUsPage } from './ContactUsPage';
import { CustomModal } from '../../shared/components/ui/modal/modal';
import { clearContactUs, hideModal } from '../../actions/commonAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/reducers/rootReducer';
import { MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';

const ContactUsModal: React.FC = () => {
  const dispatch = useDispatch();
  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );
  const [showContactus, setShowContactus] = useState(false);
  useEffect(() => {
    if (
      commonData?.isOpenDialog &&
      commonData.modalName === MODAL_DIALOG_NAMES.contactUs
    ) {
      setShowContactus(true);
    } else {
      setShowContactus(false);
    }
  }, [commonData?.isOpenDialog, commonData.modalName]);

  const contactUsModalConfig: IModalConfig = {
    title: commonData?.logoElement,
    isImageTitle: true,
    showModal: true,
    modalSize: 'largeModal',
    closeModal: closeModal,
    body: <ContactUsPage />,
  };

  function closeModal(): void {
    dispatch(clearContactUs());
    dispatch(hideModal(MODAL_DIALOG_NAMES.contactUs));
  }

  return (
    <div>
      {showContactus && (
        <CustomModal config={contactUsModalConfig}/>
      )}
    </div>
  );
};

export default ContactUsModal;
