import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import {
  hideEmployeeCreate,
  hideEmployeeDetails,
  setSelectedEmployeeId,
  setSearchBarPanel,
  setSearchBarMemberPanel, clearBarEmployeeCache
} from '../../../actions/lionAdminAction';
import chevronUp from '../../../assets/icons/icons-system-chevron-up.svg';
import chevronRight from '../../../assets/icons/icons-system-chevron-right.svg';
import SearchEmployee from './SearchEmployee';
import EmployeeDetails from './EmployeeDetails';
import EmployeeCreateLionAdmin from './EmployeeCreateLionAdmin';
import Dropdown from 'react-bootstrap/Dropdown';
import './employee-element.scss';
import { isMobile } from 'react-device-detect';

const EmployeeElement: React.FC = () => {
  const dispatch = useDispatch();

  const showEmployeeDetails = useSelector<
    AppState,
    AppState['lionAdminData']['showEmployeeDetails']
  >((state) => state.lionAdminData.showEmployeeDetails);

  const showEmployeeCreate = useSelector<
    AppState,
    AppState['lionAdminData']['showEmployeeCreate']
  >((state) => state.lionAdminData.showEmployeeCreate);

  const displaySearchEmployee = () => {
    dispatch(hideEmployeeDetails());
    dispatch(hideEmployeeCreate());
    dispatch(setSelectedEmployeeId(-1));
    dispatch(clearBarEmployeeCache());
  };

  const switchToSearchBar = () => {
    dispatch(hideEmployeeDetails());
    dispatch(hideEmployeeCreate());
    dispatch(setSelectedEmployeeId(-1));
    dispatch(clearBarEmployeeCache());
    dispatch(setSearchBarPanel());
  };

  const switchToSearchMember = () => {
    dispatch(hideEmployeeDetails());
    dispatch(hideEmployeeCreate());
    dispatch(setSelectedEmployeeId(-1));
    dispatch(clearBarEmployeeCache());
    dispatch(setSearchBarMemberPanel());
  };

  return (
    <div className="bar-employee-element-dashboard-section">
      {
        !showEmployeeDetails && !showEmployeeCreate && <>
          <div
            className="bar-employee-element-dashboard-section__heading"
            data-testid="bar-employee-element-heading-section"
          >
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span className="heading-bar-1">SEARCH &nbsp;</span>
                <span className="heading-bar-2">EMPLOYEE</span>
                <img className="heading-icon" src={chevronUp}/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={switchToSearchBar}>
                  Bar Search
                </Dropdown.Item>
                {
                  !isMobile && <Dropdown.Item onClick={switchToSearchMember}>
                    Member Search
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <SearchEmployee />
        </>
      }

      <div
        className="bar-employee-element-dashboard-section-content"
        data-testid="bar-employee-element-content-section"
      >
        {(showEmployeeDetails || showEmployeeCreate) && (
          <div
            onClick={displaySearchEmployee}
            className="bar-employee-element-dashboard-section-content__link"
          >
            <img className="back-to-search-icon" src={chevronRight}/>
            {showEmployeeDetails && (
              <span className="back-link">Back</span>
            )}
            {showEmployeeCreate && (
              <span className="back-link">Back</span>
            )}
          </div>
        )}
        {showEmployeeDetails && <EmployeeDetails />}
        {showEmployeeCreate && <EmployeeCreateLionAdmin />}
      </div>
    </div>
  );
};

export default EmployeeElement;
