import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { ContentfulClientConfigs } from 'src/shared/configs/ContentfulClientConfigs';
import { INSTAGRAM_URL } from 'src/shared/configs/appConfigs';
import { IGetSocial } from './ViewClub.interface';
import instaIcon from '../../assets/icons/icons-social-instagram.svg';

export const GetSocial: React.FC<IGetSocial> = (props) => {
  const [getSocialImageUrl, setGetSocialImageUrl] = useState('');
  useEffect(() => {
    ContentfulClientConfigs.getAsset('6Ls7Kc82jdF4JhGYBOowju').then((asset) => {
      setGetSocialImageUrl(`${asset.fields.file.url}`);
    });
  }, []);
  const onClickInstaIcon = () => {
    window.open(INSTAGRAM_URL, '_blank');
  };
  return (
    <div className="section-padding get-social-container">
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center justify-content-md-between container-md ">
        <div className="col-12 col-md-5 text-container">
          <h1>{props.instagramTitle}</h1>
          <div className={isMobile ? 'divider' : 'divider align-left'}></div>
          <p className="py-0 m-0">{props?.instagramBodyCopy}</p>
          <div className="d-flex justify-content-center justify-content-md-start align-items-center follow-btn-section">
            <Button variant="primary" onClick={() => onClickInstaIcon()}>
              <span>Follow</span>
              <img className="pl-2" src={instaIcon} alt="Instagram Icon" />
            </Button>
          </div>
        </div>
        <div className="col-12 col-md-7 image-container">
          <img src={getSocialImageUrl} alt="Get Social Image" />
        </div>
      </div>
    </div>
  );
};
