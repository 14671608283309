import React, { useEffect, useState, useRef } from 'react';
import { Button, Nav, Navbar, NavItem } from 'react-bootstrap';
import { getUserFullName, getUserRole } from '../../../utils/global-functions';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import logoutIcon from '../../../../assets/icons-system-logout-a.svg';
import navBarLogo from '../../../../assets/guinness-logo-light.svg';
import closeIcon from '../../../../assets/icons/icons-system-close.svg';
import menuIcon from '../../../../assets/icons/icons-system-hamburger.svg';
import navBgHero from '../../../../assets/home-hero-gradient-background@3x.png';
import './custom-navbar.scss';
import { useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { performLogOut } from '../../../../actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducers/rootReducer';
import { isMobile } from 'react-device-detect';
import { showModal } from '../../../../actions/commonAction';
import { MODAL_DIALOG_NAMES } from '../../../configs/appConfigs';

const CustomNavBar: React.FC = () => {
  const target = useRef(null);
  const [userInitial, setUserInitial] = useState('');
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [navBgColor, setNavBgColor] = useState('#0a0406');
  const [isHomePage, setIsHomePage] = useState(false);
  const isAuth = useSelector<AppState, AppState['authData']['isAuth']>(
    (state) => state.authData.isAuth
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const matches = getUserFullName() ? getUserFullName().match(/\b(\w)/g) : '';
    if (matches) {
      const acronym = matches.join('');
      setUserInitial(acronym);
    }
  }, [isAuth]);

  useEffect(() => {
    if (location.pathname == '/club' || location.pathname == '/about') {
      setNavBgColor('transparent');
    } else {
      setNavBgColor('#0a0406');
    }
    if (location.pathname == '/home') {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [location]);

  useEffect(() => {
    if (expanded) {
      (document.getElementsByClassName(
        'navbar-nav'
      )[0] as HTMLElement).style.height = window.innerHeight + 'px';
      (document.getElementsByTagName(
        'BODY'
      )[0] as HTMLBodyElement).style.overflow = 'hidden';
    } else {
      (document.getElementsByClassName(
        'navbar-nav'
      )[0] as HTMLElement).style.height = 'auto';
      (document.getElementsByTagName(
        'BODY'
      )[0] as HTMLBodyElement).style.overflow = 'auto';
    }
  }, [expanded]);

  const memberDashboardDetails = useSelector<AppState,
    AppState['memberData']['memberDashboardDetails']>((state) => state.memberData.memberDashboardDetails);

  const logout = () => {
    dispatch(performLogOut());
    if (isMobile) setExpanded((prevExpanded) => !prevExpanded);
  };

  const onLoginClick = () => {
    dispatch(showModal(MODAL_DIALOG_NAMES.login));
    if (isMobile) setExpanded((prevExpanded) => !prevExpanded);
  };

  const onRegisterClick = () => {
    dispatch(showModal(MODAL_DIALOG_NAMES.registration));
    if (isMobile) setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="d-flex flex-column">
      <Navbar
        className="guinness-navbar"
        expand="lg"
        expanded={expanded}
        style={
          expanded ? {
                background: `linear-gradient(90deg, rgba(5,2,3,1) 0%, rgba(24,17,12,1) 25%, rgba(46,35,22,1) 40%, rgba(84,66,40,1) 60%, rgba(103,82,50,1) 80%, rgba(79,62,38,1) 100%)`
          } :
          isHomePage ?  {
          background: `linear-gradient(90deg, rgba(5,2,3,1) 0%, rgba(24,17,12,1) 25%, rgba(46,35,22,1) 40%, rgba(84,66,40,1) 60%, rgba(103,82,50,1) 80%, rgba(79,62,38,1) 100%)`
        } :{
          backgroundColor: `${navBgColor}`,
        }}
      >
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
        >
          {expanded && (
            <img
              className="navbar-toggler-icon img-navbar-icon"
              src={closeIcon}
              alt="Close Icon"
            />
          )}
          {!expanded && (
            <img
              className="navbar-toggler-icon img-navbar-icon"
              src={menuIcon}
              alt="Menu Icon"
            />
          )}
        </Navbar.Toggle>
        <Navbar.Brand style={{width: '33.33%', textAlign: 'center'}}>
            <img src={navBarLogo} alt="NavBar Logo" />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            activeKey={location.pathname}
            className="d-flex flex-fill align-items-center"
          >
            <div className="d-flex align-item-center nav-section page-item">
              <LinkContainer
                className="home-nav-item"
                to="/home"
                onClick={() => {
                  if (isMobile) setExpanded((prevExpanded) => !prevExpanded);
                }}
              >
                <NavItem className="pb-3 pb-md-0">HOME</NavItem>
              </LinkContainer>

              <LinkContainer
                  className="guinness-nav-item"
                  to="/about"
                  onClick={() => {
                    if (isMobile) setExpanded((prevExpanded) => !prevExpanded);
                  }}
              >
                <NavItem className="pb-3 pb-md-0">ABOUT GUINNESS</NavItem>
              </LinkContainer>

              {isAuth && (
                <LinkContainer
                  className="club-nav-item"
                  to="/club"
                  onClick={() => {
                    if (isMobile) setExpanded((prevExpanded) => !prevExpanded);
                  }}
                >
                  <NavItem>THE 1759 CLUB</NavItem>
                </LinkContainer>
              )}
            </div>
            <div className="d-flex align-items-center nav-section login-register-section">
              {!isAuth ? (
                <div
                  className="active-border-none login-nav-item-container"
                  onClick={() => onLoginClick()}
                >
                  <NavItem className=" d-flex align-items-center btn-parent">
                    <Button className="btn-tertiary mr-1 pl-0" variant="link">
                      Login
                    </Button>
                  </NavItem>
                </div>
              ) : (
                !isMobile && (
                  <OverlayTrigger
                    key="test"
                    trigger="click"
                    placement="bottom"
                    rootClose
                    overlay={
                      <Popover id="popover-positioned-bottom" title="Logout">
                        <div
                          className="d-flex align-items-center p-3 cursor-pointer"
                          ref={target}
                          onClick={logout}
                        >
                          <span className="pr-2">Logout</span>
                          <img src={logoutIcon} alt="Logout Icon" />
                        </div>
                      </Popover>
                    }
                  >
                    <Button
                      variant="primary"
                      className="oval initials-button"
                      ref={target}
                    >
                      {userInitial}
                    </Button>
                  </OverlayTrigger>
                )
              )}
              {!isAuth && (
                <div
                  className="active-border-none pl-1 register-nav-item-container"
                  onClick={() => onRegisterClick()}
                >
                  <NavItem className="btn-parent">
                    <Button className="mr-4 register" variant="primary">
                      Register
                    </Button>
                  </NavItem>
                </div>
              )}
              {isAuth && getUserRole().toLowerCase() === 'Member'.toLowerCase() && (
                <LinkContainer
                  className="pl-0 ml-md-3 d-flex align-items-center user-name-link"
                  to="/dashboard"
                >
                  {isMobile ? (
                    <Button
                      onClick={() => {
                        if (isMobile)
                          setExpanded((prevExpanded) => !prevExpanded);
                      }}
                    >
                      {memberDashboardDetails?.firstName} {memberDashboardDetails?.lastName}
                    </Button>
                  ) : (
                    <NavItem>{memberDashboardDetails?.firstName} {memberDashboardDetails?.lastName}</NavItem>
                  )}
                </LinkContainer>
              )}
              {isAuth && getUserRole().toLowerCase() !== 'Member'.toLowerCase() && (
                <LinkContainer
                  className="pl-0 ml-md-3 d-flex align-items-center user-name-link"
                  to="/dashboard"
                >
                  {isMobile ? (
                    <Button
                      onClick={() => {
                        if (isMobile)
                          setExpanded((prevExpanded) => !prevExpanded);
                      }}
                    >
                      {getUserFullName()}
                    </Button>
                  ) : (
                    <NavItem>{getUserFullName()}</NavItem>
                  )}
                </LinkContainer>
              )}
              {isAuth && isMobile && (
                <div className="primaryText text-decoration-underline">
                  <Button
                    variant="link"
                    className="primaryText text-uppercase"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </div>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default CustomNavBar;
