import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { Button } from 'react-bootstrap';
import { BASE_API_URL } from '../../shared/configs/appConfigs';
import { ForgotPasswordValidationSchema } from './ForgotPasswordValidationSchema';
import { IForgotPswdReqPayload } from './ForgotPasswordRequestPayload.interface';
import ValidationErrorTemplate from 'src/shared/components/ui/validation-error/ValidationError';
import { AlertStatus } from '../../shared/components/ui/alert-status/AlertStatus';
import { ForgotPasswordConfirm } from './ForgotPasswordConfirm';
import loadIcon from '../../assets/global-element-spinner.svg';
import './forgotpassword.scss';

export const ForgotPassword: React.FC = () => {
  const initialFormValues: IForgotPswdReqPayload = {
    userName: '',
  };
  const [isApiError, setIsApiError] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = React.useState(false);
  return (
    <div className="forgot-password-dialog">
      {!email && (
        <div>
          <div className="forgot-password-dialog__heading">
            Enter your email to receive a link to reset your password
          </div>
          <Formik
            initialValues={initialFormValues}
            validationSchema={ForgotPasswordValidationSchema}
            validateOnMount
            onSubmit={async (values, { setStatus, resetForm }) => {
              try {
                const reqModalObj: IForgotPswdReqPayload = { ...values };

                setLoading(true);
                await axios({
                  method: 'post',
                  url: `${BASE_API_URL}user/forgotpassword`,
                  data: reqModalObj,
                });
                resetForm();
                setLoading(false);
                setStatus('Link was sent to your email');
                setIsApiError(false);
                setEmail(reqModalObj.userName);
              } catch (err) {
                setLoading(false);
                resetForm();
                if (err && err.response && err.response.data) {
                  setStatus([err.response.data.message]);
                  setIsApiError(true);
                }
                setEmail('');
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              status,
              errors,
              touched,
              isValid,
              isSubmitting,
              values,
              handleSubmit,
            }) => (
              <Form className="d-flex flex-column" onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column date-container">
                    <div
                      className={`form-group ${
                        errors.userName && touched.userName ? 'input-error' : ''
                      }`}
                    >
                      <label htmlFor="userName">Email address</label>
                      <input
                        type="text"
                        aria-label="user-name"
                        data-testid="forgot-pswd-email"
                        name="userName"
                        id="userName"
                        value={values.userName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={320}
                        className={
                          errors.userName && touched.userName
                            ? 'input-error'
                            : undefined
                        }
                      />
                    </div>
                    <ValidationErrorTemplate
                      error={errors.userName}
                      touched={touched.userName}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center justify-content-md-between relative">
                  {loading ? (
                    <img
                      className="spinner"
                      src={loadIcon}
                      alt="Loading..."
                    />
                  ) : null}
                  <Button
                    type="submit"
                    style={{ width: '100%' }}
                    className={`${isValid} section-margin-new forgot-password-dialog__submit`}
                    disabled={!isValid || isSubmitting}
                  >
                    Reset Password
                  </Button>
                </div>
                <AlertStatus
                  status={status}
                  isApiError={isApiError}
                ></AlertStatus>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {email && !isApiError && <ForgotPasswordConfirm email={email} />}
    </div>
  );
};
