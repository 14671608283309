import React, { useEffect, useState } from 'react';
import { useField, Field } from 'formik';
import { IFormInputProps } from '../../../types/formInputProps.interface';
import { IFormSelectProps } from '../../../types/formSelectProps.interface';
import { IFormCheckBoxProps } from '../../../types/formCheckBoxProps.interface';
import './form-input.scss';
import chevronDownImg from '../../../../assets/icons/icons-system-chevron-down.svg';
import chevronUpImg from '../../../../assets/icons/icons-system-chevron-up.svg';
import Select, { CommonProps, components, ValueType } from 'react-select';
import { customStyles } from '../../../utils/global-functions';
import moment from 'moment';

type OptionType = { label: string; value: string };

export const AppTextInput: React.FC<IFormInputProps> = (props) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely
  const [field] = useField(props);
  return (
    <div
      className={`form-group ${
        props.error && props.touched ? 'input-error' : ''
      }`}
    >
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <Field {...props} {...field} value={field.value == null ? "" : field.value} />
    </div>
  );
};

export const AppDateInput: React.FC<IFormInputProps> = (props) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely
  const [field] = useField(props);
  const displayValue = moment(new Date(field.value)).format('DD/MM/YYYY')
  return (
    <div
      className={`form-group ${
        props.error && props.touched ? 'input-error' : ''
      }`}
    >
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <input {...props} value={displayValue}/>
    </div>
  );
};

export const AppTextAreaInput: React.FC<IFormInputProps> = (props) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely
  const [field] = useField(props);
  return (
    <div
      className={`form-group ${
        props.error && props.touched ? 'input-error' : ''
      }`}
    >
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <textarea {...props} {...field} />
      {props.children}
    </div>
  );
};

export const AppEmailInput: React.FC<IFormInputProps> = (props) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely
  const [field] = useField(props);
  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <input
        className={props.error && props.touched ? 'input-error' : ''}
        data-testid="emailId"
        {...props}
        {...field}
      />
    </div>
  );
};

export const AppPasswordInput: React.FC<IFormInputProps> = (props) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely
  const [field] = useField(props);
  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <input
        className={props.error && props.touched ? 'input-error' : ''}
        {...props}
        {...field}
      />
    </div>
  );
};

export const AppSelectInput: React.FC<IFormSelectProps> = (props) => {
  const [field] = useField(props);
  const [selectedOption, setSelectedOption] = useState<ValueType<OptionType>>();

  useEffect(() => {
    setSelectedOption([{ label: props.val!, value: props.val! }]);
  }, [props.val]);

  const handleChange = (
    option: OptionType,
    fieldName: string,
    setFieldValue: (
      label: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setFieldValue(fieldName, option.value);
    setSelectedOption(option);
  };
  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      CommonProps<any> & {
        children: React.ReactElement<
          any,
          | string
          | ((
              props: any
            ) => React.ReactElement<
              any,
              | string
              | any
              | (new (props: any) => React.Component<any, any, any>)
            > | null)
          | (new (props: any) => React.Component<any, any, any>)
        >;
        innerProps: any;
        isFocused: boolean;
        isRtl: boolean;
        isDisabled: boolean;
      }
  ) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            className="select-dropdown-arrow"
            src={props.selectProps.menuIsOpen ? chevronDownImg : chevronUpImg}
            alt="select arrow"
          />
        </components.DropdownIndicator>
      )
    );
  };

  const handleBlur = () => {
    props.setFieldTouched(props.name, true);
  };
  return (
    <div className="form-group select-form-group">
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <Select
        components={{ DropdownIndicator }}
        isDisabled={props.disabled}
        styles={customStyles}
        defaultValue={selectedOption}
        value={selectedOption}
        classNamePrefix="select"
        className={`select-input ${
          props.error && props.touched ? 'input-error' : ''
        }`}
        options={props.optionArray}
        name={field.name}
        onBlur={handleBlur}
        onChange={(options: ValueType<OptionType>) =>
          handleChange(options as OptionType, field.name, props.setFieldValue)
        }
      ></Select>
    </div>
  );
};

export const AppCheckboxInput: React.FC<IFormCheckBoxProps> = ({
  children,
  ...props
}) => {
  const [field] = useField({ ...props, type: 'checkbox' });
  return (
    <div className="form-group custom-checkbox checkbox-group">
      <label>
        <Field
          className={props.error && props.touched ? 'input-error' : ''}
          {...field}
          {...props}
        ></Field>
        {children}
      </label>
    </div>
  );
};
