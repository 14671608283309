import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import _ from 'underscore';
import { IFormMapProps } from '../../../shared/types/formMapProps.interface';
import './googleplaces.scss';
import { useFormikContext } from 'formik';

const GooglePlaces: React.FC<IFormMapProps> = (props) => {
  const { values } = useFormikContext();

  const handleChange = (value: string) => {
    props.onSetValue(value);
    props.setFieldValue(props.name, value);
  };

  const handleBlur = () => {
    props.setFieldTouched(props.name, true);
  };

  const handleSelect = (value: string) => {
    props.onSetValue(value);
    props.setFieldValue(props.name, value);
    geocodeByAddress(value)
      .then((results) => {
        const addressFields = results[0].address_components;
        const street_number =
          _.find(addressFields, (field) => {
            return field.types[0] == 'street_number';
          })?.long_name || '';
        const route =
          _.find(addressFields, (field) => {
            return field.types[0] == 'route';
          })?.long_name || '';
        const locality =
          _.find(addressFields, (field) => {
            return field.types[0] == 'locality';
          })?.long_name || '';
        const city =
          _.find(addressFields, (field) => {
            return field.types[0] == 'administrative_area_level_1';
          })?.long_name || '';
        const suburb =
          _.find(addressFields, (field) => {
            return field.types[1] == 'sublocality';
          })?.short_name || '';
        const postCode =
          _.find(addressFields, (field) => {
            return field.types[0] == 'postal_code';
          })?.long_name || '';
        props.onSetAddress(
          {
            address: street_number + ' ' + route,
            suburb: suburb,
            city: locality !== '' ? locality : city,
            postCode: postCode,
          },
          values
        );
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        props.onSetAddressPoints({
          lat: latLng.lat.toString(),
          lng: latLng.lng.toString(),
        });
      })
      .catch((error) => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={props.value == null ? "" : props.value}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: {
          country: 'nz',
        },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const isErrorClassName =
          props.error && props.touched ? 'error-location-search-input' : '';
        return (
          <div style={{ position: 'relative' }}>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: `location-search-input ${isErrorClassName}`,
              })}
              onBlur={handleBlur}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.id}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default GooglePlaces;
