import * as Yup from 'yup';
import {
  alphabetWithHypenRegex,
  numberRegex,
  alphabetNumberRegex, maxText
} from '../../../shared/utils/input-regex';

const MemberDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
  lastName: Yup.string()
    .required('Last Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
  dob: Yup.date()
    .required('You must be 18 years or older to register')
    .nullable(),
  phone: Yup.string()
    .min(8, 'Minimum allowed characters 8')
    .max(10, 'Maximum allowed characters 10')
    .matches(
      numberRegex,
      'Mobile number format is incorrect. Accepts only numbers.'
    )
    .nullable(),
  boardName: Yup.string()
    .matches(alphabetWithHypenRegex, 'Board Name must contain letters only.')
    .max(100, `${maxText} 100`),
  address: Yup.string().max(100, 'Maximum allowed characters is 100').nullable(),
  address2: Yup.string().max(100, 'Maximum allowed characters is 100').nullable(),
  suburb: Yup.string().max(60, 'Maximum allowed characters is 60').nullable(),
  city: Yup.string().max(60, 'Maximum allowed characters is 60').nullable(),
  postCode: Yup.string()
    .nullable()
    .matches(numberRegex, 'Post Code must be a number'),
  barId: Yup.number()
    .required('Please select your Guinness Club bar')
    .nullable(),
  shirtSize: Yup.string().required('Please select your T-Shirt size.'),
});

export default MemberDetailsValidationSchema;
