import React from 'react';
import { NewMemberships } from './NewMemberships';
import { MembershipsByLevel } from './MembershipsByLevel';
import { MemberAchievements } from './MemberAchievements';
import './membership-view.scss';

export const MembershipView: React.FC = () => {

  return(
    <div className="membership-view-container">
      <div className="membership-view-container__new-membership">
        <NewMemberships/>
      </div>
      <div className="membership-view-container__level-and-achievements">
        <div className="membership-level">
          <div className="heading">MEMBERSHIPS BY LEVEL</div>
          <MembershipsByLevel/>
        </div>
        <div className="new-memberships">
          <NewMemberships/>
        </div>
        <div className="member-achievements">
          <MemberAchievements/>
        </div>
      </div>
    </div>
  )

}