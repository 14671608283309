import * as Yup from 'yup';
import {
  alphabetWithHypenRegex,
  alphabetNumberRegex,
  numberRegex,
} from '../../../shared/utils/input-regex';

const BarCreateValidationSchema = Yup.object().shape({
  sapCustomerId: Yup.string()
    .required('Sap Customer Id is required')
    .matches(alphabetNumberRegex, 'Sap Customer Id format is incorrect')
    .max(25, 'Maximum allowed characters is 25'),
  name: Yup.string()
    .required('Bar name is required')
    .max(60, 'Maximum allowed characters is 60'),
  address: Yup.string().required('Address is required'),
  address2: Yup.string().max(255, 'Maximum allowed characters is 255'),
  phone: Yup.string()
    .min(8, 'Minimum allowed characters 8')
    .max(10, 'Maximum allowed characters 10')
    .required('Phone is required')
    .matches(
      numberRegex,
      'Mobile number format is incorrect. Accepts only numbers'
    )
    .nullable(),
  email: Yup.string()
    .email('Email format is incorrect')
    .required('Email address is required')
    .max(320, 'Maximum allowed characters is 320'),
  contactFirstName: Yup.string()
    .required('First Name is required')
    .matches(alphabetWithHypenRegex, 'Contact First Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
  contactLastName: Yup.string()
    .required('Last Name is required')
    .matches(alphabetWithHypenRegex, 'Contact Last Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
  barAdminEmail: Yup.string()
    .email('Email format is incorrect')
    .required('Bar Admin Email address is required')
    .max(100, 'Maximum allowed characters is 100'),
  barAdminFirstName: Yup.string()
    .required('Bar Admin First Name is required')
    .matches(alphabetWithHypenRegex, 'Bar Admin First Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
  barAdminLastName: Yup.string()
    .required('Bar Admin Last Name is required')
    .matches(alphabetWithHypenRegex, 'Bar Admin Last Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
});

export default BarCreateValidationSchema;
