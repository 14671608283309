import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers/rootReducer';

const AuthRouteGuard: React.FC<{
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  component: any;
  path: string;
  exact?: boolean;
}> = (parentProps) => {
  const authData = useSelector<AppState, AppState['authData']>(
    (state) => state.authData
  );

  return (
    <Route
      render={(props) => {
        if (authData.isAuth && authData.isAgeVerified) {
          return <parentProps.component {...props} />;
        } else if (
          !authData.isAuth &&
          parentProps.path === '/confirmation/:userName'
        ) {
          return <parentProps.component {...props} />;
        } else if (authData.isAgeVerified && !authData.isAuth) {
          return <Redirect to="/home" />;
        } else {
          return <Redirect to="/agegate" />;
        }
      }}
    />
  );
};

export default AuthRouteGuard;
