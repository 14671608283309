import React from 'react';
import logoImage from '../../../../assets/global-guinness-logo-harp-colour.svg';
import cheersImage from '../../../../assets/global-cheers-logo.svg';
import { Link } from 'react-router-dom';
import './footer.scss';
import { useDispatch } from 'react-redux';
import { showModal } from 'src/actions/commonAction';
import { MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';

const Footer: React.FC = () => {
const dispatch = useDispatch();

const onClickContactus = () => {
    dispatch(showModal(MODAL_DIALOG_NAMES.contactUs));
  }
  return (
    <div id="footer" className="footer-container">
      <div className="footer-container__title-section">
        <img src={logoImage} alt="logo" data-testid="logo-image" />
        <div className="footer-title" data-testid="footer-title">
          The GUINNESS word and associated logos are trademarks. © Guinness &
          Co. 2018
        </div>
      </div>
      <div
        className="footer-container__links-section"
        data-testid="links-section"
      >
        <ul>
          <li>
            <Link to="/termsandcondition">Terms and Conditions</Link>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://lionco.com/legal/privacy-policy/"
            >
              Privacy
            </a>
          </li>
          <li>
          <button data-testid="contact-us" className="btn-link" onClick={() => onClickContactus()}>Contact us</button>
          </li>
        </ul>
      </div>
      <div className="footer-container__cheers-logo">
        <img src={cheersImage} />
      </div>
    </div>
  );
};

export default Footer;
