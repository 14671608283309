import React, { useEffect, useState } from 'react';
import { Login } from './Login';
import { IModalConfig } from '../../shared/types/modalInterface';
import { CustomModal } from '../../shared/components/ui/modal/modal';
import { ForgotPassword } from '../forgot-password/ForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/reducers/rootReducer';
import { hideModal, showModal } from '../../actions/commonAction';
import { MODAL_DIALOG_NAMES } from '../../shared/configs/appConfigs';
import { clearLogin } from '../../actions/authAction';

const LoginModal: React.FC = () => {
  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );

  const authData = useSelector<AppState, AppState['authData']>(
    (state) => state.authData
  );
  useEffect(() => {
    if (authData.isAuth && commonData?.modalName === MODAL_DIALOG_NAMES.login) {
      dispatch(hideModal(MODAL_DIALOG_NAMES.login));
    }
  }, [commonData.isOpenDialog, authData.isAuth]);
  const [showForgotPswdModal, setShowForgotPswdModal] = useState(false);
  const loginModalconfig: IModalConfig = {
    title: 'Login',
    showModal: true,
    modalSize: 'smallModal',
    closeModal: closeModal,
    body: <Login oncancel={closeModal} />,
  };

  const forgotPswdModalconfig: IModalConfig = {
    title: 'Forgot Password',
    showModal: true,
    modalSize: 'smallModal',
    closeModal: closeModal,
    body: <ForgotPassword />,
  };

  const dispatch = useDispatch();

  function closeModal(showPswdModal?: boolean): void {
    if (showPswdModal) {
      setShowForgotPswdModal(true);
      dispatch(showModal(MODAL_DIALOG_NAMES.login));
      dispatch(clearLogin());
    } else {
      setShowForgotPswdModal(false);
      dispatch(hideModal(MODAL_DIALOG_NAMES.login));
      dispatch(clearLogin());
    }
  }
  return (
    <div>
      {commonData?.isOpenDialog &&
        commonData.modalName === MODAL_DIALOG_NAMES.login &&
        showForgotPswdModal === false && (
          <CustomModal config={loginModalconfig}></CustomModal>
        )}
      <div>
        {commonData?.isOpenDialog &&
          commonData.modalName === MODAL_DIALOG_NAMES.login &&
          showForgotPswdModal && (
            <CustomModal config={forgotPswdModalconfig}></CustomModal>
          )}
      </div>
    </div>
  );
};

export default LoginModal;
