import React, {useEffect, useState} from 'react';
import './member-achievements.scss';
import chevronRight from '../../../assets/icons/icons-system-chevron-right.svg';
import chevronLeft from '../../../assets/icons/icons-system-chevron-left.svg';
import chevronRightDisabled from '../../../assets/icons/icons-system-chevron-right-disabled.svg';
import chevronLeftDisabled from '../../../assets/icons/icons-system-chevron-left-disabled.svg';
import {useDispatch, useSelector} from "react-redux";
import { fetchMemberAchievement, fetchTotalMemberRegistration } from '../../../actions/lionAdminAction';
import {AppState} from "../../../reducers/rootReducer";
import loadIcon from '../../../assets/global-element-spinner.svg';
import infoIcon from '../../../assets/icons/icons-system-information-line.svg';
import ReactTooltip from 'react-tooltip';


const MemberAchievementsHeader: React.FC = (props) => {
  return(
    <div className="member-achievements-container">
      <div className="member-achievements-container__header">
        <div className="heading" style={{marginBottom: '32px'}}>
          <div className="text">MONTHLY MEMBER &nbsp;
            <span style={{display: 'inline-block'}}>
              <span style={{marginRight: '16px'}}>ACHIEVEMENTS</span>
              <span data-testid="information-tooltip"  data-tip data-for='information-tooltip' data-event='click focus'>
                <img className="infoIcon" src={infoIcon}/>
              </span>
            </span>
          </div>
          <ReactTooltip globalEventOff='click'  id='information-tooltip' type='light' place='top' effect='solid' multiline={true} clickable={true}>
            <span className="tooltip-text">Member at your bar who have achieved <br/>a new membership level this month</span>
          </ReactTooltip>

        </div>
      </div>
      {props.children}
    </div>
  )
}


export const MemberAchievements: React.FC = () => {

    const [pageTotal, setPageTotal] = useState(1);

    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMemberAchievement(1));

      const callApi = setInterval(() => {
        dispatch(fetchMemberAchievement(1));
      }, 1800000);

      return () => clearInterval(callApi);
    }, []);

    const memberAchievementDataLeft = useSelector<
        AppState,
        AppState['lionAdminData']['memberAchievementDataLeft']
        >((state) => state.lionAdminData.memberAchievementDataLeft);

    const memberAchievementDataRight = useSelector<
        AppState,
        AppState['lionAdminData']['memberAchievementDataRight']
        >((state) => state.lionAdminData.memberAchievementDataRight);

    const memberAchievementTotalItems = useSelector<
        AppState,
        AppState['lionAdminData']['memberAchievementTotalItems']
        >((state) => state.lionAdminData.memberAchievementTotalItems);


  const monthYear = useSelector<
    AppState,
    AppState['lionAdminData']['monthYear']
    >((state) => state.lionAdminData.monthYear);


    useEffect(() => {
        if (memberAchievementTotalItems == 0)
            setPageTotal(0);
        const totalNoOfPages = memberAchievementTotalItems! / 20;
        if (totalNoOfPages % 1 != 0) { //is decimal
          setPageTotal(Math.floor(totalNoOfPages) + 1);
        } else {
          setPageTotal(totalNoOfPages);
        }
    }, [memberAchievementTotalItems]);

    const nextPage = () => {
        if (currentPage < pageTotal) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            dispatch(fetchMemberAchievement((nextPage - 1)  * 20 + 1));
        }
    }

    const previousPage = () => {
        if (currentPage > 1) {
            const previousPage = currentPage - 1;
            setCurrentPage(previousPage);
            dispatch(fetchMemberAchievement((previousPage - 1)  * 20 + 1));
        }
    }

  const isMemberAchievementLoading = useSelector<
    AppState,
    AppState['lionAdminData']['isMemberAchievementLoading']
    >((state) => state.lionAdminData.isMemberAchievementLoading);

  const isMemberAchievementError = useSelector<
    AppState,
    AppState['lionAdminData']['isMemberAchievementError']
    >((state) => state.lionAdminData.isMemberAchievementError);

  const memberAchievementErrorMessage = useSelector<
    AppState,
    AppState['lionAdminData']['memberAchievementErrorMessage']
    >((state) => state.lionAdminData.memberAchievementErrorMessage);

  if (isMemberAchievementError) {
    return (
      <MemberAchievementsHeader>
        <div style={{color: 'red', textAlign: 'center'}}>{memberAchievementErrorMessage}</div>
      </MemberAchievementsHeader>
    )
  }

  if (isMemberAchievementLoading) {
    return(
      <MemberAchievementsHeader>
        <div className="center loading-section" style={{height: '80%'}}>
          <img className="spinner" src={loadIcon} alt="Loading..." />
        </div>
      </MemberAchievementsHeader>
    )
  }

  if (memberAchievementDataLeft?.length == 0) {
    return (
      <MemberAchievementsHeader>
        <div style={{textAlign: 'center'}}>No new member achievements</div>
      </MemberAchievementsHeader>
    )
  }

  return(
    <div className="member-achievements-container">
      <div className="member-achievements-container__header">
        <div className="heading" data-testid="member-achievements-heading">
          <div className="text">MONTHLY MEMBER &nbsp;
            <span style={{display: 'inline-block'}}>
              <span style={{marginRight: '16px'}}>ACHIEVEMENTS</span>
              <span data-tip data-for='information-tooltip' data-event='click focus'>
                <img className="infoIcon" src={infoIcon}/>
              </span>
            </span>
            <ReactTooltip globalEventOff='click' id='information-tooltip' type='light' place='top' effect='solid' multiline={true} clickable={true}>
              <span className="tooltip-text">Members at your bar who have achieved <br/>a new membership level this month</span>
            </ReactTooltip>
          </div>
        </div>
        <div className="paginate" data-testid="member-achievements-paginate">
          <div>
              {
                  currentPage == 1
                      ? <img style={{cursor: 'auto'}} src={chevronRightDisabled}/>
                      : <img onClick={previousPage} src={chevronRight}/>
              }
          </div>
          <div style={{marginLeft: '12px'}}>
              {
                  currentPage == pageTotal
                      ? <img style={{cursor: 'auto'}} src={chevronLeftDisabled}/>
                      : <img onClick={nextPage} src={chevronLeft}/>
              }
          </div>
        </div>
      </div>
      <div className="member-achievements-container__date" data-testid="member-achievements-container-date">{monthYear}</div>
      <div className="member-achievements-container__content">
          <div className="left-hand-side" data-testid="left-hand-side-achievements">
              {
                  memberAchievementDataLeft?.map((data, idx) => {
                    return(
                        <div key={idx} className="achievement-row">
                            <div className="key">{data.memberName}</div>
                            <div className="value">{data.pintsLevelName}</div>
                        </div>
                    )
                })
              }
          </div>
          <div className="right-hand-side" data-testid="right-hand-side-achievements">
              {
                  memberAchievementDataRight?.map((data, idx) => {
                      return(
                          <div key={idx} className="achievement-row">
                              <div className="key">{data.memberName}</div>
                              <div className="value">{data.pintsLevelName}</div>
                          </div>
                      )
                  })
              }
          </div>
      </div>
      <div style={{display: memberAchievementTotalItems! > 0 ? 'block' : 'none'}}
          className="member-achievements-container__page" data-testid="member-achievements-container-page">Page {currentPage} of {pageTotal}</div>
    </div>
  )

}