import React, { useEffect, useState } from 'react';
import { IModalConfig } from '../../../shared/types/modalInterface';
import EmployeeCreate from '../employee-details/EmployeeCreate';
import { CustomModal } from '../../../shared/components/ui/modal/modal';
import { MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/reducers/rootReducer';
import { hideModal } from 'src/actions/commonAction';

const EmployeeCreateModal: React.FC = () => {
  const [showEmployeeCreateModal, setShowEmployeeCreateModal] = useState(false);
  const dispatch = useDispatch();
  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );

  useEffect(() => {
    if(commonData?.isOpenDialog && commonData.modalName === MODAL_DIALOG_NAMES.employeeCreate) {
      setShowEmployeeCreateModal(true);
    } else {
      setShowEmployeeCreateModal(false);
    }
  }, [commonData?.isOpenDialog])

  const employeeCreateModalConfig: IModalConfig = {
    title: commonData?.logoElement,
    isImageTitle: true,
    showModal: true,
    modalSize: 'largeModal largeModalCenterAligned',
    closeModal: closeModal,
    body: <EmployeeCreate />,
  };

  function closeModal(): void {
    dispatch(hideModal(MODAL_DIALOG_NAMES.employeeCreate));
  }

  return (
    <div>
      {showEmployeeCreateModal && <CustomModal config={employeeCreateModalConfig}></CustomModal>}
    </div>
  );
};

export default EmployeeCreateModal;