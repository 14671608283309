import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import MemberInfoValidationSchema from '../member-details/MemberInfoValidationSchema';
import { Form, Formik } from 'formik';
import {
  AppEmailInput,
  AppSelectInput,
  AppTextInput,
  AppDateInput, AppCheckboxInput
} from '../../../shared/components/ui/form-input/FormInput';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import Button from 'react-bootstrap/Button';
import './member-details.scss';
import DatePicker from 'react-datepicker';
import { getAge } from '../../../shared/utils/date';
import {
  fetchMemberInfo,
  editMemberInfo,
  setEditModeMemberInfo,
  clearMemberInfoEdit,
  clearMemberInfoCache
} from '../../../actions/memberAction';
import loadIcon from '../../../assets/global-element-spinner.svg';
import Alert from 'react-bootstrap/Alert';
import CancelEditMemberModal from './CancelEditMemberModal';
import SubmitEditMemberInfoModal from './SubmitEditMemberInfoModal';
import { IMemberInfoPayload } from './MemberInfoPayload';
import moment from 'moment';
import './member-info.scss';


const MemberInfo: React.FC = () => {

  const [
    cancelEditMemberModalShow,
    setCancelEditMemberModalShow,
  ] = useState<boolean>(false);
  const [dob, setDob] = React.useState<Date | null>(null);

  const [submitEditMemberModalShow, setSubmitEditMemberModalShow] = useState<boolean>(false);

  const valueRef = React.useRef<IMemberInfoPayload | null>(null);

  const dispatch = useDispatch();

  const tShirtArr = [
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: '2XL', label: '2XL' },
    { value: '3XL', label: '3XL' },
    { value: '4XL', label: '4XL' },
    { value: '5XL', label: '5XL' },
  ];

  const memberInfo = useSelector<
    AppState,
    AppState['memberData']['memberInfo']
    >((state) => state.memberData.memberInfo);

  const editMode = useSelector<
    AppState,
    AppState['memberData']['isEditModeMemberInfo']
    >((state) => state.memberData.isEditModeMemberInfo);

  const memberData = useSelector<AppState, AppState['memberData']>(
    (state) => state.memberData
  );

  React.useEffect(() => {
    dispatch(fetchMemberInfo());
  }, [dispatch]);


  useEffect(() => {
    if (memberInfo) setDob(new Date(memberInfo.dob));
  }, [memberInfo]);


  useEffect(() => {
    return () => {
      dispatch(clearMemberInfoEdit())
    }
  }, [dispatch])

  function hideCancelEditMemberModalShow(): void {
    setCancelEditMemberModalShow(false);
  }

  function onCancelEditMemberModalShow() {
    setCancelEditMemberModalShow(true);
  }

  const handleChange_age = (
    date: Date,
    setFieldValue: (key: string, value: Date | null) => void,
    setFieldTouched: (key: string, isTouched: boolean | undefined) => void
  ) => {
    if (date) {
      console.log("date: ", date);
      const dob = date;
      const age = getAge(dob);
      if (age && age >= 18) {
        setDob(dob);
        setFieldValue('dob', dob);
      } else {
        setFieldTouched('dob', true);
        setFieldValue('dob', null);
        setDob(null);
      }
    } else {
      setFieldTouched('dob', true);
      setFieldValue('dob', null);
      setDob(null);
    }
  };

  const cancelEdit = () => {
    dispatch(clearMemberInfoCache());
    setTimeout(() => {
      dispatch(fetchMemberInfo());
    }, 1000);
  };

  function submitCancel(): void {
    cancelEdit();
    dispatch(setEditModeMemberInfo(false));
  }

  function hideSubmitEditMemberModalShow(): void {
    setSubmitEditMemberModalShow(false);
  }

  function submitEdit(): void {
    if (valueRef.current!.dob) {
      const date = moment(new Date(valueRef.current!.dob)).add(1, 'days');
      valueRef.current!.dob = date.toISOString();
    }
    dispatch(setEditModeMemberInfo(false));
    dispatch(editMemberInfo(valueRef.current!));
  }

  function onSubmitEditMemberModalShow(values:  IMemberInfoPayload){
    valueRef.current = values;
    setSubmitEditMemberModalShow(true);
  }

  return (
    <div className="member-info-dashboard-section">
      <h3 className="member-info-dashboard-section__heading" data-testid="member-info-dashboard-section__heading">
        {memberData.isMemberInfoLoading ? (
          <div className="loader loading-icon" style={{marginBottom: '20px'}}>
            <img
              className="spinner"
              src={loadIcon}
              alt="Loading..."
            />
          </div>
        ) : null}
        {memberInfo?.firstName} {memberInfo?.lastName}
      </h3>
      <Formik
        initialValues={memberInfo!}
        validationSchema={MemberInfoValidationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={async (values) => {
          // if (values.dob) {
          //   values.dob = new Date(values.dob).toISOString();
          // }
          // dispatch(editMemberDetails(values));
          // dispatch(setEditMode(false));
        }}
      >
        {({ setFieldValue, setFieldTouched, errors, touched, isValid, values }) => {
          return (
            <Form autoComplete="off">
              <div className="member-info-dashboard-section__form">
                <div className="member-info-dashboard-section__form-section">
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="First Name"
                      data-testid="First Name"
                      id="firstName"
                      name="firstName"
                      type="text"
                      error={errors.firstName}
                      touched={touched.firstName}
                      maxLength={60}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.firstName}
                      touched={touched.firstName}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="Last Name"
                      data-testid="Last Name"
                      id="lastName"
                      name="lastName"
                      type="text"
                      error={errors.lastName}
                      touched={touched.lastName}
                      maxLength={60}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field" data-testid="shirt-size-field">
                    <AppSelectInput
                      label="Shirt Size"
                      id="shirtSize"
                      name="shirtSize"
                      placeholder="Pick a shirt size"
                      val={memberInfo?.shirtSize}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      error={errors.shirtSize}
                      touched={touched.shirtSize}
                      optionArray={tShirtArr}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.shirtSize}
                      touched={touched.shirtSize}
                    />
                  </div>
                  <div
                    className="member-info-dashboard-section__field"
                  >
                    <AppTextInput
                      label="Bar Name"
                      id="barName"
                      name="barName"
                      type="text"
                      error={errors.barName}
                      touched={touched.barName}
                      maxLength={60}
                      disabled={true}
                    />
                    <ValidationErrorTemplate
                      error={errors.barName}
                      touched={touched.barName}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="Board Name"
                      data-testid="Board Name"
                      id="boardName"
                      name="boardName"
                      type="text"
                      error={errors.boardName}
                      touched={touched.boardName}
                      maxLength={50}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.boardName}
                      touched={touched.boardName}
                    />
                  </div>
                  <div
                    className="member-info-dashboard-section__field"
                  >
                    <AppTextInput
                      label="Address 1"
                      data-testid="Address 1"
                      id="address"
                      name="address"
                      type="text"
                      error={errors.address}
                      touched={touched.address}
                      maxLength={100}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.address}
                      touched={touched.address}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="Suburb"
                      data-testid="Suburb"
                      id="suburb"
                      name="suburb"
                      type="text"
                      maxLength={60}
                      error={errors.suburb}
                      touched={touched.suburb || false}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.suburb}
                      touched={touched.suburb}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="City"
                      data-testid="City"
                      id="city"
                      name="city"
                      maxLength={60}
                      type="text"
                      error={errors.city}
                      touched={touched.city || false}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.city}
                      touched={touched.city}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="Post Code"
                      data-testid="Post Code"
                      id="postCode"
                      name="postCode"
                      type="text"
                      maxLength={4}
                      error={errors.postCode}
                      touched={touched.postCode || false}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.postCode}
                      touched={touched.postCode}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="Address 2"
                      data-testid="Address 2"
                      id="address2"
                      name="address2"
                      type="text"
                      maxLength={100}
                      error={errors.address2}
                      touched={touched.address2}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.address2}
                      touched={touched.address2}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppEmailInput
                      label="Email address"
                      id="email"
                      name="email"
                      type="email"
                      placeholder=""
                      maxLength={320}
                      error={errors.email}
                      touched={touched.email}
                      disabled={true}
                    />
                    <ValidationErrorTemplate
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <AppTextInput
                      label="Phone"
                      id="phone"
                      name="phone"
                      type="text"
                      minLength={10}
                      maxLength={10}
                      touched={touched.phone}
                      error={errors.phone}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.phone}
                      touched={touched.phone}
                    />
                  </div>
                  <div className="member-info-dashboard-section__field">
                    <div className="form-group-register" data-testid="date-of-birth-field">
                      <label>Date of Birth</label>
                      <DatePicker
                        selected={dob}
                        name="dob"
                        id="dob"
                        className={`test-class ${
                          errors.dob && touched.dob ? 'input-error' : ''
                        }`}
                        ariaLabelledBy="Date of Birth"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        data-testid="testIdDob"
                        showYearDropdown
                        onChange={(date: Date) =>
                          handleChange_age(date, setFieldValue, setFieldTouched)
                        }
                        disabled={!editMode}
                      />
                      <ValidationErrorTemplate
                        error={errors.dob}
                        touched={touched.dob}
                      />
                    </div>
                  </div>
                  <div className="member-info-dashboard-section__field marketing-checkbox" data-testid="marketing-checkbox-field">
                    <AppCheckboxInput
                      name="hasAcceptedMarketingComm"
                      type="checkbox"
                      touched={touched.hasAcceptedMarketingComm}
                      error={errors.hasAcceptedMarketingComm}
                      disabled={!editMode}
                    >
                      Accept marketing information
                    </AppCheckboxInput>
                  </div>
                </div>

                <div className="member-info-dashboard-section__buttons">
                  {!editMode && (
                    <Button
                      data-testid="submit-btn"
                      variant="primary"
                      type="button"
                      onClick={() => {
                        dispatch(setEditModeMemberInfo(true));
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {editMode && (
                    <Button
                      variant="primary"
                      className="update-button"
                      type="submit"
                      onClick={() => onSubmitEditMemberModalShow(values)}
                      disabled={!isValid || memberData.isEditMemberInfoLoading}
                    >
                      Update
                    </Button>
                  )}
                  {editMode && (
                    <Button
                      className="btn btn-primary btn-outline-secondary cancel-button"
                      type="button"
                      onClick={() => onCancelEditMemberModalShow()}
                      style={{
                        display:
                          Object.keys(touched).length === 0 ? 'none' : 'block',
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {memberData.isEditMemberInfoLoading ? (
                    <div className="loader loading-icon">
                      <img
                        className="spinner"
                        src={loadIcon}
                        alt="Loading..."
                      />
                    </div>
                  ) : null}
                </div>
                {memberData.isEditMemberInfoSuccess ? (
                  <Alert variant="success" style={{ margin: '0 15px' }}>
                    {memberData.editMemberInfoMessage}
                  </Alert>
                ) : null}
                {memberData.isEditMemberInfoError ? (
                  <Alert variant="danger" style={{ margin: '0 15px' }}>
                    {memberData.editMemberInfoMessage}
                  </Alert>
                ) : null}
                {cancelEditMemberModalShow && (
                  <CancelEditMemberModal
                    hideModalShow={hideCancelEditMemberModalShow}
                    onSubmitCancel={submitCancel}
                  />
                )}
                {submitEditMemberModalShow && (
                  <SubmitEditMemberInfoModal
                    hideModalShow={hideSubmitEditMemberModalShow}
                    onSubmit={submitEdit}
                    values={values}
                    memberInfo={memberInfo!}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MemberInfo;
