import { Dispatch } from 'redux';
import { AppActions, MemberResponseDTO } from './actions';
import axios from 'axios';
import { BASE_API_URL } from '../shared/configs/appConfigs';
import moment from 'moment';
import { IAddMemberPintsReqModal } from 'src/features/dashboard/member-list/AddMemberPintsReqModal.interface';
import { PaginationData } from 'src/shared/types/paginationData.interface';
import { store } from '../store/configureStore';
import { DefaultPagination } from '../shared/components/ui/data-table/PaginationDefaultConfig';
import { ValueType } from 'react-select';
import { IMemberDetailsPayload } from '../features/dashboard/member-details/MemberDetailsPayload';
import { getUserId, getUserRole } from '../shared/utils/global-functions';
import { IMemberInfoPayload } from '../features/dashboard/member-details/MemberInfoPayload';
import { fetchAsyncMembershipLevel, fetchAsyncMemberAchievement, fetchAsyncTotalMembershipLevel } from './lionAdminAction';

export const FETCH_MEMBER_LIST_STARTED = 'FETCH_MEMBER_LIST_STARTED';
export const FETCH_MEMBER_LIST_SUCCEEDED = 'FETCH_MEMBER_LIST_SUCCEEDED';
export const FETCH_MEMBER_LIST_FAILED = 'FETCH_MEMBER_LIST_FAILED';
export const SAVE_SELECTED_MEMBER = 'SAVE_SELECTED_MEMBER';
export const SAVE_MEMBER_PAGINATION = 'SAVE_MEMBER_PAGINATION';
export const ADD_MEMBER_PINTS_SUCCEEDED = 'ADD_MEMBER_PINTS_SUCCEEDED';
export const ADD_MEMBER_PINTS_FAILED = 'ADD_MEMBER_PINTS_FAILED';
export const ADD_MEMBER_PINTS_STARTED = 'ADD_MEMBER_PINTS_STARTED';

async function fetchAsyncMemberList(
  dispatch: Dispatch<AppActions>,
  currentIndex: number,
  sizePerPage: number,
  searchValue: string,
  sortOrder: string,
  columnName: string
) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const status = store.getState().memberData.displayingMember;
    let displayStatus = 'Active';
    if (status) {
      displayStatus = (status as {label: string, value: string}).value
    }
    const res = await axios.get(
      `${BASE_API_URL}bar/member/list?barId=${selectedBarId}&start=${currentIndex}&limit=${sizePerPage}&searchValue=${searchValue}&sortOrder=${sortOrder}&columnName=${columnName}&status=${displayStatus}`
    );
    const data = res.data?.data?.pagedItems.map((obj: MemberResponseDTO) => {
      if (obj.dateRegistered) {
        obj.dateRegistered = moment(new Date(obj.dateRegistered)).format(
          'DD/MM/YYYY'
        );
        return obj;
      }
      return obj;
    });
    const arr: MemberResponseDTO[] = [...data];
    const selectedMember = arr.find(
      (obj: MemberResponseDTO) =>
        obj.memberId === store.getState().memberData.selectedMember?.memberId
    );
    dispatch({
      type: FETCH_MEMBER_LIST_SUCCEEDED,
      payload: {
        memberList: data,
        totalMembers: res.data.data.totalItems,
        overAllTotal: res.data.overAllTotalItems,
        selectedMember: selectedMember ? selectedMember : store.getState().memberData.selectedMember,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_MEMBER_LIST_FAILED,
    });
  }
}

async function addAsyncMemberPints(
  dispatch: Dispatch<AppActions>,
  reqpayload: IAddMemberPintsReqModal
) {
  try {
    await axios.put(`${BASE_API_URL}member/pints`, reqpayload);
    dispatch({
      type: ADD_MEMBER_PINTS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: ADD_MEMBER_PINTS_FAILED,
    });
  }
}

async function addAndFetchAsyncMemberPints(
  dispatch: Dispatch<AppActions>,
  addMemberPintsReqPayload: IAddMemberPintsReqModal
) {
  await addAsyncMemberPints(dispatch, addMemberPintsReqPayload);
  const paginationData: PaginationData =
    store.getState().memberData.paginationHistory || DefaultPagination;
  await fetchAsyncMemberList(
    dispatch,
    paginationData?.currentIndex,
    paginationData?.sizePerPage,
    paginationData?.searchValue,
    paginationData?.sortOrder,
    paginationData?.columnName
  );
  await fetchAsyncMemberAchievement(1, dispatch);
  if (getUserRole() && getUserRole().toLowerCase() === 'Admin'.toLowerCase()) {
    await fetchAsyncTotalMembershipLevel(dispatch);
  }
  if (getUserRole() && getUserRole().toLowerCase() === 'BarAdmin'.toLowerCase()) {
    await fetchAsyncMembershipLevel(dispatch);
  }
}

export const addMemberPints = (
  addMemberPintsReqPayload: IAddMemberPintsReqModal
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: ADD_MEMBER_PINTS_STARTED,
    });
    return addAndFetchAsyncMemberPints(dispatch, addMemberPintsReqPayload);
  };
};
export const saveMemberListPagination = (
  currentIndex: number,
  sizePerPage: number,
  searchValue: string,
  sortOrder: string,
  columnName: string
): AppActions => {
  return {
    type: SAVE_MEMBER_PAGINATION,
    payload: {
      paginationHistory: {
        currentIndex: currentIndex,
        sizePerPage: sizePerPage,
        searchValue: searchValue,
        sortOrder: sortOrder,
        columnName: columnName,
      },
    },
  };
};

export const fetchMemberList = (): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_MEMBER_LIST_STARTED,
    });
    const paginationData: PaginationData =
      store.getState().memberData.paginationHistory || DefaultPagination;
    return fetchAsyncMemberList(
      dispatch,
      paginationData?.currentIndex,
      paginationData?.sizePerPage,
      paginationData?.searchValue,
      paginationData?.sortOrder,
      paginationData?.columnName
    );
  };
};

export const saveSelectedMember = (member: MemberResponseDTO): AppActions => {
  return {
    type: SAVE_SELECTED_MEMBER,
    payload: {
      selectedMember: member,
    },
  };
};

export const updateSelectedMemberForMobile = (): AppActions => {
  const selectedMember = store.getState().memberData.selectedMember;
  return {
    type: SAVE_SELECTED_MEMBER,
    payload: {
      selectedMember: {
        ...selectedMember,
        pintLevel: store.getState().memberData.memberDetails!.pintLevel
      } as MemberResponseDTO,
    },
  };
};

export const SET_DISPLAYING_MEMBER_FOR_BAR_ADMIN = 'SET_DISPLAYING_MEMBER_FOR_BAR_ADMIN';
export const setDisplayingMember = (value: ValueType<{ label: string; value: string }>): AppActions => {
  return {
    type: SET_DISPLAYING_MEMBER_FOR_BAR_ADMIN,
    payload: {
      displayingMember: value
    }
  }
}

async function fetchAsyncMemberDetails(dispatch: Dispatch<AppActions>) {
  try {
    const selectedMemberId = store.getState().memberData.selectedMember
      ?.memberId;
    const res = await axios.get(
      `${BASE_API_URL}member/${selectedMemberId}`
    );
    dispatch({
      type: FETCH_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED,
      payload: {
        memberDetails: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_BAR_ADMIN_MEMBER_DETAILS_FAILED,
    });
  }
}

export const FETCH_BAR_ADMIN_MEMBER_DETAILS_STARTED = 'FETCH_BAR_ADMIN_MEMBER_DETAILS_STARTED';
export const FETCH_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED = 'FETCH_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED';
export const FETCH_BAR_ADMIN_MEMBER_DETAILS_FAILED = 'FETCH_BAR_ADMIN_MEMBER_DETAILS_FAILED';
export const fetchMemberDetails = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_BAR_ADMIN_MEMBER_DETAILS_STARTED,
    });
    return fetchAsyncMemberDetails(dispatch);
  };
};

async function editAsyncMemberDetails(
  formValues: IMemberDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  try {
    const requestPayload = {
      ...formValues,
      pintLevel: parseInt(formValues.pintLevel.toString())
    };
    const res = await axios.put(`${BASE_API_URL}Member/member`, requestPayload);
    dispatch({
      type: EDIT_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: EDIT_BAR_ADMIN_MEMBER_DETAILS_FAILED,
    });
  }
}

async function editAsyncMemberDetailsAndFetch(
  formValues: IMemberDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  await editAsyncMemberDetails(formValues, dispatch);
  await fetchAsyncMemberDetails(dispatch);
  const paginationData: PaginationData =
    store.getState().memberData.paginationHistory || DefaultPagination;
  await fetchAsyncMemberList(
    dispatch,
    paginationData?.currentIndex,
    paginationData?.sizePerPage,
    paginationData?.searchValue,
    paginationData?.sortOrder,
    paginationData?.columnName
  );
  if (getUserRole() && getUserRole().toLowerCase() === 'BarAdmin'.toLowerCase()) {
    await fetchAsyncMembershipLevel(dispatch);
    await fetchAsyncMemberAchievement(1, dispatch);
  }
}

export const EDIT_BAR_ADMIN_MEMBER_DETAILS_STARTED = 'EDIT_BAR_ADMIN_MEMBER_DETAILS_STARTED';
export const EDIT_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED = 'EDIT_BAR_ADMIN_MEMBER_DETAILS_SUCCEEDED';
export const EDIT_BAR_ADMIN_MEMBER_DETAILS_FAILED = 'EDIT_BAR_ADMIN_MEMBER_DETAILS_FAILED';
export const editMemberDetails = (
  formValues: IMemberDetailsPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_BAR_ADMIN_MEMBER_DETAILS_STARTED,
    });
    return editAsyncMemberDetailsAndFetch(formValues, dispatch);
  };
};

export const SET_EDIT_MODE_BAR_ADMIN_MEMBER_DETAILS = 'SET_EDIT_MODE_BAR_ADMIN_MEMBER_DETAILS';
export const setEditMode = (isEditMode: boolean): AppActions => {
  return {
    type: SET_EDIT_MODE_BAR_ADMIN_MEMBER_DETAILS,
    payload: {
      isEditMode
    }
  };
};

export const SHOW_BAR_ADMIN_MEMBER_DETAILS = 'SHOW_BAR_ADMIN_MEMBER_DETAILS';
export const showMemberDetails = (): AppActions => {
  return {
    type: SHOW_BAR_ADMIN_MEMBER_DETAILS,
  };
};

export const HIDE_BAR_ADMIN_MEMBER_DETAILS = 'HIDE_BAR_ADMIN_MEMBER_DETAILS';
export const hideMemberDetails = (): AppActions => {
  return {
    type: HIDE_BAR_ADMIN_MEMBER_DETAILS,
  };
};

export const CLEAR_BAR_ADMIN_MEMBER_EDIT = 'CLEAR_BAR_ADMIN_MEMBER_EDIT';
export const clearMemberEdit = (): AppActions => {
  return {
    type: CLEAR_BAR_ADMIN_MEMBER_EDIT,
  };
};

export const SET_BAR_ADMIN_MEMBER_PINT_LEVEL = 'SET_BAR_ADMIN_MEMBER_PINT_LEVEL';
export const setMemberPintLevel = (
  newPintPoints: number,
  isAdd: boolean
): AppActions => {
  const selectedMember = store.getState().memberData.selectedMember!;
  const existingPintLevel = selectedMember ? selectedMember.pintLevel : 0;
  const updatedPintLevel = existingPintLevel + newPintPoints;
  return {
    type: SET_BAR_ADMIN_MEMBER_PINT_LEVEL,
    payload: {
      selectedMember: {
        ...selectedMember,
        pintLevel: updatedPintLevel,
      },
    },
  };
};

export const CLEAR_BAR_ADMIN_BAR_MEMBER_CACHE = 'CLEAR_BAR_ADMIN_BAR_MEMBER_CACHE';
export const clearMemberCache = (): AppActions => {
  const dateOfBirth = store.getState().memberData.memberDetails!.dob;
  const dateRegistered = store.getState().memberData.memberDetails!.dateRegistered;
  return {
    type: CLEAR_BAR_ADMIN_BAR_MEMBER_CACHE,
    payload: {
      memberDetails: {
        memberId: 0,
        firstName: '',
        lastName: '',
        address2: '',
        phone: '',
        email: '',
        boardName: '',
        pintLevel: 0,
        shirtSize: '',
        dob: dateOfBirth || '1970-01-01T00:00:00',
        address: '',
        suburb: '',
        city: '',
        postCode: '',
        dateRegistered: dateRegistered || '1970-01-01T00:00:00',
        barId: 0,
        barName: '',
        isActive: false
      },
    },
  };
};


async function fetchAsyncMemberDashboardDetails(dispatch: Dispatch<AppActions>) {
  try {
    const memberId = getUserId();
    const res = await axios.get(
        `${BASE_API_URL}member/${memberId}/dashboard`
    );
    dispatch({
      type: FETCH_MEMBER_DASHBOARD_DETAILS_SUCCEEDED,
      payload: {
        memberDashboardDetails: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_MEMBER_DASHBOARD_DETAILS_FAILED,
    });
  }
}

export const FETCH_MEMBER_DASHBOARD_DETAILS_STARTED = 'FETCH_MEMBER_DASHBOARD_DETAILS_STARTED';
export const FETCH_MEMBER_DASHBOARD_DETAILS_SUCCEEDED = 'FETCH_MEMBER_DASHBOARD_DETAILS_SUCCEEDED';
export const FETCH_MEMBER_DASHBOARD_DETAILS_FAILED = 'FETCH_MEMBER_DASHBOARD_DETAILS_FAILED';
export const fetchMemberDashboardDetails = (): ((
    dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_MEMBER_DASHBOARD_DETAILS_STARTED,
    });
    return fetchAsyncMemberDashboardDetails(dispatch);
  };
};

async function fetchAsyncMemberInfo(dispatch: Dispatch<AppActions>) {
  try {
    const memberId = store.getState().memberData.memberDashboardDetails?.memberId;
    const res = await axios.get(
      `${BASE_API_URL}member/${memberId}`
    );
    dispatch({
      type: FETCH_MEMBER_INFO_SUCCEEDED,
      payload: {
        memberInfo: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_MEMBER_INFO_FAILED,
    });
  }
}

export const FETCH_MEMBER_INFO_STARTED = 'FETCH_MEMBER_INFO_STARTED';
export const FETCH_MEMBER_INFO_SUCCEEDED = 'FETCH_MEMBER_INFO_SUCCEEDED';
export const FETCH_MEMBER_INFO_FAILED = 'FETCH_MEMBER_INFO_FAILED';
export const fetchMemberInfo = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_MEMBER_INFO_STARTED,
    });
    return fetchAsyncMemberInfo(dispatch);
  };
};

async function editAsyncMemberInfo(
  formValues: IMemberInfoPayload,
  dispatch: Dispatch<AppActions>
) {
  try {
    const requestPayload = {
      ...formValues
    };
    const res = await axios.put(`${BASE_API_URL}member/member`, requestPayload);
    dispatch({
      type: EDIT_MEMBER_INFO_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: EDIT_MEMBER_INFO_FAILED,
    });
  }
}

async function editAsyncMemberInfoAndFetch(
  formValues: IMemberInfoPayload,
  dispatch: Dispatch<AppActions>
) {
  await editAsyncMemberInfo(formValues, dispatch);
  await fetchAsyncMemberInfo(dispatch);
  await fetchAsyncMemberDashboardDetails(dispatch);
}

export const EDIT_MEMBER_INFO_STARTED = 'EDIT_MEMBER_INFO_STARTED';
export const EDIT_MEMBER_INFO_SUCCEEDED = 'EDIT_MEMBER_INFO_SUCCEEDED';
export const EDIT_MEMBER_INFO_FAILED = 'EDIT_MEMBER_INFO_FAILED';
export const editMemberInfo = (
  formValues: IMemberInfoPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_MEMBER_INFO_STARTED,
    });
    return editAsyncMemberInfoAndFetch(formValues, dispatch);
  };
};

export const SET_EDIT_MODE_MEMBER_INFO = 'SET_EDIT_MODE_MEMBER_INFO';
export const setEditModeMemberInfo = (isEditModeMemberInfo: boolean): AppActions => {
  return {
    type: SET_EDIT_MODE_MEMBER_INFO,
    payload: {
      isEditModeMemberInfo
    }
  };
};

export const CLEAR_MEMBER_INFO_EDIT = 'CLEAR_MEMBER_INFO_EDIT';
export const clearMemberInfoEdit = (): AppActions => {
  return {
    type: CLEAR_MEMBER_INFO_EDIT,
  };
};

export const CLEAR_MEMBER_INFO_CACHE = 'CLEAR_MEMBER_INFO_CACHE';
export const clearMemberInfoCache = (): AppActions => {
  const dateOfBirth = store.getState().memberData.memberInfo!.dob;
  const dateRegistered = store.getState().memberData.memberInfo!.dateRegistered;

  return {
    type: CLEAR_MEMBER_INFO_CACHE,
    payload: {
      memberInfo: {
        memberId: 0,
        firstName: '',
        lastName: '',
        address2: '',
        phone: '',
        email: '',
        boardName: '',
        pintLevel: 0,
        shirtSize: '',
        dob: dateOfBirth,
        address: '',
        suburb: '',
        city: '',
        postCode: '',
        dateRegistered: dateRegistered,
        barId: 0,
        barName: '',
        hasAcceptedMarketingComm: false
      },
    },
  };
};

async function editAsyncShirtSize(
  shirtSize: string,
  dispatch: Dispatch<AppActions>
) {
  try {
    const memberId = store.getState().memberData.memberDashboardDetails?.memberId;
    const res = await axios.put(`${BASE_API_URL}member/${memberId}/tshirtsize/${shirtSize}`);
    dispatch({
      type: EDIT_SHIRT_SIZE_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: EDIT_SHIRT_SIZE_FAILED,
    });
  }
}

async function editAsyncShirtSizeAndFetch(
  shirtSize: string,
  dispatch: Dispatch<AppActions>
) {
  await editAsyncShirtSize(shirtSize, dispatch);
  await fetchAsyncMemberDashboardDetails(dispatch);
}


export const EDIT_SHIRT_SIZE_STARTED = 'EDIT_SHIRT_SIZE_STARTED';
export const EDIT_SHIRT_SIZE_SUCCEEDED = 'EDIT_SHIRT_SIZE_SUCCEEDED';
export const EDIT_SHIRT_SIZE_FAILED = 'EDIT_SHIRT_SIZE_FAILED';

export const editShirtSize = (
  shirtSize: string
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_SHIRT_SIZE_STARTED,
    });
    return editAsyncShirtSizeAndFetch(shirtSize, dispatch);
  };
};

