import React, { useEffect, useRef, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { IAgeGateInput } from '../../shared/types/ageGateInput.interface';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import './age-gate.scss';
import logo from '../../assets/guinness-logo-light.svg';
import Button from 'react-bootstrap/Button';
import bgImage from '../../assets/guinness-logo-harp-white.svg';
import { getAge } from '../../shared/utils/date';
import { AgegateValidationSchema } from './AgegateValidationSchema';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ValidationErrorTemplate from 'src/shared/components/ui/validation-error/ValidationError';
import {
  setAboveEighteen,
  setNotAboveEighteen,
} from '../../actions/authAction';
import { useAgeGateCookie } from '../../shared/hooks/useAgeGateCookie';
import { AppState } from '../../reducers/rootReducer';

export const AgeGate: React.FC = () => {
  document.body.style.backgroundColor = '#030628';
  document.body.style.backgroundImage = `url${bgImage}`;
  const initialFormValues: IAgeGateInput = {
    date: '',
    month: '',
    year: '',
  };
  const commonErrorText = 'Please provide correct date format';
  const { setAgeGateCookie, removeAgeGateCookie } = useAgeGateCookie();
  const [ageBelowEighteenError, setAgeBelowEighteenError] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#030628';
    document.body.style.backgroundImage = `url${bgImage}`;
  }, []);

  const authData = useSelector<AppState, AppState['authData']>(
    (state) => state.authData
  );
  const dispatch = useDispatch();

  const dateRef = useRef<HTMLInputElement>(null);

  const monthRef = useRef<HTMLInputElement>(null);

  const yearRef = useRef<HTMLInputElement>(null);

  const submitRef = useRef<HTMLButtonElement>(null);

  const focusChangeForDate = (e: React.FormEvent<HTMLInputElement>) => {
    setAgeBelowEighteenError(false);
    if (
      (e.target as HTMLInputElement).value.length >=
      parseInt((e.target as HTMLInputElement).getAttribute('maxlength')!)
    ) {
      setTimeout(() => {
        monthRef.current!.focus();
      }, 200);
    }
  };

  const focusChangeForMonth = (e: React.FormEvent<HTMLInputElement>) => {
    setAgeBelowEighteenError(false);
    if (
      (e.target as HTMLInputElement).value.length >=
      parseInt((e.target as HTMLInputElement).getAttribute('maxlength')!)
    ) {
      setTimeout(() => {
        yearRef.current!.focus();
      }, 200);
    }
  };

  const focusChangeForYear = (e: React.FormEvent<HTMLInputElement>) => {
    setAgeBelowEighteenError(false);
    if (
      (e.target as HTMLInputElement).value.length >=
      parseInt((e.target as HTMLInputElement).getAttribute('maxlength')!)
    ) {
      setTimeout(() => {
        submitRef.current!.focus();
      }, 200);
    }
  };

  const commonErrorMessage = (
    err: FormikErrors<IAgeGateInput>,
    touched: FormikTouched<IAgeGateInput>
  ) => {
    if (err?.date) {
      return (
        <ValidationErrorTemplate
          error={commonErrorText}
          touched={touched.date}
        />
      );
    } else if (err?.month) {
      return (
        <ValidationErrorTemplate
          error={commonErrorText}
          touched={touched.month}
        />
      );
    } else if (err?.year) {
      return (
        <ValidationErrorTemplate
          error={commonErrorText}
          touched={touched.year}
        />
      );
    } else {
      return null;
    }
  };
  return !authData.isAgeVerified ? (
    <div className="age-gate-container">
      <div className="d-flex flex-column align-items-center input-container">
        <div className="d-flex section-margin img-container">
          <img src={logo} alt="Agegate Logo" />
        </div>
        <h4 className="section-margin">Please enter your date of birth</h4>
        <Formik
          initialValues={initialFormValues}
          validationSchema={AgegateValidationSchema}
          validateOnMount
          onSubmit={async (values) => {
            const dateObj = new Date(
              `${values.month}-${+values.date}-${+values.year}`.replace(
                /-/g,
                '/'
              )
            );
            const age = getAge(dateObj);
            if (isNaN(age)) {
              setAgeBelowEighteenError(true);
              removeAgeGateCookie();
              dispatch(setNotAboveEighteen(false));
            } else {
              if (age && age >= 18) {
                setAgeBelowEighteenError(false);
                setAgeGateCookie();
                dispatch(setAboveEighteen());
              } else {
                setAgeBelowEighteenError(true);
                removeAgeGateCookie();
                dispatch(setNotAboveEighteen(true));
              }
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            isValid,
            isSubmitting,
            values,
            handleSubmit,
          }) => (
            <Form
              className="d-flex flex-column justify-content-between align-items-center"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex-flex-column date-container">
                  <div
                    className={`form-group ${
                      errors.date && touched.date ? 'error-display' : ''
                    }`}
                  >
                    <input
                      type="text"
                      ref={dateRef}
                      data-testid="date"
                      name="date"
                      id="date"
                      maxLength={2}
                      minLength={2}
                      onChange={(event) => {
                        focusChangeForDate(event);
                        handleChange(event);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      placeholder="DD"
                    />
                  </div>
                </div>
                <div className="d-flex-flex-column month-container">
                  <div
                    className={`form-group ${
                      errors.month && touched.month ? 'error-display' : ''
                    }`}
                  >
                    <input
                      name="month"
                      ref={monthRef}
                      id="month"
                      value={values.month}
                      onChange={(event) => {
                        focusChangeForMonth(event);
                        handleChange(event);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      placeholder="MM"
                      maxLength={2}
                      minLength={2}
                    />
                  </div>
                </div>
                <div className="d-flex-flex-column year-container">
                  <div
                    className={`form-group ${
                      errors.year && touched.year ? 'error-display' : ''
                    }`}
                  >
                    <input
                      name="year"
                      ref={yearRef}
                      id="year"
                      value={values.year}
                      onChange={(event) => {
                        focusChangeForYear(event);
                        handleChange(event);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      placeholder="YYYY"
                      maxLength={4}
                      minLength={4}
                    />
                  </div>
                </div>
              </div>
              {commonErrorMessage(errors, touched)}
              {!errors.date &&
                !errors.month &&
                !errors.year &&
                ageBelowEighteenError && (
                  <ValidationErrorTemplate
                    error="You must be 18+"
                    touched={touched.date || touched.month || touched.year}
                  />
                )}
              <Button
                type="submit"
                ref={submitRef}
                className="section-margin"
                disabled={!isValid || isSubmitting}
              >
                Enter
              </Button>
              {authData.authErrorMessage && (
                <Alert key="2" variant="primary">
                  {authData.authErrorMessage}
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  ) : (
    <Redirect to="/home" />
  );
};
