import * as Yup from 'yup';
import { maxText, pswdRegex } from '../../shared/utils/input-regex';
import { PASSWORD_MAX_LENGTH } from '../../shared/configs/appConfigs';

export const LoginValidationSchema = Yup.object().shape({
  userName: Yup.string()
    .email('Email format is incorrect')
    .required('Email address is required')
    .max(320, `${maxText}  320 `),
  password: Yup.string()
    .required('Required')
    .matches(
      pswdRegex,
      'Password format is incorrect (Atleast 1 lowercase, uppercase, special character)'
    )
    .min(8, 'Too short. minimum 8 characters')
    .max(PASSWORD_MAX_LENGTH, `${maxText} ${PASSWORD_MAX_LENGTH}`),
});
