import React, { useEffect, useState } from 'react';
import { ContactUsForm } from './ContactUsForm';
import './contact-us.scss';
import { ContentfulClientConfigs } from '../../shared/configs/ContentfulClientConfigs';

export const ContactUsPage: React.FC = () => {
  const [logoImage, setLogoImage] = useState('');
  const [contactUsImage, setContactUsImage] = useState('');

  useEffect(() => {
    ContentfulClientConfigs.getAsset('Y6JXM2thjGSDhMD3lvOWz').then((asset) => {
      setContactUsImage(`${asset.fields.file.url}`);
    });

    ContentfulClientConfigs.getAsset('75CPwoYgUs5aom8XI0jzTi').then((asset) => {
      setLogoImage(`${asset.fields.file.url}`);
    });
  }, []);

  return (
    <div className="contact-us">
      <div
        data-testid="section-image"
        className="contact-us__section-image"
        style={{ backgroundImage: 'url(' + contactUsImage + ')' }}
      >
        <img
          data-testid="logo-image"
          className="contact-us__logo-image"
          src={logoImage}
          alt="Logo"
        />
      </div>
      <div data-testid="section-form" className="contact-us__section-form">
        <ContactUsForm />
      </div>
    </div>
  );
};
