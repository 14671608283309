import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers/rootReducer';

const AgeGateRouteGuard: React.FC<{
  component: React.FC;
  path: string;
  exact?: boolean;
}> = (parentProps) => {
  const isAgeVerified = useSelector<
    AppState,
    AppState['authData']['isAgeVerified']
  >((state) => state.authData.isAgeVerified);

  return (
    <Route
      render={() =>
        isAgeVerified ? <parentProps.component /> : <Redirect to="/agegate" />
      }
    />
  );
};

export default AgeGateRouteGuard;
