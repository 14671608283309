import React, { useEffect } from 'react';
import './new-memberships.scss';
import { fetchMemberAchievement, fetchNewMembershipCount } from '../../../actions/lionAdminAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';

export const NewMemberships: React.FC = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNewMembershipCount());

    const callApi = setInterval(() => {
      dispatch(fetchNewMembershipCount());
    }, 1800000);

    return () => clearInterval(callApi);
  }, []);

  const membershipCount = useSelector<
    AppState,
    AppState['lionAdminData']['membershipCount']
    >((state) => state.lionAdminData.membershipCount);

  return(
    <div className="new-memberships-container">
      <div className="new-memberships-container__text">NEW MEMBERSHIPS THIS MONTH</div>
      <div className="new-memberships-container__value">{membershipCount}</div>
    </div>
  )

}