import React from 'react';
import { IClubRewardsList } from './ViewClub.interface';
import guinnessGlass from '../../assets/Guinness_Pint.png';
import iconExpand from '../../assets/icons/icons-system-expand-black.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const ClubRewards: React.FC<IClubRewardsList> = (props) => {
  const responsive = {
    extraLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center section-xl-padding club-rewards-container">
      <div className="club-rewards-section-bg"></div>
      <div className="container-md text-center">
        <h1>Club Rewards</h1>
        <div className="divider"></div>
      </div>
      <div>
        <Carousel
          showDots={true}
          arrows={false}
          responsive={responsive}
          keyBoardControl={true}
          customTransition="transform 300ms ease-in-out"
          containerClass="carousel-container"
        >
          {props.clubRewardsList.map((value, index, arr) => {
            return (
              <div
                key={index}
                style={
                  index === arr.length - 1
                    ? {
                        backgroundColor: '#b9965b',
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px'
                      }
                    : { backgroundColor: '#fff', width: '100%', height: '100%', borderRadius: '10px' }
                }
                className="d-flex flex-column align-items-center reward-pints-container"
              >
                <div className="d-flex pt-2 pb-4">
                  <img
                    className="guinnes-glass"
                    src={guinnessGlass}
                    alt="Pint Glass"
                  />
                  <h3>{value.fields.pintValue}</h3>
                </div>
                <h5>{value.fields.mainPrize}</h5>
                {value.fields.mainPrizeDescription && (
                  <p className="mt-2 mb-0">
                    {value.fields.mainPrizeDescription}
                  </p>
                )}
                <div className="expand-icon">
                  <img src={iconExpand} alt="Expand Icon" />
                </div>
                {value.fields.secondaryPrizeTitle1 && (
                  <p className="m-0">{value.fields.secondaryPrizeTitle1}</p>
                )}
                {index === arr.length - 1 && (
                  <div className="legend-col pt-0">
                    {' '}
                    <h4 className="p-0 mb-1">You’re a legend!</h4>
                  </div>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
