import React, { useEffect, useState } from 'react';
import plusIcon from '../../../assets/icons/plus.svg';
import minusIcon from '../../../assets/icons/minus.svg';
import minusDisableIcon from '../../../assets/icons/minus-disable.svg';
import { AlertStatus } from '../../../shared/components/ui/alert-status/AlertStatus';
import './add-member-pints.scss';
import {
  MAX_PINTS,
  PINT_INCREMENTAL,
} from '../../../shared/configs/appConfigs';
import { addMemberPints, setMemberPintLevel } from '../../../actions/memberAction';
import { Button } from 'react-bootstrap';
import { IAddMemberPintsReqModal } from './AddMemberPintsReqModal.interface';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import loadIcon from '../../../assets/global-element-spinner.svg';

const AddMemberPints: React.FC<{
  isEditMemberForm: boolean;
  hideModalShow: () => void;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldValue?: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => void;
}> = (props) => {
  const memberData = useSelector<AppState, AppState['memberData']>(
    (state) => state.memberData
  );
  const dispatch = useDispatch();
  const [pints, setPints] = useState<number>(0);
  const [pintInput, setPintInput] = useState(0);

  useEffect(() => {
    if (
      memberData &&
      memberData.selectedMember &&
      memberData?.selectedMember?.pintLevel > 0
    ) {
      setPints(memberData.selectedMember.pintLevel);
    } else {
      setPints(0);
    }
  }, [memberData]);
  const updatePints = (isAddPints: boolean) => {
    if (isAddPints && pintInput < MAX_PINTS) {
      setPintInput((prevPints: number) => {
        return prevPints + PINT_INCREMENTAL;
      });
    }
    if (
      !isAddPints &&
      ((Math.abs(pintInput) <= MAX_PINTS && pints + pintInput > 0) ||
        (Math.abs(pintInput) === MAX_PINTS && pints === 0))
    ) {
      removePints();
    }
  };

  const removePints = () => {
    if (pintInput === 0 && pints > 0) {
      setPintInput(-PINT_INCREMENTAL);
    } else {
      setPintInput(pintInput - PINT_INCREMENTAL);
    }
  };
  const pintsDisplay = () => {
    return pintInput;
  };
  const onSubmitPints = async () => {
    let memberId = 0;
    const isAdd = pintInput >= 0 ? true : false;
    if (
      memberData &&
      memberData.selectedMember &&
      memberData.selectedMember.memberId
    ) {
      memberId = memberData.selectedMember.memberId;
    }
    const reqModalObj: IAddMemberPintsReqModal = {
      memberId: Number(memberId),
      pints: Math.abs(pintInput),
      isAdd,
    };
    if (props.isEditMemberForm) {
      const newPintLevel = pints + pintInput;
      if (props.setFieldValue)
        props.setFieldValue('pintLevel', newPintLevel.toString());
      if (props.setFieldTouched)
        props.setFieldTouched('pintLevel', true);
      dispatch(setMemberPintLevel(pintInput, isAdd));
      props.hideModalShow();
    } else {
      dispatch(addMemberPints(reqModalObj));
      pintsDisplay();
      setPintInput(0);
    }
  };
  return (
    <div className="d-flex flex-column add-member-pints-modal">
      <p className="text-1">Add pints to the {memberData?.selectedMember?.memberName}</p>
      <p className="text-2">Pints will be added in increments of 100</p>
      <label>Pint Level</label>
      <div className="d-flex justify-content-between align-items-center pint-update-section">
        <div className="hand-cursor p-1" onClick={() => updatePints(false)}>
          <img
            src={
              (pintInput === 0 && pints === 0) || pintInput > MAX_PINTS
                ? minusDisableIcon
                : minusIcon
            }
            alt="Minus Icon"
          />
        </div>
        <span>{pintsDisplay()}</span>
        <div className="hand-cursor p-1" onClick={() => updatePints(true)}>
          <img src={plusIcon} alt="Plus Icon" />
        </div>
      </div>
      {memberData.isMemberPintsLoading ? (
        <img className="spinner" src={loadIcon} alt="Loading..." />
      ) : null}
      <Button onClick={onSubmitPints} disabled={pintInput === 0}>
        Add
      </Button>
      {memberData?.memberPintsSuccessMessage && (
        <AlertStatus
          status={memberData?.memberPintsSuccessMessage}
          isApiError={false}
        ></AlertStatus>
      )}
      {memberData?.memberPintsErrorMessage && (
        <AlertStatus
          status={memberData?.memberPintsErrorMessage}
          isApiError={true}
        ></AlertStatus>
      )}
    </div>
  );
};

export default AddMemberPints;
