import React from "react";
import { TotalMembershipsByLevel } from "./TotalMembershipsByLevel";
import { MemberRegistrations } from "./MemberRegistrations";
import './lion-admin-membership-view.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';

export const LionAdminMembershipView: React.FC = () => {

    const totalMembersForBarGraph = useSelector<
      AppState,
      AppState['lionAdminData']['totalMembersForBarGraph']
      >((state) => state.lionAdminData.totalMembersForBarGraph);

    return(
        <div className="lion-admin-membership-view-container">
            <div className="lion-admin-membership-view-container__level-and-registrations">
                <div className="membership-level">
                    <div className="heading">TOTAL MEMBERSHIPS BY LEVEL</div>
                    <div className="sub-heading">{totalMembersForBarGraph} members</div>
                    <TotalMembershipsByLevel/>
                </div>
                <div className="member-registrations">
                    <div className="heading">MONTHLY REGISTRATIONS</div>
                    <div className="sub-heading">Over the last six months</div>
                    <MemberRegistrations/>
                </div>
            </div>
        </div>
    )

}