import Cookies from 'universal-cookie';

export function useAgeGateCookie(): {
  getAgeGateCookie: () => boolean;
  removeAgeGateCookie: () => void;
  setAgeGateCookie: () => void;
} {
  const ageGateCookie = new Cookies();

  const setAgeGateCookie = (): void => {
    const expiryTime = new Date(new Date().getTime() + 1440 * 60 * 1000);
    ageGateCookie.set('isAgeVerified', 'true', { expires: expiryTime });
  };

  const removeAgeGateCookie = (): void => {
    ageGateCookie.remove('isAgeVerified', { maxAge: 0 });
  };

  const getAgeGateCookie = (): boolean => {
    return ageGateCookie.get('isAgeVerified');
  };

  return {
    getAgeGateCookie,
    removeAgeGateCookie,
    setAgeGateCookie,
  };
}
