import * as Yup from 'yup';
import { numberRegex } from '../../shared/utils/input-regex';

const numOnlyMsg = 'must be a number';

export const AgegateValidationSchema = Yup.object().shape({
  date: Yup.string()
    .required('Date is required')
    .matches(numberRegex, numOnlyMsg)
    .min(2)
    .test('date-test', 'Invalid date', function (value) {
      const { path, createError } = this;
      const date = parseInt(value!);
      return (
        (date > 0 && date < 32) ||
        createError({ path, message: 'Invalid date' })
      );
    }),
  month: Yup.string()
    .required('Month is required')
    .matches(numberRegex, numOnlyMsg)
    .min(2)
    .test('month-test', 'Invalid month', function (value) {
      const { path, createError } = this;
      const month = parseInt(value!);
      return (
        (month > 0 && month < 13) ||
        createError({ path, message: 'Invalid month' })
      );
    }),
  year: Yup.string()
    .required('Year is required')
    .matches(numberRegex, numOnlyMsg)
    .min(4)
    .test('year-test', 'Invalid year', function (value) {
      const { path, createError } = this;
      const currentYear = new Date().getFullYear();
      return (
        currentYear > parseInt(value!) ||
        createError({ path, message: 'Invalid year' })
      );
    })
});
