import * as Yup from 'yup';
import { alphabetWithHypenRegex } from '../../../shared/utils/input-regex';

const LionAdminCreateValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email format is incorrect')
    .required('Email address is required')
    .max(100, 'Maximum allowed characters is 100'),
  firstName: Yup.string()
    .required('First Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
  lastName: Yup.string()
    .required('Last Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect')
    .max(50, 'Maximum allowed characters is 50'),
});

export default LionAdminCreateValidationSchema;