import React, { useState } from 'react';
import AddMemberPintsModal from './AddMemberPintsModal';
import { useField } from 'formik';
import { AppTextInput } from '../../../shared/components/ui/form-input/FormInput';
import './pint-level-counter.scss';
import expandIcon from '../../../assets/icons-system-expand.svg';

interface IPintLevelCounter {
  id: string;
  name: string;
  label: string;
  type?: string;
  error?: string;
  touched?: boolean;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldValue?: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => void;
}

const PintLevelCounter: React.FC<IPintLevelCounter> = (props) => {
  const [field] = useField(props);
  const [pintModalShow, setPintModalShow] = useState<boolean>(false);

  function onPintModalShow(e: any) {
    e.preventDefault();
    setPintModalShow(true);
  }

  function hidePintModalShow(): void {
    setPintModalShow(false);
  }

  return (
    <>
      <div className="pint-level-counter__field">
        <AppTextInput {...props} {...field} disabled />
        <button onClick={(e) => onPintModalShow(e)}>
          <img src={expandIcon} alt="Add Pint Icon" />
        </button>
      </div>
      {pintModalShow && (
        <AddMemberPintsModal
          hideModalShow={hidePintModalShow}
          isEditMemberForm={true}
          setFieldTouched={props.setFieldTouched}
          setFieldValue={props.setFieldValue}
        />
      )}
    </>
  );
};

export default PintLevelCounter;
