import React, { useState } from 'react';
import { BASE_API_URL, PASSWORD_MAX_LENGTH } from '../../shared/configs/appConfigs';
import ValidationErrorTemplate from 'src/shared/components/ui/validation-error/ValidationError';
import { Formik } from 'formik';
import { ForgotPswdConfirmValidationSchema } from './ForgotPswdConfirmValidationSchema';
import { IPasswordConfirmReqPayload } from './PasswordConfirmReqPayload.interface';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { IModalConfig } from 'src/shared/types/modalInterface';
import { Login } from '../login/Login';
import { CustomModal } from '../../shared/components/ui/modal/modal';
import { IPasswordConfirmInput } from './PasswordConfirmInput.interface';
import { AlertStatus } from '../../shared/components/ui/alert-status/AlertStatus';
import loadIcon from '../../assets/global-element-spinner.svg';

interface IConfirmPswdProps {
  email: string;
}

export const ForgotPasswordConfirm: React.FC<IConfirmPswdProps> = ({
  email,
}) => {
  const [isApiError, setIsApiError] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const loginModalconfig: IModalConfig = {
    title: 'Login',
    showModal: true,
    modalSize: 'smallModal',
    closeModal: hideModal,
    body: <Login oncancel={hideModal} />,
  };
  const initialFormValues: IPasswordConfirmInput = {
    otpCode: '',
    newPassword: '',
    confirmPassword: '',
  };

  function hideModal(): void {
    setShowLoginModal(false);
  }

  return (
    <div>
      {!showLoginModal && (
        <Formik
          initialValues={initialFormValues}
          validationSchema={ForgotPswdConfirmValidationSchema}
          validateOnMount
          onSubmit={async (values, { setStatus, resetForm }) => {
            try {
              const reqModalObj: IPasswordConfirmReqPayload = {
                ...values,
                userName: email,
              };
              delete reqModalObj.confirmPassword;
              setLoading(true);
              await axios({
                method: 'post',
                url: `${BASE_API_URL}user/forgotpassword/confirm`,
                data: reqModalObj,
              });
              resetForm();
              setLoading(false);
              setStatus('You have successfully updated your password.!');
              setIsApiError(false);
            } catch (err) {
              resetForm();
              setLoading(false);
              if (err && err.response && err.response.data) {
                setStatus([err.response.data.message]);
                setIsApiError(true);
              }
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            status,
            errors,
            touched,
            isValid,
            isSubmitting,
            values,
            handleSubmit,
          }) => (
            <Form className="d-flex flex-column" onSubmit={handleSubmit}>
              <div className="d-flex flex-column">
                <div className="d-flex flex-column date-container">
                  <div
                    className={`form-group ${
                      errors.otpCode && touched.otpCode ? 'input-error' : ''
                    }`}
                  >
                    <label htmlFor="otpCode">Code</label>
                    <input
                      type="text"
                      aria-label="otp-code"
                      data-testid="otp-code"
                      name="otpCode"
                      id="otpCode"
                      value={values.otpCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={320}
                      className={
                        errors.otpCode && touched.otpCode
                          ? 'input-error'
                          : undefined
                      }
                    />
                  </div>
                  <ValidationErrorTemplate
                    error={errors.otpCode}
                    touched={touched.otpCode}
                  />
                </div>
                <div className="d-flex flex-column date-container">
                  <div
                    className={`form-group ${
                      errors.newPassword && touched.newPassword
                        ? 'input-error'
                        : ''
                    }`}
                  >
                    <label htmlFor="userName">Password</label>
                    <input
                      type="password"
                      aria-label="user-name"
                      data-testid="email-login"
                      name="newPassword"
                      id="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={320}
                      className={
                        errors.newPassword && touched.newPassword
                          ? 'input-error'
                          : undefined
                      }
                    />
                  </div>
                  <ValidationErrorTemplate
                    error={errors.newPassword}
                    touched={touched.newPassword}
                  />
                </div>
                <div className="d-flex flex-column password-container">
                  <div
                    className={`form-group ${
                      errors.confirmPassword && touched.confirmPassword
                        ? 'input-error'
                        : ''
                    }`}
                  >
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      aria-label="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={PASSWORD_MAX_LENGTH}
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? 'input-error'
                          : undefined
                      }
                    />
                  </div>
                  <ValidationErrorTemplate
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                  />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center justify-content-md-between relative">
                {loading ? (
                  <img
                    className="spinner"
                    src={loadIcon}
                    alt="Loading..."
                  />
                ) : null}
                <Button
                  type="submit"
                  style={{ width: '100%'}}
                  className={`${isValid} section-margin-new forgot-password-dialog__submit`}
                  disabled={!isValid || isSubmitting}
                >
                  Reset
                </Button>
              </div>
              <AlertStatus
                status={status}
                isApiError={isApiError}
              ></AlertStatus>
            </Form>
          )}
        </Formik>
      )}
      <div>
        {showLoginModal && (
          <CustomModal config={loginModalconfig}></CustomModal>
        )}
      </div>
    </div>
  );
};
