import React from 'react';
import './validation-error.scss';

interface ValidationErrorProps {
  error: string | undefined;
  touched: boolean | undefined;
  isMapSelect?: boolean;
}
const ValidationErrorTemplate: React.FC<ValidationErrorProps> = (
  props: ValidationErrorProps
) => {
  return (
    <div className="validation-error">
      {props.error && props.touched ? (
        <div className="error-content" style={{marginTop: props.isMapSelect ? "1px" : ""}}>{props.error}</div>
      ) : null}
    </div>
  );
};

export default ValidationErrorTemplate;
