import React, { useEffect, useState } from 'react';
import { AboutGuinness } from "./AboutGuinness";
import { GuinnessGlass } from './GuinnessGlass';
import { GuinnessDraught } from './GuinnessDraught';
import { PerfectPint } from './PerfectPint';
import './about-guinness-page.scss';
import { MagicWidget } from './MagicWidget';
import { RegisterClub } from './RegisterClub';
import { IAboutGuinnessPage } from './AboutGuinnessPage.interface';
import { ContentfulClientConfigs } from '../../shared/configs/ContentfulClientConfigs';


export const AboutGuinnessPage: React.FC = () => {
  const [guinnessPage, setGuinnessPage] = useState<IAboutGuinnessPage>();

  useEffect(() => {
    ContentfulClientConfigs.getEntries<IAboutGuinnessPage>({
      content_type: 'aboutGuinnessPage',
    }).then(({ items }) => {
      setGuinnessPage(items[0].fields);
    });
  }, []);

    return (
        <div className="about-guinness-page">
            <AboutGuinness
              aboutGuinnessHeading={guinnessPage?.aboutGuinnessHeading}
              aboutGuinnessDescription={guinnessPage?.aboutGuinnessDescription}
              aboutGuinnessDetailedDescription1={guinnessPage?.aboutGuinnessDetailedDescription1}
              aboutGuinnessDetailedDescription2={guinnessPage?.aboutGuinnessDetailedDescription2}
            />
            <GuinnessGlass
              aboutGuinnessIconsList={guinnessPage?.aboutGuinnessIconsList}
            />
            <GuinnessDraught
              guinnessDraughtHeading={guinnessPage?.guinnessDraughtHeading}
              guinnessDraughtSubHeading={guinnessPage?.guinnessDraughtSubHeading}
              guinnessDraughtImage={guinnessPage?.guinnessDraughtImage?.fields.file.url}
              guinnessDraughtDescriptionList={guinnessPage?.guinnessDraughtDescriptionList}
            />
            <PerfectPint
              guinnessPerfectPintHeading={guinnessPage?.guinnessPerfectPintHeading}
              guinnessPerfectPintSubHeading={guinnessPage?.guinnessPerfectPintSubHeading}
              guinnessPerfectPintDescription1={guinnessPage?.guinnessPerfectPintDescription1}
              guinnessPerfectPintDescription2={guinnessPage?.guinnessPerfectPintDescription2}
              guinnessPerfectPintDescription3={guinnessPage?.guinnessPerfectPintDescription3}
              guinnessPerfectPintVideo={guinnessPage?.guinnessPerfectPintVideo.fields.file.url}
              guinnessPerfectPintVideoStill={guinnessPage?.guinnessPerfectPintVideoStill.fields.file.url}
            />
            <MagicWidget
              guinnessMagicWidgetHeading={guinnessPage?.guinnessMagicWidgetHeading}
              guinnessMagicWidgetSubHeading={guinnessPage?.guinnessMagicWidgetSubHeading}
              guinnessMagicWidgetDescription1={guinnessPage?.guinnessMagicWidgetDescription1}
              guinnessMagicWidgetDescription2={guinnessPage?.guinnessMagicWidgetDescription2}
              guinnessMagicWidgetDescription3={guinnessPage?.guinnessMagicWidgetDescription3}
              guinnessMagicWidgetIcon1={guinnessPage?.guinnessMagicWidgetIcon1.fields.file.url}
              guinnessMagicWidgetIcon2={guinnessPage?.guinnessMagicWidgetIcon2.fields.file.url}
              guinnessMagicWidgetVideo={guinnessPage?.guinnessMagicWidgetVideo.fields.file.url}
            />
            <RegisterClub/>
        </div>
    )
}