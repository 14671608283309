import { LionAdmin, LionAdminActionTypes } from '../actions/actions';
import {
  ADD_LION_ADMIN_MEMBER_PINTS_FAILED,
  ADD_LION_ADMIN_MEMBER_PINTS_STARTED,
  ADD_LION_ADMIN_MEMBER_PINTS_SUCCEEDED,
  CLEAR_BAR_CACHE,
  CLEAR_BAR_CREATE,
  CLEAR_BAR_EDIT,
  CLEAR_BAR_EMPLOYEE_CACHE,
  CLEAR_BAR_MEMBER_CACHE,
  CLEAR_EMPLOYEE_CREATE,
  CLEAR_EMPLOYEE_EDIT,
  CLEAR_LION_ADMIN_CREATE,
  CLEAR_MEMBER_EDIT,
  CREATE_BAR_DETAILS_FAILED,
  CREATE_BAR_DETAILS_STARTED,
  CREATE_BAR_DETAILS_SUCCEEDED,
  CREATE_EMPLOYEE_DETAILS_FAILED,
  CREATE_EMPLOYEE_DETAILS_STARTED,
  CREATE_EMPLOYEE_DETAILS_SUCCEEDED,
  CREATE_LION_ADMIN_DETAILS_FAILED,
  CREATE_LION_ADMIN_DETAILS_STARTED,
  CREATE_LION_ADMIN_DETAILS_SUCCEEDED,
  EDIT_BAR_DETAILS_FAILED,
  EDIT_BAR_DETAILS_STARTED,
  EDIT_BAR_DETAILS_SUCCEEDED,
  EDIT_EMPLOYEE_DETAILS_FAILED,
  EDIT_EMPLOYEE_DETAILS_STARTED,
  EDIT_EMPLOYEE_DETAILS_SUCCEEDED,
  EDIT_LION_ADMIN_DETAILS_FAILED,
  EDIT_LION_ADMIN_DETAILS_STARTED,
  EDIT_LION_ADMIN_DETAILS_SUCCEEDED,
  EDIT_MEMBER_DETAILS_FAILED,
  EDIT_MEMBER_DETAILS_STARTED,
  EDIT_MEMBER_DETAILS_SUCCEEDED,
  FETCH_BAR_DETAILS_FAILED,
  FETCH_BAR_DETAILS_STARTED,
  FETCH_BAR_DETAILS_SUCCEEDED,
  FETCH_EMPLOYEE_DETAILS_FAILED,
  FETCH_EMPLOYEE_DETAILS_STARTED,
  FETCH_EMPLOYEE_DETAILS_SUCCEEDED,
  FETCH_LION_ADMIN_MEMBER_LIST_FAILED,
  FETCH_LION_ADMIN_MEMBER_LIST_STARTED,
  FETCH_LION_ADMIN_MEMBER_LIST_SUCCEEDED,
  FETCH_MEMBER_ACHIEVEMENT_FAILED,
  FETCH_MEMBER_ACHIEVEMENT_STARTED,
  FETCH_MEMBER_ACHIEVEMENT_SUCCEEDED,
  FETCH_MEMBER_DETAILS_FAILED,
  FETCH_MEMBER_DETAILS_STARTED,
  FETCH_MEMBER_DETAILS_SUCCEEDED,
  FETCH_MEMBER_LIST_FOR_BAR_STARTED,
  FETCH_MEMBER_LIST_FOR_BAR_SUCCEEDED,
  FETCH_MEMBER_LIST_FOR_BAR_FAILED,
  FETCH_MEMBERSHIP_LEVEL_FAILED,
  FETCH_MEMBERSHIP_LEVEL_STARTED,
  FETCH_MEMBERSHIP_LEVEL_SUCCEEDED,
  FETCH_NEW_MEMBERSHIP_COUNT_FAILED,
  FETCH_NEW_MEMBERSHIP_COUNT_STARTED,
  FETCH_NEW_MEMBERSHIP_COUNT_SUCCEEDED,
  FETCH_TOTAL_BARS_SUCCEEDED,
  FETCH_TOTAL_MEMBER_REGISTRATION_FAILED,
  FETCH_TOTAL_MEMBER_REGISTRATION_STARTED,
  FETCH_TOTAL_MEMBER_REGISTRATION_SUCCEEDED,
  FETCH_TOTAL_MEMBERSHIP_LEVEL_FAILED,
  FETCH_TOTAL_MEMBERSHIP_LEVEL_STARTED,
  FETCH_TOTAL_MEMBERSHIP_LEVEL_SUCCEEDED,
  HIDE_BAR_CREATE,
  HIDE_BAR_DETAILS,
  HIDE_EMPLOYEE_CREATE,
  HIDE_EMPLOYEE_DETAILS,
  HIDE_LION_ADMIN_CREATE,
  HIDE_MEMBER_DETAILS,
  SAVE_LION_ADMIN_MEMBER_PAGINATION,
  SAVE_LION_ADMIN_SELECTED_MEMBER,
  SET_BAR_ADDRESS,
  SET_BAR_ADDRESS_POINTS,
  SET_DISPLAYING_MEMBER,
  SET_EDIT_MODE_MEMBER_DETAILS,
  SET_MEMBER_PINT_LEVEL,
  SET_SEARCH_BAR_EMPLOYEE_PANEL,
  SET_SEARCH_BAR_LION_ADMIN_PANEL,
  SET_SEARCH_BAR_MEMBER_PANEL,
  SET_SEARCH_BAR_PANEL,
  SET_SELECTED_EMPLOYEE_ID,
  SHOW_BAR_CREATE,
  SHOW_BAR_DETAILS,
  SHOW_EMPLOYEE_CREATE,
  SHOW_EMPLOYEE_DETAILS,
  SHOW_LION_ADMIN_CREATE,
  SHOW_MEMBER_DETAILS,
  CLEAR_MEMBER_LIST_FOR_BAR
} from '../actions/lionAdminAction';

import { LOGOUT_SUCCEEDED } from '../actions/authAction';
import { initialState } from '../store/configureStore';

export const lionAdminReducer = (
  state: LionAdmin = {},
  action: LionAdminActionTypes
): LionAdmin => {
  switch (action.type) {
    case SHOW_BAR_DETAILS:
      return {
        ...state,
        showBarDetails: true,
      };
    case HIDE_BAR_DETAILS:
      return {
        ...state,
        showBarDetails: false,
      };
    case FETCH_BAR_DETAILS_STARTED:
      return {
        ...state,
        isBarDetailsLoading: true,
        isBarDetailsError: false,
        barDetailsErrorMessage: '',
      };
    case FETCH_BAR_DETAILS_SUCCEEDED:
      return {
        ...state,
        barDetails: action.payload?.barDetails,
        isBarDetailsLoading: false,
        isBarDetailsError: false,
        barDetailsErrorMessage: '',
      };
    case FETCH_BAR_DETAILS_FAILED:
      return {
        ...state,
        isBarDetailsLoading: false,
        isBarDetailsError: true,
        barDetailsErrorMessage: 'Error retrieving bar details',
      };
    case CREATE_BAR_DETAILS_STARTED:
      return {
        ...state,
        isCreateBarsLoading: true,
        isCreateBarsSuccess: false,
        isCreateBarsError: false,
        createBarsMessage: '',
      };
    case CREATE_BAR_DETAILS_SUCCEEDED:
      return {
        ...state,
        isCreateBarsLoading: false,
        isCreateBarsSuccess: true,
        isCreateBarsError: false,
        createBarsMessage: 'Bar created successfully',
      };
    case CREATE_BAR_DETAILS_FAILED:
      return {
        ...state,
        isCreateBarsLoading: false,
        isCreateBarsSuccess: false,
        isCreateBarsError: true,
        createBarsMessage: 'Error creating bar',
      };
    case CLEAR_BAR_CREATE:
      return {
        ...state,
        isCreateBarsLoading: false,
        isCreateBarsSuccess: false,
        isCreateBarsError: false,
        createBarsMessage: '',
      };
    case EDIT_BAR_DETAILS_STARTED:
      return {
        ...state,
        isEditBarsLoading: true,
        isEditBarsSuccess: false,
        isEditBarsError: false,
        editBarsMessage: '',
      };
    case CLEAR_BAR_CACHE:
      return {
        ...state,
        barDetails: action.payload?.barDetails,
      }
    case EDIT_BAR_DETAILS_SUCCEEDED:
      return {
        ...state,
        isEditBarsLoading: false,
        isEditBarsSuccess: true,
        isEditBarsError: false,
        editBarsMessage: 'Bar updated successfully',
      };
    case EDIT_BAR_DETAILS_FAILED:
      return {
        ...state,
        isEditBarsLoading: false,
        isEditBarsSuccess: false,
        isEditBarsError: true,
        editBarsMessage: 'Error updating bar',
      };
    case CLEAR_BAR_EDIT:
      return {
        ...state,
        isEditBarsLoading: false,
        isEditBarsSuccess: false,
        isEditBarsError: false,
        editBarsMessage: '',
      };
    case SET_BAR_ADDRESS:
      return {
        ...state,
        barDetails: action.payload?.barDetails,
      };
    case SET_BAR_ADDRESS_POINTS:
      return {
        ...state,
        barDetails: action.payload?.barDetails,
      };
    case SHOW_BAR_CREATE:
      return {
        ...state,
        showBarCreate: true,
      };
    case HIDE_BAR_CREATE:
      return {
        ...state,
        showBarCreate: false,
      };
    case SHOW_EMPLOYEE_DETAILS:
      return {
        ...state,
        showEmployeeDetails: true,
      };
    case HIDE_EMPLOYEE_DETAILS:
      return {
        ...state,
        showEmployeeDetails: false,
      };
    case SHOW_EMPLOYEE_CREATE:
      return {
        ...state,
        showEmployeeCreate: true,
      };
    case HIDE_EMPLOYEE_CREATE:
      return {
        ...state,
        showEmployeeCreate: false,
      };
    case SET_SEARCH_BAR_PANEL:
      return {
        ...state,
        searchBarPanelVisible: true,
        searchBarEmployeePanelVisible: false,
        searchBarMemberPanelVisible: false,
        searchLionAdminPanelVisible: false,
      };
    case SET_SEARCH_BAR_EMPLOYEE_PANEL:
      return {
        ...state,
        searchBarEmployeePanelVisible: true,
        searchBarPanelVisible: false,
        searchBarMemberPanelVisible: false,
        searchLionAdminPanelVisible: false,
      };
    case SET_SEARCH_BAR_MEMBER_PANEL:
      return {
        ...state,
        searchBarMemberPanelVisible: true,
        searchBarPanelVisible: false,
        searchBarEmployeePanelVisible: false,
        searchLionAdminPanelVisible: false,
      };
    case SET_SEARCH_BAR_LION_ADMIN_PANEL:
      return {
        ...state,
        searchBarMemberPanelVisible: false,
        searchBarPanelVisible: false,
        searchBarEmployeePanelVisible: false,
        searchLionAdminPanelVisible: true,
      };
    case CREATE_EMPLOYEE_DETAILS_STARTED:
      return {
        ...state,
        isCreateEmployeeLoading: true,
        isCreateEmployeeSuccess: false,
        isCreateEmployeeError: false,
        createEmployeeMessage: '',
      };
    case CREATE_EMPLOYEE_DETAILS_SUCCEEDED:
      return {
        ...state,
        isCreateEmployeeLoading: false,
        isCreateEmployeeSuccess: true,
        isCreateEmployeeError: false,
        createEmployeeMessage: 'Employee created successfully',
      };
    case CREATE_EMPLOYEE_DETAILS_FAILED:
      return {
        ...state,
        isCreateEmployeeLoading: false,
        isCreateEmployeeSuccess: false,
        isCreateEmployeeError: true,
        createEmployeeMessage: 'Error creating employee',
      };
    case CLEAR_EMPLOYEE_CREATE:
      return {
        ...state,
        isCreateEmployeeLoading: false,
        isCreateEmployeeSuccess: false,
        isCreateEmployeeError: false,
        createEmployeeMessage: '',
      };
    case FETCH_EMPLOYEE_DETAILS_STARTED:
      return {
        ...state,
        isEmployeeDetailsLoading: true,
        isEmployeeDetailsError: false,
        employeeDetailsErrorMessage: '',
      };
    case FETCH_EMPLOYEE_DETAILS_SUCCEEDED:
      return {
        ...state,
        employeeDetails: action.payload?.employeeDetails,
        isEmployeeDetailsLoading: false,
        isEmployeeDetailsError: false,
        employeeDetailsErrorMessage: '',
      };
    case FETCH_EMPLOYEE_DETAILS_FAILED:
      return {
        ...state,
        isEmployeeDetailsLoading: false,
        isEmployeeDetailsError: true,
        employeeDetailsErrorMessage: 'Error retrieving employee details',
      };
    case EDIT_EMPLOYEE_DETAILS_STARTED:
      return {
        ...state,
        isEditEmployeeLoading: true,
        isEditEmployeeSuccess: false,
        isEditEmployeeError: false,
        editEmployeeMessage: '',
      };
    case EDIT_EMPLOYEE_DETAILS_SUCCEEDED:
      return {
        ...state,
        isEditEmployeeLoading: false,
        isEditEmployeeSuccess: true,
        isEditEmployeeError: false,
        editEmployeeMessage: 'Employee updated successfully',
      };
    case EDIT_EMPLOYEE_DETAILS_FAILED:
      return {
        ...state,
        isEditEmployeeLoading: false,
        isEditEmployeeSuccess: false,
        isEditEmployeeError: true,
        editEmployeeMessage: 'Error updating employee',
      };
    case CLEAR_EMPLOYEE_EDIT:
      return {
        ...state,
        isEditEmployeeLoading: false,
        isEditEmployeeSuccess: false,
        isEditEmployeeError: false,
        editEmployeeMessage: '',
      };
    case CLEAR_BAR_EMPLOYEE_CACHE:
      return {
        ...state,
        employeeDetails: action.payload?.employeeDetails,
      };
    case SET_SELECTED_EMPLOYEE_ID:
      return {
        ...state,
        selectedEmployeeId: action.payload?.selectedEmployeeId,
      };
    case FETCH_TOTAL_BARS_SUCCEEDED:
      return {
        ...state,
        totalBars: action.payload?.totalBars,
      };
    case SHOW_MEMBER_DETAILS:
      return {
        ...state,
        showMemberDetails: true,
      };
    case HIDE_MEMBER_DETAILS:
      return {
        ...state,
        showMemberDetails: false,
      };
    case FETCH_LION_ADMIN_MEMBER_LIST_STARTED:
      return {
        ...state,
        memberList: [],
        isMemberListLoading: true,
        isMemberListError: false,
        memberListErrorMessage: '',
      };
    case FETCH_LION_ADMIN_MEMBER_LIST_SUCCEEDED:
      return {
        ...state,
        memberList: action.payload?.memberList,
        totalMembers: action.payload?.totalMembers,
        overAllTotal: action.payload?.overAllTotal,
        isMemberListLoading: false,
        isMemberListError: false,
        memberListErrorMessage: '',
        selectedMember: action?.payload?.selectedMember,
      };
    case FETCH_LION_ADMIN_MEMBER_LIST_FAILED:
      return {
        ...state,
        memberList: [],
        totalMembers: 0,
        isMemberListLoading: false,
        isMemberListError: true,
        memberListErrorMessage: action.payload?.memberListErrorMessage,
      };
    case ADD_LION_ADMIN_MEMBER_PINTS_STARTED:
      return {
        ...state,
        isMemberPintsLoading: true,
        isMemberPintsError: false,
        memberPintsSuccessMessage: '',
        memberPintsErrorMessage: '',
      };
    case ADD_LION_ADMIN_MEMBER_PINTS_SUCCEEDED:
      return {
        ...state,
        isMemberPintsLoading: false,
        isMemberPintsError: false,
        memberPintsSuccessMessage: 'Pints updated successfully',
        memberPintsErrorMessage: '',
      };
    case ADD_LION_ADMIN_MEMBER_PINTS_FAILED:
      return {
        ...state,
        isMemberPintsLoading: false,
        isMemberPintsError: true,
        memberPintsSuccessMessage: '',
        memberPintsErrorMessage: 'Pint value incorrect',
      };
    case SAVE_LION_ADMIN_SELECTED_MEMBER:
      return {
        ...state,
        memberPintsSuccessMessage: '',
        memberPintsErrorMessage: '',
        selectedMember: action.payload?.selectedMember,
      };
    case SAVE_LION_ADMIN_MEMBER_PAGINATION:
      return {
        ...state,
        paginationHistory: action.payload?.paginationHistory,
      };
    case SET_MEMBER_PINT_LEVEL:
      return {
        ...state,
        selectedMember: action.payload?.selectedMember,
      };
    case EDIT_MEMBER_DETAILS_STARTED:
      return {
        ...state,
        isEditMemberLoading: true,
        isEditMemberSuccess: false,
        isEditMemberError: false,
        editMemberMessage: '',
      };
    case EDIT_MEMBER_DETAILS_SUCCEEDED:
      return {
        ...state,
        isEditMemberLoading: false,
        isEditMemberSuccess: true,
        isEditMemberError: false,
        editMemberMessage: 'Member updated successfully',
      };
    case EDIT_MEMBER_DETAILS_FAILED:
      return {
        ...state,
        isEditMemberLoading: false,
        isEditMemberSuccess: false,
        isEditMemberError: true,
        editMemberMessage: 'Error updating member',
      };
    case FETCH_MEMBER_DETAILS_STARTED:
      return {
        ...state,
        isMemberDetailsLoading: true,
        isMemberDetailsError: false,
        memberDetailsErrorMessage: '',
      };
    case FETCH_MEMBER_DETAILS_SUCCEEDED:
      return {
        ...state,
        memberDetails: action.payload?.memberDetails,
        isMemberDetailsLoading: false,
        isMemberDetailsError: false,
        memberDetailsErrorMessage: '',
      };
    case FETCH_MEMBER_DETAILS_FAILED:
      return {
        ...state,
        isMemberDetailsLoading: false,
        isMemberDetailsError: true,
        memberDetailsErrorMessage: 'Error retrieving member details',
      };
    case CLEAR_BAR_MEMBER_CACHE:
      return {
        ...state,
        memberDetails: action.payload?.memberDetails,
      }
    case SET_EDIT_MODE_MEMBER_DETAILS:
      return {
        ...state,
        isEditMode: action.payload?.isEditMode
      };
    case CLEAR_MEMBER_EDIT:
      return {
        ...state,
        isEditMemberLoading: false,
        isEditMemberSuccess: false,
        isEditMemberError: false,
        editMemberMessage: '',
      };
    case SHOW_LION_ADMIN_CREATE:
      return {
        ...state,
        showLionAdminCreate: true,
      };
    case HIDE_LION_ADMIN_CREATE:
      return {
        ...state,
        showLionAdminCreate: false,
      };
    case CREATE_LION_ADMIN_DETAILS_STARTED:
      return {
        ...state,
        isCreateLionAdminLoading: true,
        isCreateLionAdminSuccess: false,
        isCreateLionAdminError: false,
        createLionAdminMessage: '',
      };
    case CREATE_LION_ADMIN_DETAILS_SUCCEEDED:
      return {
        ...state,
        isCreateLionAdminLoading: false,
        isCreateLionAdminSuccess: true,
        isCreateLionAdminError: false,
        createLionAdminMessage: 'Lion admin created successfully',
      };
    case CREATE_LION_ADMIN_DETAILS_FAILED:
      return {
        ...state,
        isCreateLionAdminLoading: false,
        isCreateLionAdminSuccess: false,
        isCreateLionAdminError: true,
        createLionAdminMessage: 'Error creating lion admin',
      };
    case CLEAR_LION_ADMIN_CREATE:
      return {
        ...state,
        isCreateLionAdminLoading: false,
        isCreateLionAdminSuccess: false,
        isCreateLionAdminError: false,
        createLionAdminMessage: '',
      };
    case SET_DISPLAYING_MEMBER:
      return {
        ...state,
        displayingMember: action.payload?.displayingMember
      };
    case EDIT_LION_ADMIN_DETAILS_STARTED:
      return {
        ...state,
        isEditEmployeeLoading: true,
        isEditEmployeeSuccess: false,
        isEditEmployeeError: false,
        editEmployeeMessage: '',
      };
    case EDIT_LION_ADMIN_DETAILS_SUCCEEDED:
      return {
        ...state,
        isEditEmployeeLoading: false,
        isEditEmployeeSuccess: true,
        isEditEmployeeError: false,
        editEmployeeMessage: 'Employee updated successfully',
      };
    case EDIT_LION_ADMIN_DETAILS_FAILED:
      return {
        ...state,
        isEditEmployeeLoading: false,
        isEditEmployeeSuccess: false,
        isEditEmployeeError: true,
        editEmployeeMessage: action.payload?.editEmployeeMessage,
      };
    case FETCH_TOTAL_MEMBERSHIP_LEVEL_STARTED:
      return {
        ...state,
        isTotalMembershipLevelLoading: true,
        isTotalMembershipLevelError: false,
        totalMembershipLevelErrorMessage: '',
      };
    case FETCH_TOTAL_MEMBERSHIP_LEVEL_SUCCEEDED:
      return {
        ...state,
        totalMembershipLevelCount: action.payload?.totalMembershipLevelCount,
        totalMembersForBarGraph: action.payload?.totalMembersForBarGraph,
        isTotalMembershipLevelLoading: false,
        isTotalMembershipLevelError: false,
        totalMembershipLevelErrorMessage: '',
      };
    case FETCH_TOTAL_MEMBERSHIP_LEVEL_FAILED:
      return {
        ...state,
        isTotalMembershipLevelLoading: false,
        isTotalMembershipLevelError: true,
        totalMembershipLevelErrorMessage: 'Error retrieving total memberships by level',
      };
    case FETCH_TOTAL_MEMBER_REGISTRATION_STARTED:
      return {
        ...state,
        isTotalMemberRegistrationLoading: true,
        isTotalMemberRegistrationError: false,
        totalMemberRegistrationErrorMessage: '',
      };
    case FETCH_TOTAL_MEMBER_REGISTRATION_SUCCEEDED:
      return {
        ...state,
        totalMemberRegistration: action.payload?.totalMemberRegistration,
        isTotalMemberRegistrationLoading: false,
        isTotalMemberRegistrationError: false,
        totalMemberRegistrationErrorMessage: '',
      };
    case FETCH_TOTAL_MEMBER_REGISTRATION_FAILED:
      return {
        ...state,
        isTotalMemberRegistrationLoading: false,
        isTotalMemberRegistrationError: true,
        totalMemberRegistrationErrorMessage: 'Error retrieving total monthly member registration',
      };
    case FETCH_MEMBERSHIP_LEVEL_STARTED:
      return {
        ...state,
        isMembershipLevelLoading: true,
        isMembershipLevelError: false,
        membershipLevelErrorMessage: '',
      };
    case FETCH_MEMBERSHIP_LEVEL_SUCCEEDED:
      return {
        ...state,
        membershipLevelCount: action.payload?.membershipLevelCount,
        isMembershipLevelLoading: false,
        isMembershipLevelError: false,
        membershipLevelErrorMessage: '',
      };
    case FETCH_MEMBERSHIP_LEVEL_FAILED:
      return {
        ...state,
        isMembershipLevelLoading: false,
        isMembershipLevelError: true,
        membershipLevelErrorMessage: 'Error retrieving memberships by level',
      };
    case FETCH_MEMBER_ACHIEVEMENT_STARTED:
      return {
        ...state,
        isMemberAchievementLoading: true,
        isMemberAchievementError: false,
        memberAchievementErrorMessage: '',
      };
    case FETCH_MEMBER_ACHIEVEMENT_SUCCEEDED:
      return {
        ...state,
        memberAchievementDataLeft: action.payload?.memberAchievementDataLeft,
        memberAchievementDataRight: action.payload?.memberAchievementDataRight,
        memberAchievementTotalItems: action.payload?.memberAchievementTotalItems,
        isMemberAchievementLoading: false,
        isMemberAchievementError: false,
        memberAchievementErrorMessage: '',
      };
    case FETCH_MEMBER_ACHIEVEMENT_FAILED:
      return {
        ...state,
        isMemberAchievementLoading: false,
        isMemberAchievementError: true,
        memberAchievementErrorMessage: 'Error retrieving member achievements',
      };
    case FETCH_NEW_MEMBERSHIP_COUNT_STARTED:
      return {
        ...state,
        isMembershipCountLoading: true,
        isMembershipCountError: false,
        membershipCountErrorMessage: '',
      };
    case FETCH_NEW_MEMBERSHIP_COUNT_SUCCEEDED:
      return {
        ...state,
        membershipCount: action.payload?.membershipCount,
        monthYear: action.payload?.monthYear,
        isMembershipCountLoading: false,
        isMembershipCountError: false,
        membershipCountErrorMessage: '',
      };
    case FETCH_NEW_MEMBERSHIP_COUNT_FAILED:
      return {
        ...state,
        isMembershipCountLoading: false,
        isMembershipCountError: true,
        membershipCountErrorMessage: 'Error retrieving new membership',
      };
    case FETCH_MEMBER_LIST_FOR_BAR_STARTED:
      return {
        ...state,
        isMemberListForBarLoading: true,
        isMemberListForBarError: false,
        isMemberListForBarSuccess: false,
        memberListForBarErrorMessage: '',
      };
    case FETCH_MEMBER_LIST_FOR_BAR_SUCCEEDED:
      return {
        ...state,
        memberListForBar: action.payload?.memberListForBar,
        isMemberListForBarLoading: false,
        isMemberListForBarError: false,
        isMemberListForBarSuccess: true,
        memberListForBarErrorMessage: '',
      };
    case FETCH_MEMBER_LIST_FOR_BAR_FAILED:
      return {
        ...state,
        isMemberListForBarLoading: false,
        isMemberListForBarError: true,
        isMemberListForBarSuccess: false,
        memberListForBarErrorMessage: 'Error retrieving member list for bar',
      };
    case CLEAR_MEMBER_LIST_FOR_BAR:
      return {
        ...state,
        isMemberListForBarLoading: false,
        isMemberListForBarError: false,
        isMemberListForBarSuccess: false,
        memberListForBar: action.payload?.memberListForBar
      }
    case LOGOUT_SUCCEEDED:
      return initialState.lionAdminData
    default:
      return state;
  }
};
