import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { AgeGate } from './features/agegate/AgeGate';
import { Home } from './features/home/home';
import { RegistrationConfirm } from './features/registration-confirm/RegistrationConfirm';
import AgeGateRouteGuard from './shared/services/AgeGateRouteGuard';
import AuthRouteGuard from './shared/services/AuthRouteGuard';
import { Dashboard } from './features/dashboard/Dashboard';
import LoginModal from 'src/features/login/LoginModal';
import CustomNavBar from './shared/components/ui/navbar/CustomNavBar';
import RegistrationModal from './features/registration/RegistrationModal';
import ContactUsModal from './features/contact-us/ContactUsModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './reducers/rootReducer';
import { useAgeGateCookie } from './shared/hooks/useAgeGateCookie';
import { ViewClub } from './features/1759 club/ViewClub';
import { AboutGuinnessPage} from "./features/about-guinness/AboutGuinnessPage";

import {
  setAboveEighteen,
  setNotAboveEighteen,
  checkIfAlreadyLoggedIn,
} from './actions/authAction';
import {
  getDarkLogoFromContentful
} from './actions/commonAction';
import Footer from './shared/components/ui/footer/Footer';
import { TermsAndCondition } from './features/termsandconditions/TermsAndCondition';
import { ScrollToTop } from './shared/components/scroll-to-top/ScrollToTop';

export const App: React.FC = () => {
  const isAgeVerified = useSelector<
    AppState,
    AppState['authData']['isAgeVerified']
  >((state) => state.authData.isAgeVerified);
  const dispatch = useDispatch();
  const { getAgeGateCookie } = useAgeGateCookie();

  useEffect(() => {
    if (getAgeGateCookie()) {
      dispatch(setAboveEighteen());
    } else {
      dispatch(setNotAboveEighteen(false, true));
    }
    dispatch(checkIfAlreadyLoggedIn());
    dispatch(getDarkLogoFromContentful());
  });

  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        {isAgeVerified &&<CustomNavBar />}
        {isAgeVerified && <LoginModal/>}
        {isAgeVerified && <RegistrationModal/>}
        {isAgeVerified && <ContactUsModal/>}
        <Switch>
          <AgeGateRouteGuard path="/home" component={Home} />
          <AgeGateRouteGuard path="/about" component={AboutGuinnessPage} />
          <AuthRouteGuard path="/club" component={ViewClub} />       
          <AuthRouteGuard
            path="/confirmation/:userName"
            component={RegistrationConfirm}
          />
          <AuthRouteGuard path="/dashboard" component={Dashboard} />
          <AgeGateRouteGuard
            path="/termsandcondition"
            component={TermsAndCondition}
          />
          <Route path="/agegate" component={AgeGate} />
          <AgeGateRouteGuard path="*" component={Home} />
        </Switch>
        {isAgeVerified ? <Footer /> : null}
      </Router>
    </div>
  );
};
