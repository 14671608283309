import { AppActions, MemberResponseDTO } from './actions';
import {
  IBarDetailsPayload,
  IBarAddressPointsPayload,
} from '../features/dashboard/bar-details/BarDetailsPayload';
import { IEmployeeDetailsPayload } from '../features/dashboard/employee-details/EmployeeDetailsPayload';
import {
  IMemberPayload,
  IMemberDetailsPayload,
} from '../features/dashboard/member-details/MemberDetailsPayload';
import { Dispatch } from 'redux';
import { store } from '../store/configureStore';
import axios from 'axios';
import { BASE_API_URL } from '../shared/configs/appConfigs';
import moment from 'moment';
import { IAddMemberPintsReqModal } from '../features/dashboard/member-list/AddMemberPintsReqModal.interface';
import { PaginationData } from '../shared/types/paginationData.interface';
import { DefaultPagination } from '../shared/components/ui/data-table/PaginationDefaultConfig';
import { ILionAdminCreatePayload } from '../features/dashboard/lion-admin-details/LionAdminCreatePayload';
import { OptionsType, ValueType } from 'react-select';
import { fetchAsyncEmployeeList } from './commonAction';
import { performAsyncLogout } from './authAction';
import {getUserRole} from "../shared/utils/global-functions";
import { ITotalMemberRegistration } from '../features/dashboard/lion-admin-details/LionAdminDashboardPayload';
import {IMembershipByLevel} from "../features/dashboard/bar-admin-details/BarAdminDashboardPayload";
import { SAVE_SELECTED_MEMBER } from './memberAction';

export const SHOW_BAR_DETAILS = 'SHOW_BAR_DETAILS';
export const showBarDetails = (): AppActions => {
  return {
    type: SHOW_BAR_DETAILS,
  };
};

export const HIDE_BAR_DETAILS = 'HIDE_BAR_DETAILS';
export const hideBarDetails = (): AppActions => {
  return {
    type: HIDE_BAR_DETAILS,
  };
};

export const SHOW_BAR_CREATE = 'SHOW_BAR_CREATE';
export const showBarCreate = (): AppActions => {
  return {
    type: SHOW_BAR_CREATE,
  };
};

export const HIDE_BAR_CREATE = 'HIDE_BAR_CREATE';
export const hideBarCreate = (): AppActions => {
  return {
    type: HIDE_BAR_CREATE,
  };
};

async function fetchAsyncBarDetails(dispatch: Dispatch<AppActions>) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const res = await axios.get(`${BASE_API_URL}bar/${selectedBarId}`);
    dispatch({
      type: FETCH_BAR_DETAILS_SUCCEEDED,
      payload: {
        barDetails: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_BAR_DETAILS_FAILED,
    });
  }
}

export const FETCH_BAR_DETAILS_STARTED = 'FETCH_BAR_DETAILS_STARTED';
export const FETCH_BAR_DETAILS_SUCCEEDED = 'FETCH_BAR_DETAILS_SUCCEEDED';
export const FETCH_BAR_DETAILS_FAILED = 'FETCH_BAR_DETAILS_FAILED';
export const fetchBarDetails = (): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_BAR_DETAILS_STARTED,
    });
    return fetchAsyncBarDetails(dispatch);
  };
};

async function createAsyncBarDetails(
  requestPayload: any,
  dispatch: Dispatch<AppActions>
) {
  try {
    const res = await axios.post(`${BASE_API_URL}bar/bar`, requestPayload);
    dispatch({
      type: CREATE_BAR_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: CREATE_BAR_DETAILS_FAILED,
    });
  }
}

async function createAsyncBarDetailsAndFetchTotalBars(
  requestPayload: any,
  dispatch: Dispatch<AppActions>
) {
  await createAsyncBarDetails(requestPayload, dispatch);
  await fetchAsyncTotalBars(dispatch);
}

export const CREATE_BAR_DETAILS_STARTED = 'CREATE_BAR_DETAILS_STARTED';
export const CREATE_BAR_DETAILS_SUCCEEDED = 'CREATE_BAR_DETAILS_SUCCEEDED';
export const CREATE_BAR_DETAILS_FAILED = 'CREATE_BAR_DETAILS_FAILED';
export const createBarDetails = (
  values: any,
  addressPoints: any
): ((dispatch: Dispatch) => Promise<void>) => {
  const requestPayload = {
    sapCustomerId: values.sapCustomerId,
    name: values.name,
    address: values.address,
    address2: values.address2,
    suburb: values.suburb,
    city: values.city,
    postCode: values.postCode,
    phone: values.phone,
    email: values.email,
    contactFirstName: values.contactFirstName,
    contactLastName: values.contactLastName,
    tier: values.tier,
    longitude: parseFloat(addressPoints.lat),
    latitude: parseFloat(addressPoints.lng),
    barEmployeeData: {
      firstName: values.barAdminFirstName,
      lastName: values.barAdminLastName,
      email: values.barAdminEmail,
      isAdmin: true,
    },
  };

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CREATE_BAR_DETAILS_STARTED,
    });
    return createAsyncBarDetailsAndFetchTotalBars(requestPayload, dispatch);
  };
};

async function editAsyncBarDetails(
  formValues: IBarDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const requestPayload = {
      ...formValues,
      id: selectedBarId,
    };
    const res = await axios.put(`${BASE_API_URL}bar/bar`, requestPayload);
    dispatch({
      type: EDIT_BAR_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: EDIT_BAR_DETAILS_FAILED,
    });
  }
}

async function editAsyncBarDetailsAndFetch(
  formValues: IBarDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  await editAsyncBarDetails(formValues, dispatch);
  await fetchAsyncBarDetails(dispatch);
}

export const EDIT_BAR_DETAILS_STARTED = 'EDIT_BAR_DETAILS_STARTED';
export const EDIT_BAR_DETAILS_SUCCEEDED = 'EDIT_BAR_DETAILS_SUCCEEDED';
export const EDIT_BAR_DETAILS_FAILED = 'EDIT_BAR_DETAILS_FAILED';
export const editBarDetails = (
  formValues: IBarDetailsPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_BAR_DETAILS_STARTED,
    });
    return editAsyncBarDetailsAndFetch(formValues, dispatch);
  };
};

export const SET_BAR_ADDRESS = 'SET_BAR_ADDRESS';
export const setBarAddress = (
  barDetailsWithNewAddress: IBarDetailsPayload
): AppActions => {
  return {
    type: SET_BAR_ADDRESS,
    payload: {
      barDetails: barDetailsWithNewAddress,
    },
  };
};

export const CLEAR_BAR_MEMBER_CACHE = 'CLEAR_BAR_MEMBER_CACHE';
export const clearMemberCache = (): AppActions => {
  const dateOfBirth = store.getState().lionAdminData.memberDetails!.dob;
  const dateRegistered = store.getState().lionAdminData.memberDetails!.dateRegistered;
  return {
    type: CLEAR_BAR_MEMBER_CACHE,
    payload: {
      memberDetails: {
        memberId: 0,
        firstName: '',
        lastName: '',
        address2: '',
        phone: '',
        email: '',
        boardName: '',
        pintLevel: 0,
        shirtSize: '',
        dob: dateOfBirth || '1970-01-01T00:00:00',
        address: '',
        suburb: '',
        city: '',
        postCode: '',
        dateRegistered: dateRegistered || '1970-01-01T00:00:00',
        barId: 0,
        barName: '',
        isActive: false
      },
    },
  };
};

export const SET_MEMBER_PINT_LEVEL = 'SET_MEMBER_PINT_LEVEL';
export const setMemberPintLevel = (
  newPintPoints: number,
  isAdd: boolean
): AppActions => {
  const selectedMember = store.getState().lionAdminData.selectedMember!;
  const existingPintLevel = selectedMember ? selectedMember.pintLevel : 0;
  const updatedPintLevel = existingPintLevel + newPintPoints;
  return {
    type: SET_MEMBER_PINT_LEVEL,
    payload: {
      selectedMember: {
        ...selectedMember,
        pintLevel: updatedPintLevel,
      },
    },
  };
};

export const SET_BAR_ADDRESS_POINTS = 'SET_BAR_ADDRESS_POINTS';
export const setBarAddressPoints = (
  barAddressPoints: IBarAddressPointsPayload
): AppActions => {
  const barDetails = store.getState().lionAdminData.barDetails;
  const barDetailsWithNewAddressPoints = {
    ...barDetails,
    lat: barAddressPoints.lat,
    lng: barAddressPoints.lng,
  };
  return {
    type: SET_BAR_ADDRESS_POINTS,
    payload: {
      barDetails: barDetailsWithNewAddressPoints as IBarDetailsPayload,
    },
  };
};

export const CLEAR_BAR_CREATE = 'CLEAR_BAR_CREATE';
export const clearBarCreate = (): AppActions => {
  return {
    type: CLEAR_BAR_CREATE,
  };
};

export const CLEAR_BAR_EDIT = 'CLEAR_BAR_EDIT';
export const clearBarEdit = (): AppActions => {
  return {
    type: CLEAR_BAR_EDIT,
  };
};

export const CLEAR_BAR_CACHE = 'CLEAR_BAR_CACHE';
export const clearBarCache = (): AppActions =>{
  return {
    type: CLEAR_BAR_CACHE,
    payload: {
      barDetails:{
        sapCustomerId: '',
        name: '',
        address2: '',
        phone: '',
        email: '',
        contactFirstName: '',
        contactLastName: '',
        tier: '',
        address: '',
        suburb: '',
        city: '',
        postCode: '',
      }
    }
  }
}

export const SHOW_EMPLOYEE_DETAILS = 'SHOW_EMPLOYEE_DETAILS';
export const showEmployeeDetails = (): AppActions => {
  return {
    type: SHOW_EMPLOYEE_DETAILS,
  };
};

export const HIDE_EMPLOYEE_DETAILS = 'HIDE_EMPLOYEE_DETAILS';
export const hideEmployeeDetails = (): AppActions => {
  return {
    type: HIDE_EMPLOYEE_DETAILS,
  };
};

export const SHOW_EMPLOYEE_CREATE = 'SHOW_EMPLOYEE_CREATE';
export const showEmployeeCreate = (): AppActions => {
  return {
    type: SHOW_EMPLOYEE_CREATE,
  };
};

export const HIDE_EMPLOYEE_CREATE = 'HIDE_EMPLOYEE_CREATE';
export const hideEmployeeCreate = (): AppActions => {
  return {
    type: HIDE_EMPLOYEE_CREATE,
  };
};

export const SET_SELECTED_EMPLOYEE_ID = 'SET_SELECTED_EMPLOYEE_ID';
export const setSelectedEmployeeId = (
  selectedEmployeeId: number
): AppActions => {
  return {
    type: SET_SELECTED_EMPLOYEE_ID,
    payload: {
      selectedEmployeeId: selectedEmployeeId,
    },
  };
};

export const SET_SEARCH_BAR_PANEL = 'SET_SEARCH_BAR_PANEL';
export const setSearchBarPanel = (): AppActions => {
  return {
    type: SET_SEARCH_BAR_PANEL,
  };
};

export const SET_SEARCH_BAR_EMPLOYEE_PANEL = 'SET_SEARCH_BAR_EMPLOYEE_PANEL';
export const setSearchBarEmployeePanel = (): AppActions => {
  return {
    type: SET_SEARCH_BAR_EMPLOYEE_PANEL,
  };
};

export const SET_SEARCH_BAR_MEMBER_PANEL = 'SET_SEARCH_BAR_MEMBER_PANEL';
export const setSearchBarMemberPanel = (): AppActions => {
  return {
    type: SET_SEARCH_BAR_MEMBER_PANEL,
  };
};

export const SET_SEARCH_BAR_LION_ADMIN_PANEL = 'SET_SEARCH_BAR_LION_ADMIN_PANEL';
export const setSearchLionAdminPanel = (): AppActions => {
  return {
    type: SET_SEARCH_BAR_LION_ADMIN_PANEL,
  };
};

async function createAsyncEmployeeDetails(
  requestPayload: any,
  dispatch: Dispatch<AppActions>
) {
  try {
    const res = await axios.post(`${BASE_API_URL}bar/employee`, requestPayload);
    dispatch({
      type: CREATE_EMPLOYEE_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: CREATE_EMPLOYEE_DETAILS_FAILED,
    });
  }
}

export const CREATE_EMPLOYEE_DETAILS_STARTED =
  'CREATE_EMPLOYEE_DETAILS_STARTED';
export const CREATE_EMPLOYEE_DETAILS_SUCCEEDED =
  'CREATE_EMPLOYEE_DETAILS_SUCCEEDED';
export const CREATE_EMPLOYEE_DETAILS_FAILED = 'CREATE_EMPLOYEE_DETAILS_FAILED';
export const createEmployeeDetails = (
  values: any
): ((dispatch: Dispatch) => Promise<void>) => {
  const requestPayload = {
    barId: values.barId,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    isAdmin: false,
  };

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CREATE_EMPLOYEE_DETAILS_STARTED,
    });
    return createAsyncEmployeeDetails(requestPayload, dispatch);
  };
};

export const CLEAR_EMPLOYEE_CREATE = 'CLEAR_EMPLOYEE_CREATE';
export const clearEmployeeCreate = (): AppActions => {
  return {
    type: CLEAR_EMPLOYEE_CREATE,
  };
};

async function fetchAsyncEmployeeDetails(dispatch: Dispatch<AppActions>) {
  try {
    const selectedEmployeeId = store.getState().lionAdminData
      .selectedEmployeeId;
    const res = await axios.get(
      `${BASE_API_URL}bar/employee/${selectedEmployeeId}`
    );
    dispatch({
      type: FETCH_EMPLOYEE_DETAILS_SUCCEEDED,
      payload: {
        employeeDetails: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_EMPLOYEE_DETAILS_FAILED,
    });
  }
}

export const FETCH_EMPLOYEE_DETAILS_STARTED = 'FETCH_EMPLOYEE_DETAILS_STARTED';
export const FETCH_EMPLOYEE_DETAILS_SUCCEEDED =
  'FETCH_EMPLOYEE_DETAILS_SUCCEEDED';
export const FETCH_EMPLOYEE_DETAILS_FAILED = 'FETCH_EMPLOYEE_DETAILS_FAILED';
export const fetchEmployeeDetails = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_EMPLOYEE_DETAILS_STARTED,
    });
    return fetchAsyncEmployeeDetails(dispatch);
  };
};

export const CLEAR_EMPLOYEE_EDIT = 'CLEAR_EMPLOYEE_EDIT';
export const clearEmployeeEdit = (): AppActions => {
  return {
    type: CLEAR_EMPLOYEE_EDIT,
  };
};

export const CLEAR_BAR_EMPLOYEE_CACHE = 'CLEAR_BAR_EMPLOYEE_CACHE';
export const clearBarEmployeeCache = (): AppActions => {
  return {
    type: CLEAR_BAR_EMPLOYEE_CACHE,
    payload: {
      employeeDetails: {
        barName: '',
        email: '',
        firstName: '',
        lastName: '',
        isActive: false,
        isAdmin: false
      },
    },
  };
};

async function editAsyncEmployeeDetails(
  formValues: IEmployeeDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  try {
    const selectedEmployeeId = store.getState().lionAdminData
      .selectedEmployeeId;
    const requestPayload = {
      ...formValues,
      id: selectedEmployeeId,
    };
    delete requestPayload.barName;
    const res = await axios.put(`${BASE_API_URL}bar/employee`, requestPayload);
    dispatch({
      type: EDIT_EMPLOYEE_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: EDIT_EMPLOYEE_DETAILS_FAILED,
    });
  }
}

async function editAsyncEmployeeDetailsAndFetch(
  formValues: IEmployeeDetailsPayload,
  employeeDetails: IEmployeeDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  await editAsyncEmployeeDetails(formValues, dispatch);
  await fetchAsyncEmployeeDetails(dispatch);
  const selectedBarId = store.getState().authData.selectedBarId;
  if (selectedBarId !== -1) {
    await fetchAsyncEmployeeList(dispatch)
  }
  if (getUserRole() && getUserRole().toLowerCase() === 'BarAdmin'.toLowerCase()) {
    if (formValues.isAdmin && employeeDetails.isAdmin !== formValues.isAdmin) {
      await performAsyncLogout(dispatch);
    }
  }
}

export const EDIT_EMPLOYEE_DETAILS_STARTED = 'EDIT_EMPLOYEE_DETAILS_STARTED';
export const EDIT_EMPLOYEE_DETAILS_SUCCEEDED =
  'EDIT_EMPLOYEE_DETAILS_SUCCEEDED';
export const EDIT_EMPLOYEE_DETAILS_FAILED = 'EDIT_EMPLOYEE_DETAILS_FAILED';
export const editEmployeeDetails = (
  formValues: IEmployeeDetailsPayload,
  employeeDetails: IEmployeeDetailsPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_EMPLOYEE_DETAILS_STARTED,
    });
    return editAsyncEmployeeDetailsAndFetch(formValues, employeeDetails, dispatch);
  };
};

async function editAsyncLionAdminDetails(
    formValues: IEmployeeDetailsPayload,
    dispatch: Dispatch<AppActions>
) {
  try {
    const selectedEmployeeId = store.getState().lionAdminData
        .selectedEmployeeId;
    const requestPayload = {
      ...formValues,
      id: selectedEmployeeId,
    };
    delete requestPayload.barName;
    delete requestPayload.email;
    const res = await axios.put(`${BASE_API_URL}bar/employee/lionadmin`, requestPayload);
    dispatch({
      type: EDIT_LION_ADMIN_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: EDIT_LION_ADMIN_DETAILS_FAILED,
      payload: {
        editEmployeeMessage: err.response.data.message
      }
    });
  }
}

async function editAsyncLionAdminDetailsAndFetch(
    formValues: IEmployeeDetailsPayload,
    dispatch: Dispatch<AppActions>
) {
  await editAsyncLionAdminDetails(formValues, dispatch);
  await fetchAsyncEmployeeDetails(dispatch);
  const selectedBarId = store.getState().authData.selectedBarId;
  if (selectedBarId !== -1) {
    await fetchAsyncEmployeeList(dispatch)
  }
}

export const EDIT_LION_ADMIN_DETAILS_STARTED = 'EDIT_LION_ADMIN_DETAILS_STARTED';
export const EDIT_LION_ADMIN_DETAILS_SUCCEEDED = 'EDIT_LION_ADMIN_DETAILS_SUCCEEDED';
export const EDIT_LION_ADMIN_DETAILS_FAILED = 'EDIT_LION_ADMIN_DETAILS_FAILED';
export const editLionAdminDetails = (
    formValues: IEmployeeDetailsPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_LION_ADMIN_DETAILS_STARTED,
    });
    return editAsyncLionAdminDetailsAndFetch(formValues, dispatch);
  };
};

async function fetchAsyncTotalBars(dispatch: Dispatch<AppActions>) {
  try {
    const res = await axios.get(`${BASE_API_URL}bar/count`);
    dispatch({
      type: FETCH_TOTAL_BARS_SUCCEEDED,
      payload: {
        totalBars: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_TOTAL_BARS_FAILED,
    });
  }
}

export const FETCH_TOTAL_BARS_STARTED = 'FETCH_TOTAL_BARS_STARTED';
export const FETCH_TOTAL_BARS_SUCCEEDED = 'FETCH_TOTAL_BARS_SUCCEEDED';
export const FETCH_TOTAL_BARS_FAILED = 'FETCH_TOTAL_BARS_FAILED';
export const fetchTotalBars = (): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_TOTAL_BARS_STARTED,
    });
    return fetchAsyncTotalBars(dispatch);
  };
};

export const SHOW_MEMBER_DETAILS = 'SHOW_MEMBER_DETAILS';
export const showMemberDetails = (): AppActions => {
  return {
    type: SHOW_MEMBER_DETAILS,
  };
};

export const HIDE_MEMBER_DETAILS = 'HIDE_MEMBER_DETAILS';
export const hideMemberDetails = (): AppActions => {
  return {
    type: HIDE_MEMBER_DETAILS,
  };
};

export const FETCH_LION_ADMIN_MEMBER_LIST_STARTED =
  'FETCH_LION_ADMIN_MEMBER_LIST_STARTED';
export const FETCH_LION_ADMIN_MEMBER_LIST_SUCCEEDED =
  'FETCH_LION_ADMIN_MEMBER_LIST_SUCCEEDED';
export const FETCH_LION_ADMIN_MEMBER_LIST_FAILED =
  'FETCH_LION_ADMIN_MEMBER_LIST_FAILED';
export const SAVE_LION_ADMIN_SELECTED_MEMBER =
  'SAVE_LION_ADMIN_SELECTED_MEMBER';
export const SAVE_LION_ADMIN_MEMBER_PAGINATION =
  'SAVE_LION_ADMIN_MEMBER_PAGINATION';
export const ADD_LION_ADMIN_MEMBER_PINTS_SUCCEEDED =
  'ADD_LION_ADMIN_MEMBER_PINTS_SUCCEEDED';
export const ADD_LION_ADMIN_MEMBER_PINTS_FAILED =
  'ADD_LION_ADMIN_MEMBER_PINTS_FAILED';
export const ADD_LION_ADMIN_MEMBER_PINTS_STARTED =
  'ADD_LION_ADMIN_MEMBER_PINTS_STARTED';

async function fetchAsyncMemberList(
  dispatch: Dispatch<AppActions>,
  currentIndex: number,
  sizePerPage: number,
  searchValue: string,
  sortOrder: string,
  columnName: string
) {
  try {
    const status = store.getState().lionAdminData.displayingMember;
    let displayStatus = 'Active';
    if (status) {
      displayStatus = (status as {label: string, value: string}).value
    }
    const res = await axios.get(
      `${BASE_API_URL}member/list?start=${currentIndex}&limit=${sizePerPage}&searchValue=${searchValue}&sortOrder=${sortOrder}&columnName=${columnName}&status=${displayStatus}`
    );
    const data = res.data?.data?.pagedItems.map((obj: any) => {
      if (obj.dateRegistered) {
        obj.dateRegistered = moment(new Date(obj.dateRegistered)).format(
          'DD/MM/YYYY'
        );
        return obj;
      }
      return obj;
    });
    const arr: IMemberPayload[] = [...data];
    const selectedMember = arr.find(
      (obj: IMemberPayload) => {
        return obj.memberId === store.getState().lionAdminData.selectedMember?.memberId
      }
    );
    dispatch({
      type: FETCH_LION_ADMIN_MEMBER_LIST_SUCCEEDED,
      payload: {
        memberList: data,
        totalMembers: res.data.data.totalItems,
        overAllTotal: res.data.overAllTotalItems,
        selectedMember: selectedMember ? selectedMember : store.getState().lionAdminData.selectedMember,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_LION_ADMIN_MEMBER_LIST_FAILED,
    });
  }
}

async function addAsyncMemberPints(
  dispatch: Dispatch<AppActions>,
  reqpayload: IAddMemberPintsReqModal
) {
  try {
    await axios.put(`${BASE_API_URL}member/pints`, reqpayload);
    dispatch({
      type: ADD_LION_ADMIN_MEMBER_PINTS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: ADD_LION_ADMIN_MEMBER_PINTS_FAILED,
    });
  }
}

async function addAndFetchAsyncMemberPints(
  dispatch: Dispatch<AppActions>,
  addMemberPintsReqPayload: IAddMemberPintsReqModal
) {
  await addAsyncMemberPints(dispatch, addMemberPintsReqPayload);
  const paginationData: PaginationData =
    store.getState().lionAdminData.paginationHistory || DefaultPagination;
  await fetchAsyncMemberList(
    dispatch,
    paginationData?.currentIndex,
    paginationData?.sizePerPage,
    paginationData?.searchValue,
    paginationData?.sortOrder,
    paginationData?.columnName
  );
  await fetchAsyncTotalMembershipLevel(dispatch);
}

export const addMemberPints = (
  addMemberPintsReqPayload: IAddMemberPintsReqModal
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: ADD_LION_ADMIN_MEMBER_PINTS_STARTED,
    });
    return addAndFetchAsyncMemberPints(dispatch, addMemberPintsReqPayload);
  };
};
export const saveMemberListPagination = (
  currentIndex: number,
  sizePerPage: number,
  searchValue: string,
  sortOrder: string,
  columnName: string
): AppActions => {
  return {
    type: SAVE_LION_ADMIN_MEMBER_PAGINATION,
    payload: {
      paginationHistory: {
        currentIndex: currentIndex,
        sizePerPage: sizePerPage,
        searchValue: searchValue,
        sortOrder: sortOrder,
        columnName: columnName,
      },
    },
  };
};
export const fetchMemberList = (): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_LION_ADMIN_MEMBER_LIST_STARTED,
    });
    const paginationData: PaginationData =
      store.getState().lionAdminData.paginationHistory || DefaultPagination;
    return fetchAsyncMemberList(
      dispatch,
      paginationData?.currentIndex,
      paginationData?.sizePerPage,
      paginationData?.searchValue,
      paginationData?.sortOrder,
      paginationData?.columnName
    );
  };
};

export const saveSelectedMember = (member: IMemberPayload): AppActions => {
  return {
    type: SAVE_LION_ADMIN_SELECTED_MEMBER,
    payload: {
      selectedMember: member,
    },
  };
};

export const updateSelectedMemberForMobile = (): AppActions => {
  const selectedMember = store.getState().lionAdminData.selectedMember;
  return {
    type: SAVE_LION_ADMIN_SELECTED_MEMBER,
    payload: {
      selectedMember: {
        ...selectedMember,
        pintLevel: store.getState().lionAdminData.memberDetails!.pintLevel
      } as IMemberPayload,
    },
  };
};

async function fetchAsyncMemberDetails(dispatch: Dispatch<AppActions>, memberId?: number) {
  try {

    const selectedMemberId = store.getState().lionAdminData.selectedMember
        ?.memberId;
    const res = await axios.get(
      `${BASE_API_URL}member/${selectedMemberId}`
    );
    dispatch({
      type: FETCH_MEMBER_DETAILS_SUCCEEDED,
      payload: {
        memberDetails: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_MEMBER_DETAILS_FAILED,
    });
  }
}

export const FETCH_MEMBER_DETAILS_STARTED = 'FETCH_MEMBER_DETAILS_STARTED';
export const FETCH_MEMBER_DETAILS_SUCCEEDED = 'FETCH_MEMBER_DETAILS_SUCCEEDED';
export const FETCH_MEMBER_DETAILS_FAILED = 'FETCH_MEMBER_DETAILS_FAILED';
export const fetchMemberDetails = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_MEMBER_DETAILS_STARTED,
    });
    return fetchAsyncMemberDetails(dispatch);
  };
};


async function editAsyncMemberDetails(
  formValues: IMemberDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  try {
    const requestPayload = {
      ...formValues,
      pintLevel: parseInt(formValues.pintLevel.toString())
    };
    const res = await axios.put(`${BASE_API_URL}member/member`, requestPayload);
    dispatch({
      type: EDIT_MEMBER_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: EDIT_MEMBER_DETAILS_FAILED,
    });
  }
}

async function editAsyncMemberDetailsAndFetch(
  formValues: IMemberDetailsPayload,
  dispatch: Dispatch<AppActions>
) {
  await editAsyncMemberDetails(formValues, dispatch);
  await fetchAsyncMemberDetails(dispatch);
  const paginationData: PaginationData =
    store.getState().lionAdminData.paginationHistory || DefaultPagination;
  await fetchAsyncMemberList(
    dispatch,
    paginationData?.currentIndex,
    paginationData?.sizePerPage,
    paginationData?.searchValue,
    paginationData?.sortOrder,
    paginationData?.columnName
  );
  await fetchAsyncTotalMembershipLevel(dispatch);
}

export const EDIT_MEMBER_DETAILS_STARTED = 'EDIT_MEMBER_DETAILS_STARTED';
export const EDIT_MEMBER_DETAILS_SUCCEEDED = 'EDIT_MEMBER_DETAILS_SUCCEEDED';
export const EDIT_MEMBER_DETAILS_FAILED = 'EDIT_MEMBER_DETAILS_FAILED';
export const editMemberDetails = (
  formValues: IMemberDetailsPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: EDIT_MEMBER_DETAILS_STARTED,
    });
    return editAsyncMemberDetailsAndFetch(formValues, dispatch);
  };
};

export const SET_EDIT_MODE_MEMBER_DETAILS = 'SET_EDIT_MODE_MEMBER_DETAILS';
export const setEditMode = (isEditMode: boolean): AppActions => {
  return {
    type: SET_EDIT_MODE_MEMBER_DETAILS,
    payload: {
      isEditMode
    }
  };
};

export const CLEAR_MEMBER_EDIT = 'CLEAR_MEMBER_EDIT';
export const clearMemberEdit = (): AppActions => {
  return {
    type: CLEAR_MEMBER_EDIT,
  };
};

export const SHOW_LION_ADMIN_CREATE = 'SHOW_LION_ADMIN_CREATE';
export const showLionAdminCreate = (): AppActions => {
  return {
    type: SHOW_LION_ADMIN_CREATE,
  };
};

export const HIDE_LION_ADMIN_CREATE = 'HIDE_LION_ADMIN_CREATE';
export const hideLionAdminCreate = (): AppActions => {
  return {
    type: HIDE_LION_ADMIN_CREATE,
  };
};

async function createAsyncLionAdminDetails(
  requestPayload: ILionAdminCreatePayload,
  dispatch: Dispatch<AppActions>
) {
  try {
    const res = await axios.post(`${BASE_API_URL}user/admin`, requestPayload);
    dispatch({
      type: CREATE_LION_ADMIN_DETAILS_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: CREATE_LION_ADMIN_DETAILS_FAILED,
    });
  }
}

export const CREATE_LION_ADMIN_DETAILS_STARTED =
  'CREATE_LION_ADMIN_DETAILS_STARTED';
export const CREATE_LION_ADMIN_DETAILS_SUCCEEDED =
  'CREATE_LION_ADMIN_DETAILS_SUCCEEDED';
export const CREATE_LION_ADMIN_DETAILS_FAILED =
  'CREATE_LION_ADMIN_DETAILS_FAILED';
export const createLionAdminDetails = (
  requestPayload: ILionAdminCreatePayload
): ((dispatch: Dispatch) => Promise<void>) => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CREATE_LION_ADMIN_DETAILS_STARTED,
    });
    return createAsyncLionAdminDetails(requestPayload, dispatch);
  };
};

export const CLEAR_LION_ADMIN_CREATE = 'CLEAR_LION_ADMIN_CREATE';
export const clearLionAdminCreate = (): AppActions => {
  return {
    type: CLEAR_LION_ADMIN_CREATE,
  };
};

export const SET_DISPLAYING_MEMBER = 'SET_DISPLAYING_MEMBER';
export const setDisplayingMember = (value: ValueType<{ label: string; value: string }>): AppActions => {
  return {
    type: SET_DISPLAYING_MEMBER,
    payload: {
      displayingMember: value
    }
  }
}

export async function fetchAsyncMembershipLevel(dispatch: Dispatch<AppActions>) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const res = await axios.get(
      `${BASE_API_URL}bar/members/level?id=${selectedBarId}`
    );

    const membershipLevelCountValue = res.data.filter(function( item: IMembershipByLevel ) {
      return item.membershipLevel !== "0";
    });

    dispatch({
      type: FETCH_MEMBERSHIP_LEVEL_SUCCEEDED,
      payload: {
        membershipLevelCount: membershipLevelCountValue,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_MEMBERSHIP_LEVEL_FAILED,
    });
  }
}

export const FETCH_MEMBERSHIP_LEVEL_STARTED = 'FETCH_MEMBERSHIP_LEVEL_STARTED';
export const FETCH_MEMBERSHIP_LEVEL_SUCCEEDED = 'FETCH_MEMBERSHIP_LEVEL_SUCCEEDED';
export const FETCH_MEMBERSHIP_LEVEL_FAILED = 'FETCH_MEMBERSHIP_LEVEL_FAILED';
export const fetchMembershipLevel = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_MEMBERSHIP_LEVEL_STARTED,
    });
    return fetchAsyncMembershipLevel(dispatch);
  };
};


export async function fetchAsyncMemberAchievement(currentIndex: number, dispatch: Dispatch<AppActions>) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const res = await axios.get(
      `${BASE_API_URL}bar/members/achievements?id=${selectedBarId}&start=${currentIndex}&limit=20`
    );
    let dataLeft = [];
    let dataRight = [];
    if (res.data?.data?.pagedItems) {
      const paginatedList = res.data?.data?.pagedItems;
      if (paginatedList.length == 20) {
        dataLeft = paginatedList.slice(0, 10);
        dataRight = paginatedList.slice(10, 20);
      } else if (paginatedList.length > 10 && paginatedList.length < 20) {
        dataLeft = paginatedList.slice(0, 10);
        dataRight = paginatedList.slice(10, paginatedList.length);
      } else if (paginatedList.length > 0 && paginatedList.length <= 10) {
        dataLeft = paginatedList.slice(0, paginatedList.length);
      }
    }


    dispatch({
      type: FETCH_MEMBER_ACHIEVEMENT_SUCCEEDED,
      payload: {
        memberAchievementDataLeft: dataLeft,
        memberAchievementDataRight: dataRight,
        memberAchievementTotalItems: res.data?.overAllTotalItems
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_MEMBER_ACHIEVEMENT_FAILED,
    });
  }
}

export const FETCH_MEMBER_ACHIEVEMENT_STARTED = 'FETCH_MEMBER_ACHIEVEMENT_STARTED';
export const FETCH_MEMBER_ACHIEVEMENT_SUCCEEDED = 'FETCH_MEMBER_ACHIEVEMENT_SUCCEEDED';
export const FETCH_MEMBER_ACHIEVEMENT_FAILED = 'FETCH_MEMBER_ACHIEVEMENT_FAILED';
export const fetchMemberAchievement = (currentIndex: number): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_MEMBER_ACHIEVEMENT_STARTED,
    });
    return fetchAsyncMemberAchievement(currentIndex, dispatch);
  };
};

async function fetchAsyncNewMembershipCount(dispatch: Dispatch<AppActions>) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const res = await axios.get(
      `${BASE_API_URL}bar/members/month/memberships?id=${selectedBarId}`
    );

    dispatch({
      type: FETCH_NEW_MEMBERSHIP_COUNT_SUCCEEDED,
      payload: {
        membershipCount: res.data.membershipCount,
        monthYear: res.data.monthYear
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_NEW_MEMBERSHIP_COUNT_FAILED,
    });
  }
}

export const FETCH_NEW_MEMBERSHIP_COUNT_STARTED = 'FETCH_NEW_MEMBERSHIP_COUNT_STARTED';
export const FETCH_NEW_MEMBERSHIP_COUNT_SUCCEEDED = 'FETCH_NEW_MEMBERSHIP_COUNT_SUCCEEDED';
export const FETCH_NEW_MEMBERSHIP_COUNT_FAILED = 'FETCH_NEW_MEMBERSHIP_COUNT_FAILED';
export const fetchNewMembershipCount = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_NEW_MEMBERSHIP_COUNT_STARTED,
    });
    return fetchAsyncNewMembershipCount(dispatch);
  };
};

export async function fetchAsyncTotalMembershipLevel(dispatch: Dispatch<AppActions>) {
  try {
    const res = await axios.get(
      `${BASE_API_URL}club/total-membership-by-level`
    );

    dispatch({
      type: FETCH_TOTAL_MEMBERSHIP_LEVEL_SUCCEEDED,
      payload: {
        totalMembershipLevelCount: res.data.clubMembershipByLevelDetailDTOs,
        totalMembersForBarGraph: res.data.totalMembers
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_TOTAL_MEMBERSHIP_LEVEL_FAILED,
    });
  }
}

export const FETCH_TOTAL_MEMBERSHIP_LEVEL_STARTED = 'FETCH_TOTAL_MEMBERSHIP_LEVEL_STARTED';
export const FETCH_TOTAL_MEMBERSHIP_LEVEL_SUCCEEDED = 'FETCH_TOTAL_MEMBERSHIP_LEVEL_SUCCEEDED';
export const FETCH_TOTAL_MEMBERSHIP_LEVEL_FAILED = 'FETCH_TOTAL_MEMBERSHIP_LEVEL_FAILED';
export const fetchTotalMembershipLevel = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_TOTAL_MEMBERSHIP_LEVEL_STARTED,
    });
    return fetchAsyncTotalMembershipLevel(dispatch);
  };
};

async function fetchAsyncTotalMemberRegistration(dispatch: Dispatch<AppActions>) {
  try {
    const res = await axios.get(
      `${BASE_API_URL}club/total-monthly-registration`
    );

    const registrationArray = [];
    for (let i=0; i<res.data.length; i++) {
      const monthRegObj: ITotalMemberRegistration = {month: '', registrationCount: 0};
      monthRegObj.month = res.data[i].month.slice(0, 3) + ' ' + res.data[i].year.toString().slice(2,4)
      monthRegObj.registrationCount = res.data[i].registrationCount;
      registrationArray.push(monthRegObj);
      if (i < res.data.length - 1) {
        const monthRegObjHidden: ITotalMemberRegistration = {month: '', registrationCount: 0};
        monthRegObjHidden.month = res.data[i].month.slice(0, 3) + ' ' + res.data[i+1].month.slice(0, 3) + ' ' + res.data[i].year.toString().slice(2,4)
        monthRegObjHidden.registrationCount = (res.data[i].registrationCount + res.data[i+1].registrationCount) / 2;
        registrationArray.push(monthRegObjHidden);
      }
    }

    dispatch({
      type: FETCH_TOTAL_MEMBER_REGISTRATION_SUCCEEDED,
      payload: {
        totalMemberRegistration: registrationArray,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_TOTAL_MEMBER_REGISTRATION_FAILED,
    });
  }
}

export const FETCH_TOTAL_MEMBER_REGISTRATION_STARTED = 'FETCH_TOTAL_MEMBER_REGISTRATION_STARTED';
export const FETCH_TOTAL_MEMBER_REGISTRATION_SUCCEEDED = 'FETCH_TOTAL_MEMBER_REGISTRATION_SUCCEEDED';
export const FETCH_TOTAL_MEMBER_REGISTRATION_FAILED = 'FETCH_TOTAL_MEMBER_REGISTRATION_FAILED';
export const fetchTotalMemberRegistration = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_TOTAL_MEMBER_REGISTRATION_STARTED,
    });
    return fetchAsyncTotalMemberRegistration(dispatch);
  };
};

async function fetchAsyncMemberListForBar(dispatch: Dispatch<AppActions>, searchName: string) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const res = await axios.get(
      `${BASE_API_URL}bar/search-bar-members?barId=${selectedBarId}&memberName=${searchName}`
    );

    dispatch({
      type: FETCH_MEMBER_LIST_FOR_BAR_SUCCEEDED,
      payload: {
        memberListForBar: res.data
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_MEMBER_LIST_FOR_BAR_FAILED,
    });
  }
}

export const FETCH_MEMBER_LIST_FOR_BAR_STARTED = 'FETCH_MEMBER_LIST_FOR_BAR_STARTED';
export const FETCH_MEMBER_LIST_FOR_BAR_SUCCEEDED = 'FETCH_MEMBER_LIST_FOR_BAR_SUCCEEDED';
export const FETCH_MEMBER_LIST_FOR_BAR_FAILED = 'FETCH_MEMBER_LIST_FOR_BAR_FAILED';
export const fetchMemberListForBar = (searchName: string): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_MEMBER_LIST_FOR_BAR_STARTED,
    });
    return fetchAsyncMemberListForBar(dispatch, searchName);
  };
};

export const CLEAR_MEMBER_LIST_FOR_BAR = 'CLEAR_MEMBER_LIST_FOR_BAR';
export const clearMemberListForBar = (): AppActions => {
  return {
    type: CLEAR_MEMBER_LIST_FOR_BAR,
    payload: {
      memberListForBar: []
    }
  }
}





