import * as Yup from 'yup';
import { alphabetWithHypenRegex } from '../../shared/utils/input-regex';

const ContactUsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect'),
  lastName: Yup.string()
    .required('Last Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect'),
  email: Yup.string()
    .email('Email format is incorrect')
    .required('Email address is required'),
  contactMessage: Yup.string().required('Message is required'),
});

export default ContactUsValidationSchema;
