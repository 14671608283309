import React from 'react';
import trackPints from '../../assets/chart@3x.png';

export const TrackPinter: React.FC = () => {
  return (
    <div className="track-pints-container section-xl-padding">
      <div className="d-flex flex-column-reverse flex-md-row justify-content-center align-items-center justify-content-md-between container-md inner-container">
        <div className="col-12 col-md-7 image-container">
          <img src={trackPints} alt="Track Pints" />
        </div>
        <div className="col-12 col-md-5 text-container d-flex flex-column">
          <h1 className="primaryText">Track Your Pints</h1>
          <div className="divider align-left"></div>
          <div className="description  py-0 py-md-4">
            <p>
              Collect a stamp for every pint you buy at participating Guinness
              1759 Club Bars.
            </p>
            <p>
              Turn the card in at your chosen local to record your pints against
              your profile and see how you stack up on the bar’s leaderboard.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
