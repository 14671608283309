import React, { useEffect, useState } from 'react';
import ReactTooltip from "react-tooltip";
import './member-view.scss';
import {showModal} from "../../../actions/commonAction";
import {MODAL_DIALOG_NAMES} from "../../../shared/configs/appConfigs";
import { useDispatch } from 'react-redux';
import infoIcon from '../../../assets/icons/icons-system-information-line.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import chevronUp from '../../../assets/icons/icons-system-chevron-up.svg';
import chevronDown from '../../../assets/icons/icons-system-chevron-down.svg';
import { editShirtSize } from '../../../actions/memberAction';

const MemberView: React.FC<{barName?: string, shirtSize?: string}> = (props) => {

    const [isOpen, setIsMenuOpen] = useState(false);

    const dispatch = useDispatch();

    const onClickContactus = () => {
        dispatch(showModal(MODAL_DIALOG_NAMES.contactUs));
    }

    const setMenuOpen = (isOpen: boolean) => {
        setIsMenuOpen(isOpen);
    }

    const editMemberShirtSize = (shirtSize: string) => {
        dispatch(editShirtSize(shirtSize))
    }

    return(
        <div className="member-view-container">
            <p className="member-view-container__stamps-text">Make sure you collect your stamps at your local pub to track your progress</p>
            <div className="divider-full"></div>
            <div className="member-view-container__member-details">
                <div className="bar-details">
                    <div className="bar-heading">MY LOCAL</div>
                    <div className="bar-container">
                        <div className="bar-name">{props.barName}</div>
                        <div className="contact-us-tooltip" data-testid="contact-us-tooltip" data-tip data-for='tooltip' data-event='click focus'>
                            <img className="infoIcon" src={infoIcon}/>
                        </div>
                    </div>
                    <ReactTooltip globalEventOff='click' id='tooltip' type='light' place='bottom' effect='solid' multiline={true} clickable={true}>
                        <span className="tooltip-text"><span className="get-in-touch-text" onClick={() => onClickContactus()}>Get in touch</span> if you want <br/> to change your local bar</span>
                    </ReactTooltip>
                </div>
                <div className="member-details-divider"></div>
                <div className="t-shirt-details">
                    <div className="t-shirt-heading">MY T-SHIRT SIZE</div>
                    <Dropdown onToggle={setMenuOpen}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <div className="t-shirt-container">
                                <div>{props.shirtSize}</div>
                                <div className="edit-tooltip" data-testid="edit-tooltip">
                                    <img className="editIcon" src={isOpen ? chevronDown : chevronUp}/>
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => editMemberShirtSize('S')}>
                                S
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editMemberShirtSize('M')}>
                                M
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editMemberShirtSize('L')}>
                                L
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editMemberShirtSize('XL')}>
                                XL
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editMemberShirtSize('2XL')}>
                                2XL
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editMemberShirtSize('3XL')}>
                                3XL
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editMemberShirtSize('4XL')}>
                                4XL
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editMemberShirtSize('5XL')}>
                                5XL
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            </div>
            <div className="divider-full"></div>
        </div>
    )
}

export default MemberView