import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import validationSchema from './BarCreateValidationSchema';
import {
  AppEmailInput,
  AppSelectInput,
  AppTextInput,
} from '../../../shared/components/ui/form-input/FormInput';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import './bar-create.scss';
import GooglePlaces from './GooglePlaces';
import {
  createBarDetails,
  clearBarCreate,
} from '../../../actions/lionAdminAction';
import { AppState } from '../../../reducers/rootReducer';
import Alert from 'react-bootstrap/Alert';
import loadIcon from '../../../assets/global-element-spinner.svg';
import CancelRegistrationModal from './CancelRegistrationModal';

const BarCreate: React.FC = () => {
  const [value, setValue] = useState('');

  const [
    cancelRegistrationModalShow,
    setCancelRegistrationModalShow,
  ] = useState<boolean>(false);

  const [addressPoints, setAddressPoints] = useState({
    lat: '',
    lng: '',
  });

  const [barDetails, setBarDetails] = useState({
    sapCustomerId: '',
    name: '',
    address: '',
    suburb: '',
    city: '',
    postCode: '',
    address2: '',
    phone: '',
    email: '',
    contactFirstName: '',
    contactLastName: '',
    tier: '',
    barAdminFirstName: '',
    barAdminLastName: '',
    barAdminEmail: '',
  });

  const handleSetValue = (value: string) => {
    setValue(value);
  };

  function hideCancelRegistrationModalShow(): void {
    setCancelRegistrationModalShow(false);
  }

  function submitCancel(): void {
    clearForm();
    setValue('');
  }

  const handleSetBarAddress = (
    value: {
      address: string;
      suburb: string;
      city: string;
      postCode: string;
    },
    formikValues: any
  ) => {
    setValue(value.address);
    setBarDetails({
      sapCustomerId: formikValues.sapCustomerId,
      name: formikValues.name,
      address2: formikValues.address2,
      phone: formikValues.phone,
      email: formikValues.email,
      contactFirstName: formikValues.contactFirstName,
      contactLastName: formikValues.contactLastName,
      tier: formikValues.tier,
      barAdminFirstName: formikValues.barAdminFirstName,
      barAdminLastName: formikValues.barAdminLastName,
      barAdminEmail: formikValues.barAdminEmail,
      address: value.address,
      suburb: value.suburb,
      city: value.city,
      postCode: value.postCode,
    });
  };

  const handleSetAddressPoints = (points: { lat: string; lng: string }) => {
    setAddressPoints({
      lat: points.lat,
      lng: points.lng,
    });
  };

  const tShirtArr = [
    { value: 'None', label: 'None' },
    { value: 'Silver', label: 'Silver' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Platinum', label: 'Platinum' },
  ];

  const dispatch = useDispatch();

  const lionAdminData = useSelector<AppState, AppState['lionAdminData']>(
    (state) => state.lionAdminData
  );

  useEffect(() => {
    if (lionAdminData.isCreateBarsSuccess) {
      clearForm();
      setValue('');
    }
  }, [lionAdminData.isCreateBarsSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearBarCreate());
    };
  }, [dispatch]);

  const clearForm = () => {
    setBarDetails({
      sapCustomerId: '',
      name: '',
      address: '',
      suburb: '',
      city: '',
      postCode: '',
      address2: '',
      phone: '',
      email: '',
      contactFirstName: '',
      contactLastName: '',
      tier: '',
      barAdminFirstName: '',
      barAdminLastName: '',
      barAdminEmail: '',
    });
  };

  function onCancelRegistrationModalShow() {
    setCancelRegistrationModalShow(true);
  }

  return (
    <div className="bar-create-dashboard-section">
      <h3 className="bar-create-dashboard-section__heading">REGISTER BAR</h3>
      <Formik
        initialValues={barDetails!}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={async (values) => {
          dispatch(createBarDetails(values, addressPoints));
        }}
      >
        {({
          setFieldValue,
          setFieldTouched,
          resetForm,
          errors,
          touched,
          isValid,
        }) => {
          return (
            <Form>
              <div className="bar-create-dashboard-section__form">
                <div className="bar-create-dashboard-section__form-section">
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="SAP Customer ID"
                      id="sapCustomerId"
                      name="sapCustomerId"
                      type="text"
                      error={errors.sapCustomerId}
                      touched={touched.sapCustomerId}
                      maxLength={60}
                    />
                    <ValidationErrorTemplate
                      error={errors.sapCustomerId}
                      touched={touched.sapCustomerId}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="Bar Name"
                      id="name"
                      name="name"
                      type="text"
                      error={errors.name}
                      touched={touched.name}
                      maxLength={60}
                    />
                    <ValidationErrorTemplate
                      error={errors.name}
                      touched={touched.name}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="Contact First Name"
                      id="contactFirstName"
                      name="contactFirstName"
                      type="text"
                      error={errors.contactFirstName}
                      touched={touched.contactFirstName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      data-testid="test-id-error"
                      error={errors.contactFirstName}
                      touched={touched.contactFirstName}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="Contact Last Name"
                      id="contactLastName"
                      name="contactLastName"
                      type="text"
                      error={errors.contactLastName}
                      touched={touched.contactLastName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      error={errors.contactLastName}
                      touched={touched.contactLastName}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppEmailInput
                      label="Contact Email address"
                      id="email"
                      name="email"
                      type="email"
                      maxLength={320}
                      error={errors.email}
                      touched={touched.email}
                    />
                    <ValidationErrorTemplate
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="Phone"
                      id="phone"
                      name="phone"
                      type="text"
                      minLength={10}
                      maxLength={10}
                      touched={touched.phone}
                      error={errors.phone}
                    />
                    <ValidationErrorTemplate
                      error={errors.phone}
                      touched={touched.phone}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppSelectInput
                      label="Tier"
                      id="tier"
                      name="tier"
                      val={barDetails?.tier}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      error={errors.tier}
                      touched={touched.tier}
                      optionArray={tShirtArr}
                    />
                    <ValidationErrorTemplate
                      error={errors.tier}
                      touched={touched.tier}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <div className="form-group google-address">
                      <label>Address</label>
                      <GooglePlaces
                        label="Address"
                        id="address"
                        name="address"
                        value={value}
                        touched={touched.address}
                        error={errors.address}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        onSetValue={handleSetValue}
                        onSetAddress={handleSetBarAddress}
                        onSetAddressPoints={handleSetAddressPoints}
                      />
                      <ValidationErrorTemplate
                        error={errors.address}
                        touched={touched.address}
                        isMapSelect={true}
                      />
                    </div>
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Suburb"
                      id="suburb"
                      name="suburb"
                      type="text"
                      maxLength={60}
                      error={errors.suburb}
                      touched={touched.suburb || false}
                      disabled
                    />
                    <ValidationErrorTemplate
                      error={errors.suburb}
                      touched={touched.suburb}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="City"
                      id="city"
                      name="city"
                      maxLength={60}
                      type="text"
                      error={errors.city}
                      touched={touched.city || false}
                      disabled
                    />
                    <ValidationErrorTemplate
                      error={errors.city}
                      touched={touched.city}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Post Code"
                      id="postCode"
                      name="postCode"
                      type="text"
                      maxLength={4}
                      error={errors.postCode}
                      touched={touched.postCode || false}
                      disabled
                    />
                    <ValidationErrorTemplate
                      error={errors.postCode}
                      touched={touched.postCode}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="Address 2"
                      id="address2"
                      name="address2"
                      type="text"
                      maxLength={100}
                      error={errors.address2}
                      touched={touched.address2}
                    />
                    <ValidationErrorTemplate
                      error={errors.address2}
                      touched={touched.address2}
                    />
                  </div>
                </div>
                <div className="bar-create-dashboard-section__form-section-divider" />
                <div className="bar-create-dashboard-section__form-section">
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="Bar Admin First Name"
                      id="barAdminFirstName"
                      name="barAdminFirstName"
                      type="text"
                      error={errors.barAdminFirstName}
                      touched={touched.barAdminFirstName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      data-testid="test-id-error"
                      error={errors.barAdminFirstName}
                      touched={touched.barAdminFirstName}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="Bar Admin Last Name"
                      id="barAdminLastName"
                      name="barAdminLastName"
                      type="text"
                      error={errors.barAdminLastName}
                      touched={touched.barAdminLastName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      error={errors.barAdminLastName}
                      touched={touched.barAdminLastName}
                    />
                  </div>
                  <div className="bar-create-dashboard-section__field">
                    <AppEmailInput
                      label="Bar Admin Email address"
                      id="barAdminEmail"
                      name="barAdminEmail"
                      type="email"
                      maxLength={320}
                      error={errors.barAdminEmail}
                      touched={touched.barAdminEmail}
                    />
                    <ValidationErrorTemplate
                      error={errors.barAdminEmail}
                      touched={touched.barAdminEmail}
                    />
                  </div>
                </div>

                <div className="bar-create-dashboard-section__buttons">
                  <Button
                    variant="primary"
                    type="submit"
                    className="register-button"
                    disabled={!isValid || lionAdminData.isCreateBarsLoading}
                  >
                    Register
                  </Button>

                  <Button
                    className="btn btn-primary btn-outline-secondary cancel-button"
                    type="button"
                    onClick={() => onCancelRegistrationModalShow()}
                    style={{ display: !isValid ? 'none' : 'block' }}
                  >
                    Cancel
                  </Button>
                  {lionAdminData.isCreateBarsLoading ? (
                    <div className="loader loading-icon">
                      <img
                        className="spinner"
                        src={loadIcon}
                        alt="Loading..."
                      />
                    </div>
                  ) : null}
                </div>
                {lionAdminData.isCreateBarsSuccess ? (
                  <Alert variant="success">
                    {lionAdminData.createBarsMessage}
                  </Alert>
                ) : null}
                {lionAdminData.isCreateBarsError ? (
                  <Alert variant="danger">
                    {lionAdminData.createBarsMessage}
                  </Alert>
                ) : null}
                {cancelRegistrationModalShow && (
                  <CancelRegistrationModal
                    hideModalShow={hideCancelRegistrationModalShow}
                    onSubmitCancel={submitCancel}
                    onResetForm={resetForm}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BarCreate;
