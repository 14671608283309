import React, { useEffect, useState } from 'react';
import { IModalConfig } from '../../../shared/types/modalInterface';
import MemberInfo from './MemberInfo';
import { CustomModal } from '../../../shared/components/ui/modal/modal';
import { MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/reducers/rootReducer';
import { hideModal } from 'src/actions/commonAction';

const MemberInfoModal: React.FC = () => {
  const [showMemberInfoModal, setShowMemberInfoModal] = useState(false);
  const dispatch = useDispatch();
  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );

  useEffect(() => {
    if(commonData?.isOpenDialog && commonData.modalName === MODAL_DIALOG_NAMES.memberInfo) {
      setShowMemberInfoModal(true);
    } else {
      setShowMemberInfoModal(false);
    }
  }, [commonData?.isOpenDialog])

  const memberInfoModalConfig: IModalConfig = {
    title: commonData?.logoElement,
    isImageTitle: true,
    showModal: true,
    modalSize: 'largeModal largeModalCenterAligned',
    closeModal: closeModal,
    body: <MemberInfo />,
  };

  function closeModal(): void {
    dispatch(hideModal(MODAL_DIALOG_NAMES.memberInfo));
  }

  return (
    <div>
      {showMemberInfoModal && <CustomModal config={memberInfoModalConfig}></CustomModal>}
    </div>
  );
};

export default MemberInfoModal;