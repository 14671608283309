import React, { PureComponent, useEffect } from 'react';
import { AreaChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Area } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTotalMemberRegistration, fetchTotalMembershipLevel } from '../../../actions/lionAdminAction';
import { AppState } from '../../../reducers/rootReducer';
import { isMobile } from 'react-device-detect';
import loadIcon from '../../../assets/global-element-spinner.svg';
import './member-registrations.scss';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

class CustomTooltip extends PureComponent {
  render() {
    const { payload, label } = this.props as any;
    if (label && label.length == 6 && payload && payload.length > 0){
      return (
        <div style={{fontSize: '14px', fontFamily: 'Gotham-bold', color: 'white', backgroundColor: '#0c2340', borderRadius: '5px', padding: '10px 20px'}}>
          <div>{`${payload[0].value}`}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

class CustomizedDot extends React.Component<any, any> {
  render() {
    const { cx, cy, payload, showToolTip, hideToolTip } = this.props as any;
    if (payload.month.length == 6){
      return (
        <circle cx={cx} cy={cy} r={5} stroke="#489fdf" strokeWidth={2} fill="#ffffff"
                onMouseOver={showToolTip}
                onMouseLeave={hideToolTip}
        />
      );
    }
    return null;
  }
}

class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as any;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fontFamily="Gotham"
              fontSize={!isMobile ? 14 : 12} letterSpacing={!isMobile ? -0.1 : -0.09}>
          {payload.value}
        </text>
      </g>
    );
  }
}

class CustomizedYAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as any;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={-12} dy={4} textAnchor="middle" fontFamily="Gotham"
              fontSize={!isMobile ? 14 : 12} letterSpacing={!isMobile ? -0.1 : -0.09}>
          {payload.value}
        </text>
      </g>
    );
  }
}


export const MemberRegistrations: React.FC<{testWidth?: number, testHeight?: number}> = (props) => {

  let tooltip: any = null;

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchTotalMemberRegistration());

      const callApi = setInterval(() => {
        dispatch(fetchTotalMemberRegistration());
      }, 1800000);

      return () => clearInterval(callApi);
    }, []);

    const totalMemberRegistration = useSelector<
      AppState,
      AppState['lionAdminData']['totalMemberRegistration']
      >((state) => state.lionAdminData.totalMemberRegistration);

  const isTotalMemberRegistrationLoading = useSelector<
    AppState,
    AppState['lionAdminData']['isTotalMemberRegistrationLoading']
    >((state) => state.lionAdminData.isTotalMemberRegistrationLoading);

  const isTotalMemberRegistrationError = useSelector<
    AppState,
    AppState['lionAdminData']['isTotalMemberRegistrationError']
    >((state) => state.lionAdminData.isTotalMemberRegistrationError);

  const totalMemberRegistrationErrorMessage = useSelector<
    AppState,
    AppState['lionAdminData']['totalMemberRegistrationErrorMessage']
    >((state) => state.lionAdminData.totalMemberRegistrationErrorMessage);

  if (isTotalMemberRegistrationError) {
    return (
      <div style={{color: 'red', textAlign: 'center'}}>{totalMemberRegistrationErrorMessage}</div>
    )
  }

  const showToolTip = () => {
    tooltip.props.wrapperStyle.opacity = 1;
  };

  const hideToolTip = () => {
    tooltip.props.wrapperStyle.opacity = 0;
  };

    return(
      <SimpleBar forceVisible="y" autoHide={false}>
        <div style={{
            paddingBottom: '320px',
            position: 'relative',
            height: 0
        }}>
            <div className="member-registration-container ui-chart" data-testid="total-member-registrations-chart">
              {
                !isTotalMemberRegistrationLoading
                ? (
                <ResponsiveContainer
                  width={props.testWidth || "100%"}
                  height={props.testHeight || "100%"}
                >
                <AreaChart
                width={500}
                height={300}
                data={totalMemberRegistration}
                margin={{
                top: 5,
                right: 20,
                left: 5,
                bottom: 5,
              }}
                >
                <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#489fdf" stopOpacity={0.99}/>
                <stop offset="95%" stopColor="#489fdf" stopOpacity={0.13}/>
                </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <XAxis dataKey="month" interval={1} tickLine={false} tick={<CustomizedXAxisTick/>}/>
                <YAxis tickLine={false} tick={<CustomizedYAxisTick/>}/>
                <Tooltip cursor={false} content={<CustomTooltip />} ref={ref => tooltip = ref}/>
              {
                totalMemberRegistration?.map((point, idx) => {
                if (idx % 2 !== 0){
                return(<ReferenceLine key={idx} x={point.month}/>)
              }
              })
              }
                <Area type="linear" dataKey="registrationCount" fillOpacity={1}
                      fill="url(#colorUv)" stroke="#489fdf" isAnimationActive={false}
                      dot={<CustomizedDot />}
                      activeDot={<CustomizedDot showToolTip={showToolTip} hideToolTip={hideToolTip}/>}
                />
                </AreaChart>
                </ResponsiveContainer>
                )
                  : <div className="center loading-section" style={{height: '80%'}}>
                      <img className="spinner" src={loadIcon} alt="Loading..." />
                    </div>
              }
            </div>
        </div>
      </SimpleBar>
    )

}