import * as Yup from 'yup';
import {
  maxText,
  alphabetWithHypenRegex,
  numberRegex,
  pswdRegex,
  alphabetNumberWithHypenRegex
} from '../../shared/utils/input-regex';
import { PASSWORD_MAX_LENGTH } from '../../shared/configs/appConfigs';

const RegistrationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect')
    .max(50, `${maxText} 50`),
  lastName: Yup.string()
    .required('Last Name is required')
    .matches(alphabetWithHypenRegex, 'Name format is incorrect')
    .max(50, `${maxText} 50`),
  email: Yup.string()
    .email('Email format is incorrect')
    .required('Email address is required')
    .max(320, `${maxText}  320 `),
  dob: Yup.date()
    .required('You must be 18 years or older to register')
    .nullable(),
  tShirtSize: Yup.string().required('Please select your T-Shirt size.'),
  boardName: Yup.string()
    .matches(alphabetWithHypenRegex, 'Board Name must contain letters only.')
    .max(100, `${maxText} 100`),
  address: Yup.string().max(100, `Address ${maxText}`),
  address2: Yup.string().max(100, `${maxText} 100`),
  suburb: Yup.string().max(60, `${maxText} 60`),
  city: Yup.string().max(60, `${maxText} 60`),
  postCode: Yup.string()
    .nullable()
    .matches(numberRegex, 'Post Code must be a number'),
  barId: Yup.number()
    .required('Please select your Guinness Club bar')
    .nullable(),
  password: Yup.string()
    .required('Required')
    .matches(
      pswdRegex,
      'Password is incorrect (min: 8 length, 1 lowercase, uppercase, number, special character)'
    )
    .min(8, 'Too short. minimum 8 characters')
    .max(PASSWORD_MAX_LENGTH, `${maxText } ${ PASSWORD_MAX_LENGTH }`),
  hasAcceptedTermsConditions: Yup.boolean().oneOf(
    [true],
    'Please confirm this selection.'
  ),
  mobileNumber: Yup.string()
    .min(10, 'Min Length is 10')
    .max(10, `${maxText} 10`)
    .matches(
      numberRegex,
      'Mobile number format is incorrect.Accepts only numbers'
    )
    .nullable(),
});

export default RegistrationValidationSchema;
