import React, { PureComponent, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './memberships-by-level.scss';
import { fetchMembershipLevel, fetchNewMembershipCount } from '../../../actions/lionAdminAction';
import { useDispatch, useSelector } from 'react-redux';
import {AppState} from "../../../reducers/rootReducer";
import loadIcon from '../../../assets/global-element-spinner.svg';
import { isMobile } from 'react-device-detect';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value, width, height } = this.props as any;
      return (
        <text x={x + width/2} y={y - 5} fill="black" textAnchor="middle"
              fontFamily="Gotham-bold" fontSize={!isMobile ? 14 : 12} letterSpacing={!isMobile ? -0.1 : -0.09}>{value}</text>
      );
    return null;
  }
}

class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as any;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fontFamily="Gotham"
              fontSize={!isMobile ? 14 : 12} letterSpacing={!isMobile ? -0.1 : -0.09}>
          {payload.value}
        </text>
      </g>
    );
  }
}

class CustomizedYAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as any;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={-12} dy={4} textAnchor="middle" fontFamily="Gotham"
              fontSize={!isMobile ? 14 : 12} letterSpacing={!isMobile ? -0.1 : -0.09}>
          {payload.value}
        </text>
      </g>
    );
  }
}

export const MembershipsByLevel: React.FC<{testWidth?: number, testHeight?: number}> = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMembershipLevel());

    const callApi = setInterval(() => {
      dispatch(fetchMembershipLevel());
    }, 1800000);

    return () => clearInterval(callApi);
  }, []);

  const membershipLevelCount = useSelector<
      AppState,
      AppState['lionAdminData']['membershipLevelCount']
      >((state) => state.lionAdminData.membershipLevelCount);

  const isMembershipLevelLoading = useSelector<
    AppState,
    AppState['lionAdminData']['isMembershipLevelLoading']
    >((state) => state.lionAdminData.isMembershipLevelLoading);


  const isMembershipLevelError = useSelector<
    AppState,
    AppState['lionAdminData']['isMembershipLevelError']
    >((state) => state.lionAdminData.isMembershipLevelError);

  const membershipLevelErrorMessage = useSelector<
    AppState,
    AppState['lionAdminData']['membershipLevelErrorMessage']
    >((state) => state.lionAdminData.membershipLevelErrorMessage);

  if (isMembershipLevelError) {
    return (
      <div style={{color: 'red', textAlign: 'center'}}>{membershipLevelErrorMessage}</div>
    )
  }

  return(
    <div style={{
      position: 'relative',
      height: 0
    }}>
      <div data-testid="memberships-by-level-chart" style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '270px'
      }}>
        {
          !isMembershipLevelLoading
          ? (
              <ResponsiveContainer
                width={props.testWidth || "100%"}
                height={props.testHeight || "100%"}
              >
                <BarChart
                  width={500}
                  height={270}
                  data={membershipLevelCount}
                  margin={{
                    top: 30,
                    right: 20,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                  <XAxis dataKey="membershipLevel" tickLine={false} tick={<CustomizedXAxisTick/>}/>
                  <YAxis axisLine={false} tickLine={false} tick={<CustomizedYAxisTick/>}/>
                  <Bar dataKey="count" fill="#fabea0" label={<CustomizedLabel />} />
                </BarChart>
              </ResponsiveContainer>
            )
          : <div className="center loading-section" style={{height: '80%'}}>
              <img className="spinner" src={loadIcon} alt="Loading..." />
            </div>
        }

    </div>
    </div>
  )

}