import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'react-bootstrap';
import ValidationErrorTemplate from 'src/shared/components/ui/validation-error/ValidationError';
import { LoginValidationSchema } from './LoginValidationSchema';
import { ILoginReqPayload } from './LoginRequestPayload.interface';
import { Redirect } from 'react-router';
import { AlertStatus } from '../../shared/components/ui/alert-status/AlertStatus';
import { useDispatch, useSelector } from 'react-redux';
import { performLogIn } from '../../actions/authAction';
import { AppState } from '../../reducers/rootReducer';
import loadIcon from '../../assets/global-element-spinner.svg';
import './login.scss';
import { PASSWORD_MAX_LENGTH } from '../../shared/configs/appConfigs';

interface Props {
  oncancel: (passwordModal?: boolean) => void;
}

export const Login: React.FC<Props> = (props) => {
  const authData = useSelector<AppState, AppState['authData']>(
    (state) => state.authData
  );

  const dispatch = useDispatch();

  const initialFormValues: ILoginReqPayload = {
    userName: '',
    password: '',
  };

  const onCancelModalEmit = () => {
    return props.oncancel();
  };

  const showPasswordModal = () => {
    return props.oncancel(true);
  };

  return (
    <div className="d-flex flex-column justify-content-start justify-content-lg-center form-section login-form">
      <Formik
        initialValues={initialFormValues}
        validationSchema={LoginValidationSchema}
        validateOnMount
        onSubmit={async (values, { resetForm }) => {
          const reqModalObj: ILoginReqPayload = { ...values };
          dispatch(performLogIn(reqModalObj));
          resetForm();
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          handleSubmit,
        }) => (
          <Form className="d-flex flex-column" onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column date-container">
                <div
                  className={`form-group ${
                    errors.userName && touched.userName ? 'input-error' : ''
                  }`}
                >
                  <label htmlFor="userName">Email address</label>
                  <input
                    type="text"
                    aria-label="user-name"
                    data-testid="email-login"
                    name="userName"
                    id="userName"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={320}
                    className={
                      errors.userName && touched.userName
                        ? 'input-error'
                        : undefined
                    }
                  />
                </div>
                <ValidationErrorTemplate
                  error={errors.userName}
                  touched={touched.userName}
                />
              </div>
              <div className="d-flex flex-column password-container">
                <div
                  className={`form-group ${
                    errors.password && touched.password ? 'input-error' : ''
                  }`}
                >
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    aria-label="password"
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={PASSWORD_MAX_LENGTH}
                    className={
                      errors.password && touched.password
                        ? 'input-error'
                        : undefined
                    }
                  />
                </div>
                <ValidationErrorTemplate
                  error={errors.password}
                  touched={touched.password}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end forgot-password-container">
              <Button
                variant="link"
                className="orangeText forgot-password"
                onClick={() => showPasswordModal()}
              >
                Forgot password
              </Button>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between">
              <Button
                type="submit"
                className={`${isValid} section-margin-new submit-login`}
                disabled={!isValid || authData.isAuthLoading}
              >
                Login
              </Button>
              {authData.isAuthLoading ? (
                <img className="spinner login-loader" src={loadIcon} alt="Loading..." />
              ) : null}
              <Button
                className="section-margin btn btn-outline-secondary cancel-login"
                onClick={() => onCancelModalEmit()}
              >
                Cancel
              </Button>
            </div>
            <AlertStatus
              status={authData.authErrorMessage!}
              isApiError={authData.isAuthError!}
            ></AlertStatus>
            {authData.isAuth && <Redirect to="/dashboard" />}
          </Form>
        )}
      </Formik>
    </div>
  );
};
