import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { SearchInput } from '../../shared/components/ui/search-input/SearchInput';
import {
  AppTextInput,
  AppEmailInput,
  AppPasswordInput,
  AppCheckboxInput,
  AppSelectInput,
} from '../../shared/components/ui/form-input/FormInput';
import { Col, Row, Image } from 'react-bootstrap';
import { IRegistrationRequestPayload } from './RegistrationRequestPayload.modal';
import './registration.scss';
import DatePicker from 'react-datepicker';
import { getAge } from '../../shared/utils/date';
import validationSchema from './RegistrationValidationSchema';
import { Formik, Form } from 'formik';
import { AlertStatus } from '../../shared/components/ui/alert-status/AlertStatus';
import ValidationErrorTemplate from '../../shared/components/ui/validation-error/ValidationError';
import Alert from 'react-bootstrap/Alert';
import infoIconImg from '../../assets/icons-system-info.svg';
import {
  BASE_API_URL,
  PASSWORD_MAX_LENGTH,
} from '../../shared/configs/appConfigs';
import loadIcon from '../../assets/global-element-spinner.svg';
import { ContentfulClientConfigs } from '../../shared/configs/ContentfulClientConfigs';
import moment from "moment";
import Recaptcha from "react-recaptcha";

const RECAPTCHA_SITE_KEY = '6Lc0RyMbAAAAAP78tgUvbcvT6ccmtayNbO_0OgQa';

export const RegistrationForm: React.FC = () => {
  const [formErrorFromServer, setFormErrorFromServer] = React.useState('');
  const [dob, setDob] = React.useState<Date | null>(null);
  const [isDisabled, setDisabled] = React.useState(false);
  const [registrationSuccess, setRegistrationSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isNotRecaptchaVerified, setIsNotRecaptchaVerified] = React.useState(true);
  const tShirtArr = [
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: '2XL', label: '2XL' },
    { value: '3XL', label: '3XL' },
    { value: '4XL', label: '4XL' },
    { value: '5XL', label: '5XL' },
  ];
  const initFormValues: IRegistrationRequestPayload = {
    email: '',
    firstName: '',
    lastName: '',
    dob: null,
    address: '',
    address2: '',
    suburb: '',
    city: '',
    postCode: '',
    tShirtSize: '',
    boardName: '',
    barId: '',
    password: '',
    hasAcceptedTermsConditions: false,
    mobileNumber: '',
  };

  const [registrationImage, setRegistrationImage] = useState('');
  const [logoImage, setLogoImage] = useState('');

  useEffect(() => {
    ContentfulClientConfigs.getAsset('6UPVhBPOIInQQrrX9NQIKN').then((asset) => {
      setRegistrationImage(`${asset.fields.file.url}`);
    });
    // TODO: Onetime fetch from contentful to use it across application
    ContentfulClientConfigs.getAsset('75CPwoYgUs5aom8XI0jzTi').then((asset) => {
      setLogoImage(`${asset.fields.file.url}`);
    });
  }, []);

  const handleChange_age = (
    date: Date,
    setFieldValue: (key: string, value: Date | null) => void,
    setFieldTouched: (key: string, isTouched: boolean | undefined) => void
  ) => {
    if (date) {
      const dob = date;
      const age = getAge(dob);
      JSON.stringify(dob);
      if (age && age >= 18) {
        setDob(dob);
        setFieldValue('dob', dob);
      } else {
        setDob(null);
        setFieldValue('dob', null);
        setFieldTouched('dob', true);
      }
    } else {
      setDob(null);
      setFieldValue('dob', null);
      setFieldTouched('dob', true);
    }
  };

  const recaptchaLoaded = () => {
    console.log("recaptcha Loaded");
  }

  const verifyCallback = (response: any) => {
    if (response) {
      setIsNotRecaptchaVerified(false);
    }
  }

  const expiredCallback = () => {
    setIsNotRecaptchaVerified(true);
  }

  return (
    <div className="d-flex flex-column flex-lg-row registration-form-container">
      <div className="reg-image-container">
        <div
          className="registration-image"
          style={{ backgroundImage: 'url(' + registrationImage + ')' }}
        >
          <img className="logo-image" src={logoImage} alt="Logo" />
        </div>
      </div>

      <div className="d-flex flex-column justify-content-start justify-content-lg-center form-section">
        <Row>
          <div className="title">Register to start tracking your pints</div>
        </Row>
        <Formik
          initialValues={initFormValues}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={async (values, { setStatus, resetForm, setFieldValue }) => {
            try {
              const reqModalObj: IRegistrationRequestPayload = { ...values };
              reqModalObj.dob = String(dob);
              if (dob) {
                const date = moment(new Date(reqModalObj.dob)).add(1, 'days');
                reqModalObj.dob = date.toISOString();
              }
              setLoading(true);
              await axios({
                method: 'post',
                url: `${BASE_API_URL}user/register/member`,
                data: reqModalObj,
              });
              setLoading(false);
              setDob(null);
              setFieldValue('dob', '');
              resetForm();
              setDisabled(true);
              setRegistrationSuccess(true);
              setFormErrorFromServer('');
              setStatus('Thank you for registering with Guinness 1759 Club.');
            } catch (err) {
              if (
                err &&
                err.response &&
                typeof err.response.data === 'string'
              ) {
                setFormErrorFromServer(err.response.data);
              }
              if (
                err &&
                err.response &&
                typeof err.response.data === 'object'
              ) {
                setFormErrorFromServer(err.response.data.message);
                // err.response.data.inputErrors.map((val: any, key: any) => {
                //   setFieldError('boardName', val.message);
                //   setFieldTouched('boardName', true);
                // })
              }
              setLoading(false);
              setDisabled(false);
              setFieldValue('hasAcceptedTermsConditions', false);
              setRegistrationSuccess(false);
            }
          }}
        >
          {({
            setFieldValue,
            setFieldTouched,
            status,
            errors,
            touched,
            isValid,
            isSubmitting,
          }) => (
            <Form autoComplete="off">
              <fieldset
                className={isDisabled ? 'form-disabled' : ''}
                disabled={isDisabled}
              >
                <Row>
                  <Col className="pl-0 pr-0 pr-lg-2" lg={6}>
                    <AppTextInput
                      label="First Name"
                      data-testid="testIdfirstName"
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      error={errors.firstName}
                      touched={touched.firstName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      data-testid="test-id-error"
                      error={errors.firstName}
                      touched={touched.firstName}
                    />
                  </Col>
                  <Col className="pl-0 pr-0 pl-lg-2" lg={6}>
                    <AppTextInput
                      label="Last Name"
                      data-testid="testIdlastName"
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      error={errors.lastName}
                      touched={touched.lastName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                  </Col>
                  <div className="form-group-register">
                    <label>Date of Birth</label>
                    <DatePicker
                      selected={dob}
                      name="dob"
                      id="dob"
                      className={`test-class ${
                        errors.dob && touched.dob ? 'input-error' : ''
                      }`}
                      ariaLabelledBy="Date of Birth"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                      data-testid="testIdDob"
                      showYearDropdown
                      onChange={(date: Date) =>
                        handleChange_age(date, setFieldValue, setFieldTouched)
                      }
                    />
                    <ValidationErrorTemplate
                      error={errors.dob}
                      touched={touched.dob}
                    />
                  </div>

                  <Col>
                    <AppEmailInput
                      label="Email address"
                      id="email"
                      name="email"
                      type="email"
                      placeholder=""
                      maxLength={320}
                      error={errors.email}
                      touched={touched.email}
                    />
                    <ValidationErrorTemplate
                      error={errors.email}
                      touched={touched.email}
                    />
                  </Col>
                  <Col>
                    <AppPasswordInput
                      label="Password"
                      id="password"
                      name="password"
                      type="password"
                      minLength={8}
                      maxLength={PASSWORD_MAX_LENGTH}
                      error={errors.password}
                      touched={touched.password}
                      placeholder="8+ characters"
                    />
                    <ValidationErrorTemplate
                      error={errors.password}
                      touched={touched.password}
                    />
                  </Col>
                  <Col className="search-container">
                    <SearchInput
                      label="Select Bar"
                      id="barId"
                      name="barId"
                      error={errors.barId}
                      touched={touched.barId}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      placeholder="Search for your local"
                    />
                    <ValidationErrorTemplate
                      error={errors.barId}
                      touched={touched.barId}
                    />
                  </Col>
                  <Col className="pl-0 pr-0 pr-lg-2" lg={6}>
                    <AppSelectInput
                      label="T-Shirt Size"
                      id="tShirtSize"
                      name="tShirtSize"
                      placeholder="Pick a size"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      error={errors.tShirtSize}
                      touched={touched.tShirtSize}
                      optionArray={tShirtArr}
                    />
                    <ValidationErrorTemplate
                      error={errors.tShirtSize}
                      touched={touched.tShirtSize}
                    />
                  </Col>
                  <Col className="pl-0 pr-0 pl-lg-2" lg={6}>
                    <AppTextInput
                      label="Mobile Number (Optional)"
                      id="mobileNumber"
                      name="mobileNumber"
                      type="text"
                      minLength={10}
                      maxLength={10}
                      touched={touched.mobileNumber}
                      error={errors.mobileNumber}
                    />
                    <ValidationErrorTemplate
                      error={errors.mobileNumber}
                      touched={touched.mobileNumber}
                    />
                  </Col>
                  <Col>
                    <AppTextInput
                      label="Board Name (Optional)"
                      id="boardName"
                      name="boardName"
                      type="text"
                      placeholder="Name to be used on the bar board, if different from your real name"
                      error={errors.boardName}
                      touched={touched.boardName}
                      maxLength={100}
                    />
                    <ValidationErrorTemplate
                      error={errors.boardName}
                      touched={touched.boardName}
                    />
                  </Col>
                  <Col>
                    <AppTextInput
                      label="Address 1 (Optional)"
                      id="address"
                      name="address"
                      type="text"
                      placeholder="Street Address"
                      error={errors.address}
                      touched={touched.address}
                      maxLength={100}
                    />
                    <ValidationErrorTemplate
                      error={errors.address}
                      touched={touched.address}
                    />
                  </Col>
                  <Col>
                    <AppTextInput
                      label="Address 2 (Optional)"
                      id="address2"
                      name="address2"
                      type="text"
                      maxLength={100}
                      error={errors.address2}
                      touched={touched.address2}
                    />
                    <ValidationErrorTemplate
                      error={errors.address2}
                      touched={touched.address2}
                    />
                  </Col>
                  <Col>
                    <AppTextInput
                      label="Suburb (Optional)"
                      id="suburb"
                      name="suburb"
                      type="text"
                      maxLength={60}
                      error={errors.suburb}
                      touched={touched.suburb || false}
                    />
                    <ValidationErrorTemplate
                      error={errors.suburb}
                      touched={touched.suburb}
                    />
                  </Col>
                  <Col className="pl-0 pr-0 pr-lg-2" lg={6}>
                    {' '}
                    <AppTextInput
                      label="City (Optional)"
                      id="city"
                      name="city"
                      maxLength={60}
                      type="text"
                      error={errors.city}
                      touched={touched.city || false}
                    />
                    <ValidationErrorTemplate
                      error={errors.city}
                      touched={touched.city}
                    />
                  </Col>
                  <Col className="pl-0 pr-0 pl-lg-2" lg={6}>
                    <AppTextInput
                      label="Post Code (Optional)"
                      id="postCode"
                      name="postCode"
                      type="text"
                      maxLength={4}
                      error={errors.postCode}
                      touched={touched.postCode || false}
                    />
                    <ValidationErrorTemplate
                      error={errors.postCode}
                      touched={touched.postCode}
                    />
                  </Col>
                  <Col>
                    <AppCheckboxInput
                      name="hasAcceptedTermsConditions"
                      type="checkbox"
                      touched={touched.hasAcceptedTermsConditions}
                      error={errors.hasAcceptedTermsConditions}
                    >
                      Accept terms & conditions
                    </AppCheckboxInput>
                    <ValidationErrorTemplate
                      error={errors.hasAcceptedTermsConditions}
                      touched={touched.hasAcceptedTermsConditions}
                    />{' '}
                  </Col>
                  <Col>
                    <AppCheckboxInput
                      name="hasAcceptedMarketingCommunications"
                      type="checkbox"
                      touched={touched.hasAcceptedMarketingCommunications}
                      error={errors.hasAcceptedMarketingCommunications}
                    >
                      Accept marketing information (Optional)
                    </AppCheckboxInput>
                    <ValidationErrorTemplate
                      error={errors.hasAcceptedMarketingCommunications}
                      touched={touched.hasAcceptedMarketingCommunications}
                    />{' '}
                  </Col>
                  <Col style={{marginBottom: '30px'}}>
                    <Recaptcha
                        sitekey={RECAPTCHA_SITE_KEY}
                        render="explicit"
                        onloadCallback={recaptchaLoaded}
                        verifyCallback={verifyCallback}
                        expiredCallback={expiredCallback}
                    />
                  </Col>
                  <small className="d-flex justify-content-center align-items-center">
                    <Image src={infoIconImg} />
                    <span className="pl-2">
                      This registration is for members only. If you want to
                      register your bar please contact Lion admin.
                    </span>
                  </small>
                </Row>
                <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between relative">
                  {loading ? (
                    <img
                      className="spinner spinner-position-dialog"
                      src={loadIcon}
                      alt="Loading..."
                    />
                  ) : null}
                  <Button
                    data-testid="submit-btn"
                    variant="primary"
                    type="submit"
                    disabled={!isValid || isSubmitting || isNotRecaptchaVerified}
                  >
                    Register
                  </Button>
                  {/* <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> */}
                </div>
              </fieldset>
              {registrationSuccess && (
                <Alert key="1" variant="primary">
                  {status}
                </Alert>
              )}
            </Form>
          )}
        </Formik>

        {formErrorFromServer && (
          <AlertStatus status={formErrorFromServer} isApiError={true} />
        )}
      </div>
    </div>
  );
};
