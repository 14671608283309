import { createStore, applyMiddleware, CombinedState, Store } from 'redux';
import logger from 'redux-logger';
import rootReducer, { AppState } from '../reducers/rootReducer';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { AppActions, Auth, Member, Common, LionAdmin } from '../actions/actions';
import { DefaultPagination } from 'src/shared/components/ui/data-table/PaginationDefaultConfig';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

export const initialState = {
  authData: {
    isAuth: false,
    isAuthLoading: false,
    isAuthError: false,
    isAgeVerified: false,
    authErrorMessage: '',
    selectedBarId: localStorage.getItem('selectedBarId')
      ? parseInt(localStorage.getItem('selectedBarId')!)
      : -1,
  },
  memberData: {
    memberList: [],
    showMemberDetails: false,
    totalMembers: 0,
    isMemberListLoading: false,
    isMemberListError: false,
    memberListErrorMessage: '',
    paginationHistory: {
      ...DefaultPagination,
    },
    memberDetails: {
      memberId: 0,
      firstName: '',
      lastName: '',
      address2: '',
      phone: '',
      email: '',
      boardName: '',
      pintLevel: 0,
      shirtSize: '',
      dob: '1970-01-01T00:00:00',
      address: '',
      suburb: '',
      city: '',
      postCode: '',
      dateRegistered: '1970-01-01T00:00:00',
      barId: 0,
      barName: '',
      isActive: false
    }
  },
  commonData: {
    contactUsLoading: false,
    isContactUsError: false,
    contactUsErrorMessage: '',
    employeeList:{
      active: [],
      inActive: []
    }
  },
  lionAdminData: {
    memberList: [],
    totalMembers: 0,
    showBarDetails: false,
    showBarCreate: false,
    showEmployeeDetails: false,
    showEmployeeCreate: false,
    searchBarPanelVisible: true,
    searchBarEmployeePanelVisible: false,
    barDetails: {
      sapCustomerId: '',
      name: '',
      address: '',
      address2: '',
      suburb: '',
      city: '',
      postCode: '',
      phone: '',
      email: '',
      contactFirstName: '',
      contactLastName: '',
      tier: '',
    },
    paginationHistory: {
      ...DefaultPagination,
    },
    memberDetails: {
      memberId: 0,
      firstName: '',
      lastName: '',
      address2: '',
      phone: '',
      email: '',
      boardName: '',
      pintLevel: 0,
      shirtSize: '',
      dob: '1970-01-01T00:00:00',
      address: '',
      suburb: '',
      city: '',
      postCode: '',
      dateRegistered: '1970-01-01T00:00:00',
      barId: 0,
      barName: '',
      isActive: false
    },
    memberAchievementDataLeft: [],
    memberAchievementDataRight: [],
    memberAchievementTotalItems: 0,
    memberListForBar: []
  },
};

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
function configureStore(): Store<
  CombinedState<{ authData: Auth; memberData: Member; commonData: Common; lionAdminData: LionAdmin; }>,
  AppActions
> {
  return createStore(
    persistedReducer,
    initialState,
    applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>, logger)
  );
}



const store = configureStore();

const persistor = persistStore(store)

export { store, persistor} ;
