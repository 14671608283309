import React, { useEffect, useState } from 'react';
import DataTable from '../../../shared/components/ui/data-table/DataTable';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import expandIcon from '../../../assets/icons-system-expand.svg';
import searchDefaultIcon from '../../../assets/icons/buttons-search-default.svg';
import searchActiveIcon from '../../../assets/icons/buttons-search-active.svg';
import closeIcon from '../../../assets/icons/icons-system-search-close.svg';
import { isMobile } from 'react-device-detect';
import './search-member.scss';
import {
  fetchMemberList,
  saveSelectedMember,
  saveMemberListPagination,
  showMemberDetails,
  fetchMemberDetails,
  setEditMode, clearMemberEdit, setDisplayingMember
} from '../../../actions/lionAdminAction';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { IMemberPayload } from './MemberDetailsPayload';
import AddMemberPintsModal from './AddMemberPintsModal';
import loadIcon from '../../../assets/global-element-spinner.svg';
import { DefaultPagination } from 'src/shared/components/ui/data-table/PaginationDefaultConfig';
import { DataTableDataType } from '../../../shared/types/dataTableDataType';
import Select, { ValueType } from 'react-select';
import { customStyles } from '../../../shared/utils/global-functions';
import editPencil from "../../../assets/icons/icons-system-edit.svg";

type OptionType = { label: string; value: string };

const SearchMember: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [pintModalShow, setPintModalShow] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [searchEnabled, setSearchEnabled] = useState(false);
  const statusArr = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
    { value: 'All', label: 'All' },
  ];

  const lionAdminData = useSelector<AppState, AppState['lionAdminData']>(
    (state) => state.lionAdminData
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchMemberList());
  }, [dispatch, searchEnabled]);

  function onPintModalShow(e: any, row: IMemberPayload) {
    e.stopPropagation();
    setPintModalShow(true);
    dispatch(saveSelectedMember(row));
  }

  function pintFormatter(cell: number, row: IMemberPayload) {
    if (row.pintLevel !== undefined) {
      return (
        <div
          className="d-flex justify-content-between pr-4"
          style={{ color: '#dc7300' }}
        >
          <div>{cell === null ? 0 : cell}</div>
          <img
            src={expandIcon}
            alt="Add Pint Icon"
          />
        </div>
      );
    }
    return <span>0</span>;
  }

  function dateRegisteredFormatter(cell: number, row: IMemberPayload) {
    if (row.dateRegistered !== undefined) {
      return (
        <div className="d-flex justify-content-between pr-4">
          <div>{cell}</div>
        </div>
      );
    }
    return <span />;
  }
  function sortCaretFormatter() {
    return <i className="pl-2 fa fa-sort" />;
  }

  const updateInputValue = (e: any) => {
    setInputValue(e!.target?.value);
  };

  const clearSearch = () => {
    setInputValue('');
    setPage(1);
    dispatch(
      saveMemberListPagination(
        1,
        lionAdminData.paginationHistory?.sizePerPage as number,
        '',
        lionAdminData.paginationHistory?.sortOrder as string,
        lionAdminData.paginationHistory?.columnName as string
      )
    );
    setSearchEnabled(false);
  };


  useEffect(() => {
    return () => {dispatch(setDisplayingMember({label: 'Active', value: 'Active'}))};
  }, [])

  const handleChange = (option: OptionType) => {
    dispatch(setDisplayingMember(option));
    setInputValue('');
    setPage(1);
    setSearchEnabled(false);
    dispatch(saveMemberListPagination(
      1,
      lionAdminData.paginationHistory?.sizePerPage as number,
      '',
      'asc',
      'memberId'
    ))
    dispatch(fetchMemberList());
  };

  const searchTable = () => {
    if (inputValue) {
      setPage(1);
      dispatch(
        saveMemberListPagination(
          1,
          lionAdminData.paginationHistory?.sizePerPage as number,
          inputValue,
          lionAdminData.paginationHistory?.sortOrder as string,
          lionAdminData.paginationHistory?.columnName as string
        )
      );
      setSearchEnabled(true);
    }
  };

  const onTableChange = (
    type: TableChangeType,
    newState: TableChangeState<DataTableDataType>
  ) => {
    setPage(newState.page);
    const pageData = lionAdminData.paginationHistory || DefaultPagination;
    if (type === 'pagination') {
      dispatch(
        saveMemberListPagination(
          (newState.page - 1) * newState.sizePerPage + 1,
          newState.sizePerPage,
          inputValue,
          pageData.sortOrder,
          pageData.columnName
        )
      );
      dispatch(fetchMemberList());
    }
    if (type === 'sort') {
      let order = '';
      if (pageData?.sortOrder === 'asc') {
        order = 'desc';
      } else if (pageData?.sortOrder === 'desc') {
        order = 'asc';
      }
      dispatch(
        saveMemberListPagination(
          (newState.page - 1) * newState.sizePerPage + 1,
          newState.sizePerPage,
          inputValue,
          order,
          newState.sortField as keyof IMemberPayload
        )
      );
      dispatch(fetchMemberList());
    }
  };

  function hidePintModalShow(): void {
    setPintModalShow(false);
  }

  function editImageFormatter() {
    return(
        <div style={{cursor: 'pointer', textAlign: 'center'}}>
          <img src={editPencil} style={{width: '18px', height: '18px'}}/>
        </div>
    )
  }

  const columns = [
    {
      dataField: 'memberId',
      text: 'Member ID',
      hidden: true,
      sort: true,
    },
    {
      dataField: '',
      text: 'Edit',
      formatter: editImageFormatter,
      headerStyle: () => {
        return { width: "100px" };
      },
      events: {
        onClick: (e: React.SyntheticEvent<EventTarget>, column: any, columnIndex: number, row: IMemberPayload, rowIndex: number) => {
          dispatch(saveSelectedMember(row));
          dispatch(fetchMemberDetails());
          dispatch(setEditMode(false));
          dispatch(clearMemberEdit());
          dispatch(showMemberDetails());
          window.scrollTo(0, 400);
        }
      }
    },
    {
      dataField: 'memberName',
      text: 'Name',
      sort: true,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'barName',
      text: 'Bar Name',
      sort: true,
      hidden: isMobile,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'boardName',
      text: 'Board Name',
      sort: true,
      hidden: isMobile,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'pintLevel',
      text: 'Pint Level',
      sort: true,
      style: { color: '#dc7300', cursor: 'pointer' },
      formatter: pintFormatter,
      hidden: isMobile,
      sortCaret: sortCaretFormatter,
      events: {
        onClick: (e: React.SyntheticEvent<EventTarget>, column: any, columnIndex: number, row: IMemberPayload, rowIndex: number) => onPintModalShow(e, row),
      }
    },
    {
      dataField: 'status',
      text: 'Active / Inactive',
      sort: true,
      hidden: isMobile,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'shirtSize',
      text: 'Shirt Size',
      sort: true,
      hidden: isMobile,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'dateRegistered',
      text: 'Date Registered',
      sort: true,
      formatter: dateRegisteredFormatter,
      hidden: isMobile,
      sortCaret: sortCaretFormatter,
    },
  ];


  return (
    <div className="member-dashboard-section">
      <div className="d-flex flex-column flex-md-row py-4 justify-content-center justify-content-md-between member-summary-section member-dashboard-padding">
        <div className="d-flex align-items-center ">
          <h3 className="pr-4">All Members</h3>
          <span>Total Members: {lionAdminData.overAllTotal}</span>
        </div>
      </div>
      {/* Search Section */}
      <div className="d-flex flex-column flex-md-row search-section member-dashboard-padding">
        <div className="form-group has-search col-12 col-lg-4 pl-0">
          {!searchEnabled && (
            <img
              className="search-icon"
              src={inputValue ? searchActiveIcon : searchDefaultIcon}
              alt="Search Icon"
              onClick={() => searchTable()}
            />
          )}
          <input
            type="text"
            className="form-control"
            placeholder="Search for members"
            value={inputValue}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              updateInputValue(event);
            }}
          />
        </div>
        {searchEnabled && !lionAdminData.isMemberListLoading && (
          <div className="d-flex align-items-center pl-4">
            <span className="gotham-bold pr-1">
              {lionAdminData?.totalMembers}
            </span>
            <span>result(s) found</span>
            <button className="btn btn-link px-2" onClick={() => clearSearch()}>
              <img
                className="close-icon pr-1"
                src={closeIcon}
                alt="Close Icon"
              />
              CLEAR
            </button>
          </div>
        )}
        <div className="displaying-container">
          <div className="select-field">
            <div className="label">Displaying</div>
            <Select
              styles={customStyles}
              defaultValue={statusArr[0]}
              value={lionAdminData.displayingMember}
              classNamePrefix='select'
              options={statusArr}
              onChange={(options: ValueType<OptionType>) =>
                handleChange(options as OptionType)
              }
            />
          </div>
        </div>
      </div>

        <div style={{position: 'relative'}}>
            <DataTable
              data={lionAdminData.memberList!}
              page={page}
              totalSize={lionAdminData.totalMembers!}
              columns={columns}
              sizePerPage={
                lionAdminData?.paginationHistory?.sizePerPage
                  ? lionAdminData?.paginationHistory?.sizePerPage
                  : 10
              }
              keyField="memberId"
              onTableChangeCallback={onTableChange}
            />
          {
            lionAdminData.isMemberListLoading && (
              <div className="center loading-section" style={{ position: 'absolute', top: '40%', left: '50%' }}>
                <img className="spinner" src={loadIcon} alt="Loading..." />
              </div>
            )
          }
          {lionAdminData.memberList &&
            lionAdminData.memberList?.length === 0 && !lionAdminData.isMemberListLoading && (
              <h2 className="member-dashboard-padding">No records found!</h2>
            )}
          {pintModalShow && (
            <AddMemberPintsModal
              hideModalShow={hidePintModalShow}
              isEditMemberForm={false}
            />
          )}
        </div>
    </div>
  );
};
export default SearchMember;
