import axios from 'axios';
import { performLogOut } from '../../actions/authAction';
import { store } from '../../store/configureStore';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers/rootReducer';
import { AppActions } from '../../actions/actions';

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err && err.response) {
      if (
        err.response.status === 401 &&
        localStorage.getItem('accessToken') !== null
      ) {
        (store.dispatch as ThunkDispatch<AppState, void, AppActions>)(
          performLogOut()
        );
      } else {
        throw err;
      }
    }
  }
);
