import BootstrapTable, {
  TableChangeState,
  TableChangeType,
  TableChangeHandler,
  ColumnDescription,
  SortOrder,
} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import React, { useEffect } from 'react';
import './data-table.scss';
import { DataTableDataType } from '../../../types/dataTableDataType';
import { IMemberPayload } from '../../../../features/dashboard/member-details/MemberDetailsPayload';

interface IDataTable {
  data: DataTableDataType[];
  page: number;
  sizePerPage: number;
  onTableChangeCallback: TableChangeHandler<DataTableDataType>;
  totalSize: number;
  columns: ColumnDescription[];
  keyField: string;
}

const DataTable: React.FC<IDataTable> = ({
  data,
  page,
  sizePerPage,
  onTableChangeCallback,
  totalSize,
  columns,
  keyField,
}) => {

  useEffect(() => {
    document.addEventListener('click', scrollToEnd);
    return () => document.removeEventListener('click', scrollToEnd);
  }, []);

  const scrollToEnd = (event: MouseEvent) => {
    if ((event.target as Element).id == 'pageDropDown') {
      const element = document.getElementById('footer');
      element!.scrollIntoView();
    }
  };

  const onTableChange = (
    type: TableChangeType,
    newState: TableChangeState<DataTableDataType>
  ) => {
    return onTableChangeCallback(type, newState);
  };

  const customPaginationTotal = (from: number, to: number, size: number) => {
    return (
      <div>
        <div className="react-bootstrap-table-pagination-total">
          <span className="gotham-bold">
            {from} - {to}
          </span>
          of {size} items
        </div>
        <div className="react-bs-table-sizePerPage-dropdown text-content">
          Items per page
        </div>
      </div>
    );
  };

  const paginationOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    alwaysShowAllBtns: true,
    paginationTotalRenderer: customPaginationTotal,
    showTotal: true,
    paginationSize: 2,
    pageStartIndex: 1,
    withFirstAndLast: false,
  };
  return (
    <div className="data-table">
      <BootstrapTable
        bootstrap4
        remote={{ sort: true, pagination: true }}
        classes="guinness-table"
        striped={true}
        bordered={false}
        tabIndexCell={true}
        keyField={keyField}
        data={data}
        columns={columns}
        pagination={paginationFactory(paginationOptions)}
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default DataTable;
