import React, { useEffect, useState } from 'react';
import { IModalConfig } from '../../../shared/types/modalInterface';
import EmployeeDetails from '../employee-details/EmployeeDetails';
import { CustomModal } from '../../../shared/components/ui/modal/modal';
import { MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/reducers/rootReducer';
import { hideModal } from 'src/actions/commonAction';
import { isMobile } from 'react-device-detect';

const EmployeeDetailsModal: React.FC = () => {
  const [showEmployeeDetailsModal, setShowEmployeeDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );

  useEffect(() => {
    if(commonData?.isOpenDialog && commonData.modalName === MODAL_DIALOG_NAMES.employeeDetails) {
      setShowEmployeeDetailsModal(true);
    } else {
      setShowEmployeeDetailsModal(false);
    }
  }, [commonData?.isOpenDialog])

  const employeeDetailsModalConfig: IModalConfig = {
    title: commonData?.logoElement,
    isImageTitle: true,
    showModal: true,
    modalSize: isMobile ? 'largeModal' : 'largeModal largeModalCenterAligned',
    closeModal: closeModal,
    body: <EmployeeDetails />,
  };
  function closeModal(): void {
    dispatch(hideModal(MODAL_DIALOG_NAMES.employeeDetails));
  }

  return (
    <div>
      {showEmployeeDetailsModal && <CustomModal config={employeeDetailsModalConfig}></CustomModal>}
    </div>
  );
};

export default EmployeeDetailsModal;