import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import validationSchema from './EmployeeCreateValidationSchema';
import {
  clearEmployeeCreate,
  createEmployeeDetails,
} from '../../../actions/lionAdminAction';
import {
  AppEmailInput,
  AppTextInput,
} from '../../../shared/components/ui/form-input/FormInput';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import Button from 'react-bootstrap/Button';
import loadIcon from '../../../assets/global-element-spinner.svg';
import Alert from 'react-bootstrap/Alert';
import CancelEmployeeRegistrationModal from './CancelEmployeeRegistrationModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { SearchInput } from '../../../shared/components/ui/search-input/SearchInput';
import './employee-create.scss';

const EmployeeCreate: React.FC = () => {
  const [clear, setClear] = useState(false);

  const [
    cancelRegistrationModalShow,
    setCancelRegistrationModalShow,
  ] = useState<boolean>(false);

  const [employeeDetails, setEmployeeDetails] = useState({
    barId: '',
    email: '',
    firstName: '',
    lastName: '',
  });

  const dispatch = useDispatch();

  const isOpenDialog = useSelector<AppState, AppState['commonData']['isOpenDialog']>(
    (state) => state.commonData.isOpenDialog
  );

  const lionAdminData = useSelector<AppState, AppState['lionAdminData']>(
    (state) => state.lionAdminData
  );

  useEffect(() => {
    if (lionAdminData.isCreateEmployeeSuccess) {
      clearForm();
    }
  }, [lionAdminData.isCreateEmployeeSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearEmployeeCreate());
    };
  }, [dispatch]);

  function hideCancelRegistrationModalShow(): void {
    setCancelRegistrationModalShow(false);
  }

  function onCancelRegistrationModalShow() {
    setCancelRegistrationModalShow(true);
  }

  function clearForm() {
    setEmployeeDetails({
      barId: '',
      email: '',
      firstName: '',
      lastName: '',
    });
    setClear(true);
  }

  function submitCancel(): void {
    clearForm();
  }

  return (
    <div className={isOpenDialog ? "bar-employee-create-dashboard-section employee-create-dialog" : "bar-employee-create-dashboard-section"}>
      <h3 className="bar-employee-create-dashboard-section__heading">
        REGISTER EMPLOYEE
      </h3>
      <Formik
        initialValues={employeeDetails!}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={async (values) => {
          setEmployeeDetails({
            barId: values.barId,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
          });
          dispatch(createEmployeeDetails(values));
        }}
      >
        {({
            setFieldValue,
            setFieldTouched,
            resetForm,
            errors,
            touched,
            isValid,
          }) => {
          return (
            <Form>
              <div className="bar-employee-create-dashboard-section__form">
                <div className="bar-employee-create-dashboard-section__form-section">
                  <div className="bar-employee-create-dashboard-section__field">
                    <SearchInput
                      label="Select Bar"
                      id="barId"
                      name="barId"
                      error={errors.barId}
                      touched={touched.barId}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      setClear={setClear}
                      placeholder="Search for your local"
                      clear={clear}
                    />
                    <ValidationErrorTemplate
                      error={errors.barId}
                      touched={touched.barId}
                    />
                  </div>
                  <div className="bar-employee-create-dashboard-section__field">
                    <AppTextInput
                      label="First Name"
                      id="firstName"
                      name="firstName"
                      data-testid="employee-create-first-name"
                      type="text"
                      error={errors.firstName}
                      touched={touched.firstName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      data-testid="test-id-error"
                      error={errors.firstName}
                      touched={touched.firstName}
                    />
                  </div>
                  <div className="bar-employee-create-dashboard-section__field">
                    <AppTextInput
                      label="Last Name"
                      id="lastName"
                      name="lastName"
                      data-testid="employee-create-last-name"
                      type="text"
                      error={errors.lastName}
                      touched={touched.lastName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                  </div>
                  <div className="bar-employee-create-dashboard-section__field">
                    <AppEmailInput
                      label="Email address"
                      id="email"
                      name="email"
                      data-testid="employee-create-email"
                      type="email"
                      maxLength={320}
                      error={errors.email}
                      touched={touched.email}
                    />
                    <ValidationErrorTemplate
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>
                </div>
                <div className="bar-employee-create-dashboard-section__buttons">
                  <Button
                    variant="primary"
                    type="submit"
                    className="register-button"
                    data-testid="register-button"
                    disabled={!isValid || lionAdminData.isCreateEmployeeLoading}
                  >
                    Register
                  </Button>

                  <Button
                    className="btn btn-primary btn-outline-secondary cancel-button"
                    type="button"
                    onClick={() => onCancelRegistrationModalShow()}
                    style={{ display: !isValid ? 'none' : 'block' }}
                  >
                    Cancel
                  </Button>
                  {lionAdminData.isCreateEmployeeLoading ? (
                    <div className="loader loading-icon">
                      <img
                        className="spinner"
                        src={loadIcon}
                        alt="Loading..."
                      />
                    </div>
                  ) : null}
                </div>
                {lionAdminData.isCreateEmployeeSuccess ? (
                  <Alert variant="success">
                    {lionAdminData.createEmployeeMessage}
                  </Alert>
                ) : null}
                {lionAdminData.isCreateEmployeeError ? (
                  <Alert variant="danger">
                    {lionAdminData.createEmployeeMessage}
                  </Alert>
                ) : null}
                {cancelRegistrationModalShow && (
                  <CancelEmployeeRegistrationModal
                    hideModalShow={hideCancelRegistrationModalShow}
                    onSubmitCancel={submitCancel}
                    onResetForm={resetForm}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmployeeCreate;
