import React, {useState} from 'react';
import { MemberRewardCard } from './MemberRewardCard';
import './member-rewards.scss';

const Pints100InProgress = [
  "Become an Official Member! See your name up on your local pub's 1759 board!",
  "You received a free Guinness T-Shirt!"
]

const Pints100Achieved = [
  "You are an Official Member! See your name up on your local pub's 1759 board!",
  "You received a free Guinness T-Shirt!"
]

const Pints500InProgress = [
  "Claim your 1759 Club Pin",
  "Start receiving your Annual St Patrick's Day Delivery"
]

const Pints500Achieved = [
  "You received 1759 Club Pin",
  "You will receive your Annual St Patrick's Day Delivery"
]

const Pints1000InProgress = [
  "Claim your 1759 Club Vest",
  "Continue receiving your Annual St Patrick's Day Delivery"
]

const Pints1000Achieved = [
  "You received 1759 Club Vest",
  "You will continue receiving your Annual St Patrick's Day Delivery"
]

const Pints2500InProgress = [
  "Claim your 1759 Club Jacket, which recognises the most elite status of the club!",
  "Continue receiving your Annual St Patrick's Day Delivery",
  "You are a legend!"
]

const Pints2500Achieved = [
  "You received 1759 Club Jacket, which recognises the most elite status of the club!",
  "You will continue receiving your Annual St Patrick's Day Delivery",
  "You are a legend!"
]

export const MemberRewards: React.FC<{pints?: number}> = (props) => {

  const [pintLevel, setPintLevel] = useState(props.pints || 0);

  return(
    <div className="member-rewards-container">
      <div className="member-rewards-container__heading">MY REWARDS</div>
      <div className="divider-center"/>
      {
        pintLevel < 2500
          ? <div className="member-rewards-container__subHeading">In Progress</div>
          : null
      }

      {
        pintLevel < 100
          ? <div>
              <MemberRewardCard pintLevel={100} pintRewards={Pints100InProgress} achieved={false} nextTier={true}/>
              <MemberRewardCard pintLevel={500} pintRewards={Pints500InProgress} achieved={false}/>
              <MemberRewardCard pintLevel={1000} pintRewards={Pints1000InProgress} achieved={false}/>
              <MemberRewardCard pintLevel={2500} pintRewards={Pints2500InProgress} achieved={false}/>
            </div>
          : pintLevel < 500
          ? <div>
              <MemberRewardCard pintLevel={500} pintRewards={Pints500InProgress} achieved={false} nextTier={true}/>
              <MemberRewardCard pintLevel={1000} pintRewards={Pints1000InProgress} achieved={false}/>
              <MemberRewardCard pintLevel={2500} pintRewards={Pints2500InProgress} achieved={false}/>
            </div>
          : pintLevel < 1000
          ? <div>
              <MemberRewardCard pintLevel={1000} pintRewards={Pints1000InProgress} achieved={false} nextTier={true}/>
              <MemberRewardCard pintLevel={2500} pintRewards={Pints2500InProgress} achieved={false}/>
            </div>
          : pintLevel < 2500
          ? <div>
              <MemberRewardCard pintLevel={2500} pintRewards={Pints2500InProgress} achieved={false} nextTier={true}/>
            </div>
          : null
      }
      {
        pintLevel > 100
          ? <div className="member-rewards-container__subHeading" style={{color: '#b9965b', marginTop: '60px'}}>Achieved</div>
          : null
      }

      {
        pintLevel >= 2500
        ? <div>
            <MemberRewardCard pintLevel={2500} pintRewards={Pints2500Achieved} achieved={true}/>
            <MemberRewardCard pintLevel={1000} pintRewards={Pints1000Achieved} achieved={true}/>
            <MemberRewardCard pintLevel={500} pintRewards={Pints500Achieved} achieved={true}/>
            <MemberRewardCard pintLevel={100} pintRewards={Pints100Achieved} achieved={true}/>
          </div>
        : pintLevel >= 1000
        ? <div>
            <MemberRewardCard pintLevel={1000} pintRewards={Pints1000Achieved} achieved={true}/>
            <MemberRewardCard pintLevel={500} pintRewards={Pints500Achieved} achieved={true}/>
            <MemberRewardCard pintLevel={100} pintRewards={Pints100Achieved} achieved={true}/>
          </div>
        : pintLevel >= 500
        ? <div>
            <MemberRewardCard pintLevel={500} pintRewards={Pints500Achieved} achieved={true}/>
            <MemberRewardCard pintLevel={100} pintRewards={Pints100Achieved} achieved={true}/>
          </div>
        : pintLevel >= 100
        ? <div>
             <MemberRewardCard pintLevel={100} pintRewards={Pints100Achieved} achieved={true}/>
          </div>
        : null
      }

    </div>
  )
}