import jwt_decode from 'jwt-decode';
import { CSSProperties } from 'react';
import { IJwtResponse } from '../types/jwtResponse';

const decodeToken = (): IJwtResponse | null => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const decodeToken: IJwtResponse = jwt_decode(token);
    return decodeToken;
  }
  return null;
};

export const getUserId = (): string => {
  const token: IJwtResponse | null = decodeToken();
  if (token) {
    return token.userId;
  }
  return '';
}

export const getExpiryTime = (): number => {
  const token: IJwtResponse | null = decodeToken();
  if (token) {
    return token.exp;
  }
  return 0;
};

export const getUserName = (): string => {
  const token: IJwtResponse | null = decodeToken();
  if (token) {
    return token.sub;
  }
  return '';
};

export const getUserRole = (): string => {
  const token: IJwtResponse | null = decodeToken();
  if (token) {
    return token?.role;
  }
  return '';
};

export const getUserFullName = (): string => {
  const token: IJwtResponse | null = decodeToken();
  if (token) {
    return `${token.given_name}   ${token.family_name}`;
  }
  return '';
};

export const getBarName = (): string => {
  const token: IJwtResponse | null = decodeToken();
  if (token) {
    return token.barname;
  }
  return '';
};

export const getBars = (): string => {
  const token: IJwtResponse | null = decodeToken();
  if (token) {
    return token.barcount;
  }
  return '';
}

export const customStyles = {
  control: (base: CSSProperties, state: any) => ({
    ...base,
    borderColor: state.isFocused ? '#00d78c !important' : '#929396',
    boxShadow: state.isFocused ? 'none !important' : 'none !important',
    '&:focus': {
      borderColor: '#00d78c',
      boxShadow: 'none',
    },
  }),
  option: (base: CSSProperties, state: any) => ({
    ...base,
    '&:hover': {
      backgroundColor: 'rgba(0, 215, 140, 0.25)',
      fontWeight: '700',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 215, 140, 0.25)',
      fontWeight: '700',
    },
    backgroundColor: state.isSelected
      ? 'rgba(0, 215, 140, 0.5) !important'
      : 'inherit',
    color: state.isSelected ? '#0a0406 !important' : 'inherit',
  }),
};
