import React from 'react';
import pintLogo from '../../../assets/guinness-glass-small.png';
import './member-reward-card.scss';


export const MemberRewardCard: React.FC<{ pintLevel: number, pintRewards: string[], achieved: boolean, nextTier?: boolean }> = (props) => {
  return (
    <div className='member-reward-card-container' style={{ backgroundColor: props.achieved ? '#b9965b' : 'white' }}>
      <div className='member-reward-card-container__tier-image-and-pints' data-testid='tier-image-and-pints'>
        {
          props.nextTier && <div className="tier">YOUR NEXT TIER</div>
        }
        <div className='image-and-pints'>
          <img className='pint-glass-logo' src={pintLogo} />
          <div className='pint-level-points'>{props.pintLevel} <span>PINTS</span></div>
        </div>
      </div>

      <div className="member-reward-card-container__rewards">
        <div className='pint-rewards'>REWARDS</div>
        <ul>
          {
            props.pintRewards.map((rewards, idx) => {
              return (
                <li key={idx} className={props.achieved ? 'achieved' : ''}>{rewards}</li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
};