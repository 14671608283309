import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { showModal } from '../../actions/commonAction';
import { MODAL_DIALOG_NAMES } from '../../shared/configs/appConfigs';
import registerClub from '../../assets/register-club.png';
import './register-club.scss';


export const RegisterClub: React.FC = () => {

  const dispatch = useDispatch();
  const onClickRegister = () => {
    dispatch(showModal(MODAL_DIALOG_NAMES.registration));
  };

  return(
    <div className="register-club-container">
      <div className="register-club-container__club-image">
        <img src={registerClub}/>
      </div>
      <div className="register-club-container__club-text">
        Register below to find out more about the 1759 Club.
      </div>
        <Button
          className="register-club-container__club-button"
          variant="primary"
          onClick={() => onClickRegister()}
        >
          Register
        </Button>
    </div>
  )
}