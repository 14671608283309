import React, { useEffect, useState } from 'react';
import { IModalConfig } from '../../shared/types/modalInterface';
import { RegistrationForm } from './RegistrationForm';
import { CustomModal } from '../../shared/components/ui/modal/modal';
import { MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/reducers/rootReducer';
import { hideModal } from 'src/actions/commonAction';

const RegistrationModal: React.FC = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const dispatch = useDispatch();
  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );
  
  useEffect(() => {
    if(commonData?.isOpenDialog && commonData.modalName === MODAL_DIALOG_NAMES.registration) {
      setShowRegistrationModal(true);
    } else {
      setShowRegistrationModal(false);
    }
  }, [commonData?.isOpenDialog])

  const registerModalconfig: IModalConfig = {
    title: commonData?.logoElement,
    isImageTitle: true,
    showModal: true,
    modalSize: 'largeModal',
    closeModal: closeModal,
    body: <RegistrationForm />,
  };
    function closeModal(): void {
      dispatch(hideModal(MODAL_DIALOG_NAMES.registration));
  }

  return (
    <div>
      {showRegistrationModal && <CustomModal config={registerModalconfig}></CustomModal>}
    </div>
  );
};

export default RegistrationModal;
