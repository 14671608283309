import React, { useEffect, useState } from 'react';
import categoryPints from '../../assets/icons-category-pints.svg';
import categoryTalk from '../../assets/icons-category-talk.svg';
import categoryReward from '../../assets/icons-category-reward.svg';
import categoryHarp from '../../assets/icons-category-harp.svg';
import './view-club.scss';
import { ContentfulClientConfigs } from '../../shared/configs/ContentfulClientConfigs';
import { IViewClubContentful } from './ViewClub.interface';
import { ClubRewards } from './ClubRewards';
import { GetSocial } from './GetSocial';
import { TrackPinter } from './TrackPinter';

export const ViewClub: React.FC = () => {
  const [clubContent, setClubContent] = useState<IViewClubContentful>();

  useEffect(() => {
    ContentfulClientConfigs.getEntries<IViewClubContentful>({
      content_type: 'aboutTheClub',
    }).then(({ items }) => {
      setClubContent(items[0].fields);
    });
  }, []);
  return (
    <div className="d-flex flex-column club-container justify-content-center">
      {/* About the club */}
      <div className="d-flex flex-column align-items-center justify-content-center page-first-section-padding about-container">
        <div className="about-section-bg"/>
        <div className="container-md text-center">
          <h1 className="about-the-club-heading">About the club</h1>
          <div className="divider"/>
          <p>{clubContent?.aboutUsHeroBannerBody}</p>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start justify-content-md-between category-section">
          <div className="d-flex flex-column justify-content-center inner-container">
            <div className="category-image-container">
              <img src={categoryPints} alt="Category Pints" />
            </div>
            <p>{clubContent?.aboutUsHeroBannerSteps[0].fields.description}</p>
          </div>
          {/* Reward section */}
          <div className="d-flex flex-column justify-content-center inner-container">
            <div className="category-image-container">
              <img src={categoryReward} alt="Category Reward" />
            </div>
            <p>{clubContent?.aboutUsHeroBannerSteps[1].fields.description}</p>
          </div>

          {/* Talk Section */}
          <div className="d-flex flex-column justify-content-center inner-container">
            <div className="category-image-container">
              <img src={categoryTalk} alt="Category Talk" />
            </div>
            <p>{clubContent?.aboutUsHeroBannerSteps[2].fields.description}</p>
          </div>

          {/* Harp Section */}
          <div className="d-flex flex-column justify-content-center inner-container">
            <div className="category-image-container">
              <img src={categoryHarp} alt="Category Harp" />
            </div>
            <p>
              <span>
                {clubContent?.aboutUsHeroBannerSteps[3].fields.description}
              </span>
            </p>
          </div>
        </div>
      </div>
      {clubContent && clubContent.clubRewardsList && (
        <ClubRewards clubRewardsList={clubContent?.clubRewardsList} />
      )}
      <TrackPinter />
      {clubContent?.instagramTitle && (
        <GetSocial
          instagramBodyCopy={clubContent?.instagramBodyCopy}
          instagramTitle={clubContent?.instagramTitle}
        />
      )}
    </div>
  );
};
