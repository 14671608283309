import React, { useEffect, useState } from 'react';
import { IModalConfig } from '../../../shared/types/modalInterface';
import MemberDetails from './MemberDetails';
import { CustomModal } from '../../../shared/components/ui/modal/modal';
import { MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/reducers/rootReducer';
import { hideModal } from 'src/actions/commonAction';
import { setEditMode } from '../../../actions/memberAction';

const MemberDetailsModal: React.FC = () => {
  const [showMemberDetailsModal, setShowMemberDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );

  useEffect(() => {
    if(commonData?.isOpenDialog && commonData.modalName === MODAL_DIALOG_NAMES.memberDetails) {
      setShowMemberDetailsModal(true);
    } else {
      setShowMemberDetailsModal(false);
    }
  }, [commonData?.isOpenDialog])

  const memberDetailsModalConfig: IModalConfig = {
    title: commonData?.logoElement,
    isImageTitle: true,
    showModal: true,
    modalSize: 'largeModal',
    closeModal: closeModal,
    body: <MemberDetails />,
  };

  function closeModal(): void {
    dispatch(hideModal(MODAL_DIALOG_NAMES.memberDetails));
    dispatch(setEditMode(false));
  }

  return (
    <div>
      {showMemberDetailsModal && <CustomModal config={memberDetailsModalConfig}></CustomModal>}
    </div>
  );
};

export default MemberDetailsModal;