import React from 'react';
import { CustomModal } from 'src/shared/components/ui/modal/modal';
import { IModalConfig } from 'src/shared/types/modalInterface';
import AddMemberPints from './AddMemberPints';

const AddMemberPintsModal: React.FC<{
  hideModalShow: () => void;
  isEditMemberForm: boolean;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldValue?: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => void;
}> = (
  props
) => {
  const AddMemberPintsModalconfig: IModalConfig = {
    title: 'Add pints',
    showModal: true,
    modalSize: 'smallModal',
    closeModal: hideModal,
    body:
      <AddMemberPints
      isEditMemberForm={props.isEditMemberForm}
      hideModalShow={props.hideModalShow}
      setFieldTouched={props.setFieldTouched}
      setFieldValue={props.setFieldValue}
      />,
  };

  function hideModal() {
    props.hideModalShow();
  }
  return (
    <div>
      <CustomModal config={AddMemberPintsModalconfig}></CustomModal>
    </div>
  );
};

export default AddMemberPintsModal;
