import React from 'react';
import { CustomModal } from 'src/shared/components/ui/modal/modal';
import { IModalConfig } from 'src/shared/types/modalInterface';
import { IMemberDetailsPayload } from './MemberDetailsPayload';

const SubmitEditEmployeeModal: React.FC<{
  hideModalShow: () => void;
  onSubmit: () => void;
  values: IMemberDetailsPayload;
  memberDetails: IMemberDetailsPayload;
}> = (props) => {

  const submitEditMemberConfig: IModalConfig = {
    title: 'Confirm Submit',
    showModal: true,
    modalSize: 'smallModal',
    closeModal: hideModal,
    body: generateMessage(),
    primaryBtnText: 'Yes',
    secondaryBtnText: 'No',
    submitModal: submitValues,
  };

  function generateMessage() {
    if (props.values.isActive !== props.memberDetails.isActive) {
      if (props.values.isActive) {
        return (
          <p style={{ textAlign: 'center' }}>Are you sure you want to activate {props.memberDetails.firstName} {props.memberDetails.lastName}?</p>
        )
      } else {
        return (
          <p style={{ textAlign: 'center' }}>Are you sure you want to deactivate {props.memberDetails.firstName} {props.memberDetails.lastName}?</p>
        )
      }
    }
    return (
      <p style={{ textAlign: 'center' }}>Are you sure you want to update details?</p>
    )
  }

  function hideModal() {
    props.hideModalShow();
  }

  function submitValues() {
    props.onSubmit();
  }

  return (
    <div>
      <CustomModal config={submitEditMemberConfig}/>
    </div>
  );
};

export default SubmitEditEmployeeModal;
