import React, { useEffect, useState } from 'react';
import './about-guinness.scss'
import stJamesGate from '../../assets/st-james-gate.png'
import arthurSign from '../../assets/arthur-sign.png'
import { IAboutGuinness } from './AboutGuinnessPage.interface';
import { ContentfulClientConfigs } from '../../shared/configs/ContentfulClientConfigs';


export const AboutGuinness: React.FC<IAboutGuinness> = (props) => {

  const [aboutGuinnessImageUrl, setAboutGuinnessImageUrl] = useState('');
  useEffect(() => {
    ContentfulClientConfigs.getAsset('5x2leKqRvFspzvl2wGrQd8').then((asset) => {
      setAboutGuinnessImageUrl(`${asset.fields.file.url}`);
    });
  }, []);

  return(
        <div className="about-guinness-container">
          <div className="about-guinness-container__content">
            <div className="about-guinness-container__content-heading">{props.aboutGuinnessHeading}</div>
            <div className='divider-center'/>
            <p className="about-guinness-container__content-text">{props.aboutGuinnessDescription}</p>
            <div className="about-guinness-container__content-image-and-text">
              <div className="st-james-image">
                <img src={aboutGuinnessImageUrl}/>
              </div>
              <div className="st-james-text">
                <p className="first-para">{props.aboutGuinnessDetailedDescription1}</p>
                <div className='divider-center'/>
                <p className="second-para">{props.aboutGuinnessDetailedDescription2}</p>
                <div className="arthur-image">
                  <img src={arthurSign}/>
                </div>
              </div>
            </div>
          </div>

        </div>
    )
}