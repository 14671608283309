import React from 'react';
import SearchBar from './SearchBar';
import BarDetails from './BarDetails';
import BarCreate from './BarCreate';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import {
  clearBarCache,
  hideBarCreate,
  hideBarDetails,
  setSearchBarEmployeePanel,
  setSearchBarMemberPanel,
  clearMemberListForBar
} from '../../../actions/lionAdminAction';
import { hideMemberDetails } from '../../../actions/memberAction';
import { setSelectedBarId } from '../../../actions/authAction';
import chevronRight from '../../../assets/icons/icons-system-chevron-right.svg';
import chevronUp from '../../../assets/icons/icons-system-chevron-up.svg';
import './bar-element.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { isMobile } from 'react-device-detect';
import { MemberSearchForBar } from "../lion-admin-details/MemberSearchForBar";

const BarElement: React.FC = () => {
  const dispatch = useDispatch();

  const displaySearchBar = () => {
    dispatch(hideBarDetails());
    dispatch(hideBarCreate());
    dispatch(setSelectedBarId(-1));
    dispatch(clearBarCache());
    dispatch(clearMemberListForBar());
    dispatch(hideMemberDetails());
  };

  const switchToSearchEmployee = () => {
    dispatch(hideBarDetails());
    dispatch(hideBarCreate());
    dispatch(setSelectedBarId(-1));
    dispatch(setSearchBarEmployeePanel());
    dispatch(clearBarCache());
  };

  const switchToSearchMember = () => {
    dispatch(hideBarDetails());
    dispatch(hideBarCreate());
    dispatch(setSelectedBarId(-1));
    dispatch(setSearchBarMemberPanel());
    dispatch(clearBarCache());
  };

  const showBarDetails = useSelector<
    AppState,
    AppState['lionAdminData']['showBarDetails']
  >((state) => state.lionAdminData.showBarDetails);

  const showBarCreate = useSelector<
    AppState,
    AppState['lionAdminData']['showBarCreate']
  >((state) => state.lionAdminData.showBarCreate);

  return (
    <div className="bar-element-dashboard-section">
      {
        !showBarDetails && !showBarCreate && <>
          <div
            className="bar-element-dashboard-section__heading"
            data-testid="bar-element-heading-section"
          >
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span className="heading-bar-1">SEARCH &nbsp;</span>
                <span className="heading-bar-2">BAR</span>
                <img className="heading-icon" src={chevronUp}/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={switchToSearchEmployee}>
                  Employee Search
                </Dropdown.Item>
                {
                  !isMobile &&  <Dropdown.Item onClick={switchToSearchMember}>
                    Member Search
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <SearchBar />
        </>
      }

      <div
        className="bar-element-dashboard-section-content"
        data-testid="bar-element-content-section"
      >
        {(showBarDetails || showBarCreate) && (
          <div
            onClick={displaySearchBar}
            className="bar-element-dashboard-section-content__link"
          >
            <img className="back-to-search-icon" src={chevronRight}/>
            {showBarDetails && (
              <span className="back-link">Back</span>
            )}
            {showBarCreate && (
              <span className="back-link">Back</span>
            )}
          </div>
        )}
        {showBarDetails && <BarDetails />}
        {showBarDetails && isMobile && <MemberSearchForBar/>}
        {showBarCreate && <BarCreate />}
      </div>
    </div>
  );
}

export default BarElement;
