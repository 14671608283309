import { Dispatch } from 'redux';
import { AppActions } from './actions';
import { IContactUsRequestPayload } from '../features/contact-us/ContactUsForm';
import axios from 'axios';
import { BASE_API_URL } from '../shared/configs/appConfigs';
import { ContentfulClientConfigs } from 'src/shared/configs/ContentfulClientConfigs';
import { store } from '../store/configureStore';
import _ from 'underscore';

export const CONTACT_US_STARTED = 'CONTACT_US_STARTED';
export const CONTACT_US_SUCCEEDED = 'CONTACT_US_SUCCEEDED';
export const CONTACT_US_FAILED = 'CONTACT_US_FAILED';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const GET_CONTENTFUL_LOGO_FAILED = 'GET_CONTENTFUL_LOGO_FAILED';
export const GET_CONTENTFUL_LOGO_SUCCEEDED = 'GET_CONTENTFUL_LOGO_SUCCEEDED';
export const GET_CONTENTFUL_LOGO_STARTED = 'GET_CONTENTFUL_LOGO_STARTED';

async function submitAsyncContactUs(
  dispatch: Dispatch<AppActions>,
  contactUsRequestPayload: IContactUsRequestPayload
) {
  try {
    await axios.post(
      `${BASE_API_URL}contact-us/contact`,
      contactUsRequestPayload
    );
    dispatch({
      type: CONTACT_US_SUCCEEDED,
      payload: {
        contactUsMessage: 'Request is submitted successfully',
      },
    });
  } catch (err) {
    dispatch({
      type: CONTACT_US_FAILED,
      payload: {
        contactUsMessage: 'Server error. Please try again.',
      },
    });
  }
}

export const submitContactUs = (
  contactUsRequestPayload: IContactUsRequestPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: CONTACT_US_STARTED,
    });
    return submitAsyncContactUs(dispatch, contactUsRequestPayload);
  };
};

export const CLEAR_CONTACT_US = 'CLEAR_CONTACT_US';
export const clearContactUs = (): AppActions => {
  return {
    type: CLEAR_CONTACT_US,
  };
};

export const showModal = (modalName: string) => {
  return {
    type: SHOW_MODAL,
    payload: {
      modalName: modalName,
    },
  };
};

export const hideModal = (modalName: string) => {
  return {
    type: HIDE_MODAL,
    payload: {
      modalName: modalName,
    },
  };
};

async function getAsyncDarkLogoFromContentful(dispatch: Dispatch<AppActions>) {
  try {
    const items: any = await ContentfulClientConfigs.getEntries({
      content_type: 'guinnessLogoDark',
    });
    dispatch({
      type: GET_CONTENTFUL_LOGO_SUCCEEDED,
      payload: {
        logoElement: `https:${items.includes.Asset[0].fields.file.url}`,
      },
    });
  } catch (err) {
    return {
      type: GET_CONTENTFUL_LOGO_FAILED,
      payload: {
        logoErrorMessage: 'Server error. Please try again.',
      },
    };
  }
}
export const getDarkLogoFromContentful = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: GET_CONTENTFUL_LOGO_STARTED,
    });
    return getAsyncDarkLogoFromContentful(dispatch);
  };
};

export async function fetchAsyncEmployeeList(dispatch: Dispatch<AppActions>) {
  try {
    const selectedBarId = store.getState().authData.selectedBarId;
    const res = await axios.get(
      `${BASE_API_URL}bar/${selectedBarId}/employee/list`
    );
    const adminEmployee = _.filter(res.data.active, (item) => {return item.role === 'BarAdmin'});
    const alteredEmployeeList = _.reject(res.data.active, (item) => {return item.role === 'BarAdmin'});
    alteredEmployeeList.unshift(...adminEmployee);
    res.data.active = alteredEmployeeList;
    dispatch({
      type: FETCH_EMPLOYEE_LIST_SUCCEEDED,
      payload: {
        employeeList: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_EMPLOYEE_LIST_FAILED,
    });
  }
}

export const FETCH_EMPLOYEE_LIST_STARTED = 'FETCH_EMPLOYEE_LIST_STARTED';
export const FETCH_EMPLOYEE_LIST_SUCCEEDED = 'FETCH_EMPLOYEE_LIST_SUCCEEDED';
export const FETCH_EMPLOYEE_LIST_FAILED = 'FETCH_EMPLOYEE_LIST_FAILED';
export const fetchEmployeeList = (): ((
  dispatch: Dispatch
) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_EMPLOYEE_LIST_STARTED,
    });
    return fetchAsyncEmployeeList(dispatch);
  };
};
