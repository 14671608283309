import React from 'react';
import { Formik, Form } from 'formik';
import {
  AppEmailInput,
  AppTextInput,
  AppTextAreaInput,
} from '../../shared/components/ui/form-input/FormInput';
import ValidationErrorTemplate from '../../shared/components/ui/validation-error/ValidationError';
import validationSchema from './ContactUsValidationSchema';
import Button from 'react-bootstrap/Button';
import { submitContactUs } from '../../actions/commonAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers/rootReducer';
import Alert from 'react-bootstrap/Alert';
import loadIcon from '../../assets/global-element-spinner.svg';
import {Col} from "react-bootstrap";
import Recaptcha from "react-recaptcha";

export interface IContactUsRequestPayload {
  firstName: string;
  lastName: string;
  email: string;
  contactMessage: string;
}

const RECAPTCHA_SITE_KEY = '6Lc0RyMbAAAAAP78tgUvbcvT6ccmtayNbO_0OgQa';

export const ContactUsForm: React.FC = () => {

  const [isNotRecaptchaVerified, setIsNotRecaptchaVerified] = React.useState(true);

  const initFormValues: IContactUsRequestPayload = {
    email: '',
    firstName: '',
    lastName: '',
    contactMessage: '',
  };

  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );

  const dispatch = useDispatch();

    const recaptchaLoaded = () => {
        console.log("recaptcha Loaded");
    }

    const verifyCallback = (response: any) => {
        if (response) {
            setIsNotRecaptchaVerified(false);
        }
    }

    const expiredCallback = () => {
        setIsNotRecaptchaVerified(true);
    }

  return (
    <div className="contact-us-form">
      <h3 data-testid="contact-us-heading" className="heading">
        GET IN TOUCH
      </h3>
      <Formik
        initialValues={initFormValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values, { resetForm }) => {
          dispatch(submitContactUs(values));
          resetForm();
        }}
      >
        {({ values, errors, touched, isValid, isSubmitting }) => (
          <Form autoComplete="off">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <AppTextInput
                  label="First Name"
                  data-testid="contact-us-first-name"
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  error={errors.firstName}
                  touched={touched.firstName}
                  maxLength={50}
                />
                <ValidationErrorTemplate
                  error={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
              <div>
                <AppTextInput
                  label="Last Name"
                  data-testid="contact-us-last-name"
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  error={errors.lastName}
                  touched={touched.lastName}
                  maxLength={50}
                />
                <ValidationErrorTemplate
                  error={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
              <div>
                <AppEmailInput
                  label="Email address"
                  data-testid="contact-us-email"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  maxLength={320}
                  error={errors.email}
                  touched={touched.email}
                />
                <ValidationErrorTemplate
                  error={errors.email}
                  touched={touched.email}
                />
              </div>
              <div>
                <AppTextAreaInput
                  label="How can we help?"
                  data-testid="contact-us-contact-message"
                  id="contactMessage"
                  name="contactMessage"
                  placeholder="Message"
                  error={errors.contactMessage}
                  touched={touched.contactMessage}
                  maxLength={500}
                >
                  <div className="text-area-chars">
                    {values.contactMessage ? values.contactMessage.length : 0}
                    /500
                  </div>
                </AppTextAreaInput>

                <ValidationErrorTemplate
                  error={errors.contactMessage}
                  touched={touched.contactMessage}
                />
              </div>
                <Col style={{marginBottom: '30px'}}>
                    <Recaptcha
                        sitekey={RECAPTCHA_SITE_KEY}
                        render="explicit"
                        onloadCallback={recaptchaLoaded}
                        verifyCallback={verifyCallback}
                        expiredCallback={expiredCallback}
                    />
                </Col>
              <div className="submit-loader-and-button">
                <Button
                  data-testid="submit-btn"
                  variant="primary"
                  type="submit"
                  disabled={!isValid || isSubmitting || isNotRecaptchaVerified}
                >
                  SEND
                </Button>
                {commonData.contactUsLoading ? (
                    <div className="loader"><img className="spinner" src={loadIcon} alt="Loading..." /></div>
                ) : null}
              </div>
            </div>
            {commonData.isContactUsSuccess ? (
              <Alert variant="success">{commonData.contactUsMessage}</Alert>
            ) : null}
            {commonData.isContactUsError ? (
              <Alert variant="danger">{commonData.contactUsMessage}</Alert>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};
