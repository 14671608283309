import React, { useEffect, useState } from 'react';
import './member-search-for-bar.scss'
import searchActiveIcon from "../../../assets/icons/buttons-search-active.svg";
import searchDefaultIcon from "../../../assets/icons/buttons-search-default.svg";
import closeIcon from "../../../assets/icons/icons-system-search-close.svg";
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { fetchMemberListForBar, clearMemberListForBar,
    fetchMemberDetails, saveSelectedMember, updateSelectedMemberForMobile } from '../../../actions/lionAdminAction';
import MemberDetailsModal from '../member-details/MemberDetailsModal';
import { showModal } from '../../../actions/commonAction';
import { MODAL_DIALOG_NAMES } from '../../../shared/configs/appConfigs';
import { IMemberSearchForBarPayload } from '../bar-details/MemberSearchForBarPayload';
import loadIcon from '../../../assets/global-element-spinner.svg';

export const MemberSearchForBar: React.FC = () => {
    const [inputValue, setInputValue] = useState('');

    const dispatch = useDispatch();

    const memberListForBar = useSelector<
      AppState,
      AppState['lionAdminData']['memberListForBar']
      >((state) => state.lionAdminData.memberListForBar);

    const isMemberListForBarLoading = useSelector<
      AppState,
      AppState['lionAdminData']['isMemberListForBarLoading']
      >((state) => state.lionAdminData.isMemberListForBarLoading);

    const isMemberListForBarSuccess = useSelector<
      AppState,
      AppState['lionAdminData']['isMemberListForBarSuccess']
      >((state) => state.lionAdminData.isMemberListForBarSuccess);

    const isMemberListForBarError = useSelector<
      AppState,
      AppState['lionAdminData']['isMemberListForBarError']
      >((state) => state.lionAdminData.isMemberListForBarError);

    const memberListForBarErrorMessage = useSelector<
      AppState,
      AppState['lionAdminData']['memberListForBarErrorMessage']
      >((state) => state.lionAdminData.memberListForBarErrorMessage);

    const updateInputValue = (e: any) => {
        setInputValue(e!.target?.value);
    };

    const searchTable = () => {
        dispatch(fetchMemberListForBar(inputValue));
    }

    const clearSearch = () => {
        setInputValue('');
    }

    const clearSearchResults = () => {
        setInputValue('');
        dispatch(clearMemberListForBar());
    }

    const showMemberDetails = (member: IMemberSearchForBarPayload) => {
        dispatch(showModal(MODAL_DIALOG_NAMES.memberDetails));
        dispatch(saveSelectedMember({
            memberId: member.memberId,
            memberName: '',
            boardName: '',
            pintLevel: 0,
            dateRegistered: '',
            shirtSize: '',
        }))
        dispatch(fetchMemberDetails());
        setTimeout(() => {
            dispatch(updateSelectedMemberForMobile());
        }, 2000)

    }

    return(
        <div className="member-search-for-bar-container">
            <div className="heading">SEARCH MEMBERS</div>
            <div className="search-section">
                {
                    inputValue.length > 2
                            ? (

                              <>
                                  <img
                                    className="clear-icon"
                                    src={closeIcon}
                                    alt="Clear Icon"
                                    onClick={() => clearSearch()}
                                  />

                                  <img
                                    className="search-icon"
                                    src={searchActiveIcon}
                                    alt="Search Icon"
                                    onClick={() => searchTable()}
                                  />
                              </>

                            )
                            : (
                                <img
                                className="search-icon"
                                src={searchDefaultIcon}
                                alt="Search Icon"
                                />
                            )

                }
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for members"
                    value={inputValue}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        updateInputValue(event);
                    }}
                />
                {
                    isMemberListForBarLoading && (
                      <div className='center loading-section'>
                          <img className='spinner' src={loadIcon} alt='Loading...' />
                      </div>
                    )
                }
                {
                    isMemberListForBarError &&
                    <div style={{color: "red", fontSize: "14px"}}>{memberListForBarErrorMessage}</div>
                }
                {
                    memberListForBar && memberListForBar!.length == 0 && isMemberListForBarSuccess &&
                    <div style={{fontSize: "14px"}}>There are no members with this name</div>
                }
                {
                    memberListForBar && memberListForBar!.length > 0 &&
                    <div className="search-text">Search Results</div>
                }
                {
                    memberListForBar && memberListForBar!.length > 0 && memberListForBar!.map((member, idx) => {
                        return(
                          <div onClick={() => showMemberDetails(member)} className="member-link" key={idx}>{member.firstName} {member.lastName}</div>
                        )
                    })
                }
                <button onClick={clearSearchResults} style={{width: '100%', marginTop: '32px'}} data-testid="submit-btn" type="button" className="btn btn-primary btn-outline-secondary">Clear Search</button>
                <MemberDetailsModal/>
            </div>
        </div>
    )
}