import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import validationSchema from './BarDetailsValidationSchema';
import {
  AppEmailInput,
  AppSelectInput,
  AppTextInput,
} from '../../../shared/components/ui/form-input/FormInput';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import './bar-details.scss';
import { AppState } from '../../../reducers/rootReducer';
import {
  fetchBarDetails,
  editBarDetails,
  setBarAddress,
  setBarAddressPoints,
  clearBarEdit, clearBarCache
} from '../../../actions/lionAdminAction';
import GooglePlaces from './GooglePlaces';
import Alert from 'react-bootstrap/Alert';
import loadIcon from '../../../assets/global-element-spinner.svg';
import CancelEditBarModal from './CancelEditBarModal';

const BarDetails: React.FC = () => {
  const [value, setValue] = useState('');
  const [cancelEditBarModalShow, setCancelEditBarModalShow] = useState<boolean>(
    false
  );
  const [editMode, setEditMode] = useState(false);
  const tierArr = [
    { value: 'None', label: 'None' },
    { value: 'Silver', label: 'Silver' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Platinum', label: 'Platinum' },
  ];

  const dispatch = useDispatch();

  const lionAdminData = useSelector<AppState, AppState['lionAdminData']>(
    (state) => state.lionAdminData
  );

  const barDetails = useSelector<
    AppState,
    AppState['lionAdminData']['barDetails']
  >((state) => state.lionAdminData.barDetails);

  const selectedBarId = useSelector<
    AppState,
    AppState['authData']['selectedBarId']
  >((state) => state.authData.selectedBarId);

  useEffect(() => {
    dispatch(fetchBarDetails());
  }, [dispatch, selectedBarId]);

  useEffect(() => {
    return () => {
      dispatch(clearBarEdit());
    };
  }, [dispatch]);

  const handleSetValue = (value: string) => {
    setValue(value);
  };

  function hideCancelEditBarModalShow(): void {
    setCancelEditBarModalShow(false);
  }

  function submitCancel(): void {
    cancelEdit();
    setEditMode(false);
  }

  const handleSetBarAddress = (
    value: {
      address: string;
      suburb: string;
      city: string;
      postCode: string;
    },
    formikValues: any
  ) => {
    setValue(value.address);
    dispatch(
      setBarAddress({
        sapCustomerId: formikValues.sapCustomerId,
        name: formikValues.name,
        address2: formikValues.address2,
        phone: formikValues.phone,
        email: formikValues.email,
        contactFirstName: formikValues.contactFirstName,
        contactLastName: formikValues.contactLastName,
        tier: formikValues.tier,
        address: value.address,
        suburb: value.suburb,
        city: value.city,
        postCode: value.postCode,
      })
    );
  };

  const handleSetAddressPoints = (points: { lat: string; lng: string }) => {
    dispatch(
      setBarAddressPoints({
        lat: points.lat,
        lng: points.lng,
      })
    );
  };

  const cancelEdit = () => {
    dispatch(clearBarCache());
    dispatch(fetchBarDetails());
  };

  function onCancelEditBarModalShow() {
    setCancelEditBarModalShow(true);
  }

  return (
    <div className="bar-details-dashboard-section">
      <h3 className="bar-details-dashboard-section__heading">
        {barDetails?.name}
        {lionAdminData.isBarDetailsLoading ? (
          <div className="loader loading-icon">
            <img
              className="spinner"
              src={loadIcon}
              alt="Loading..."
            />
          </div>
        ) : null}
      </h3>

      <Formik
        initialValues={barDetails!}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={async (values) => {
          setEditMode(false);
          setValue('');
          dispatch(editBarDetails(values));
        }}
      >
        {({ setFieldValue, setFieldTouched, errors, touched, isValid }) => {
          return (
            <Form>
              <div className="bar-details-dashboard-section__form">
                <div className="bar-details-dashboard-section__form-section">
                  <div className="bar-create-dashboard-section__field">
                    <AppTextInput
                      label="SAP Customer ID"
                      id="sapCustomerId"
                      name="sapCustomerId"
                      type="text"
                      error={errors.sapCustomerId}
                      touched={touched.sapCustomerId}
                      maxLength={60}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.sapCustomerId}
                      touched={touched.sapCustomerId}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Bar Name"
                      id="name"
                      name="name"
                      type="text"
                      error={errors.name}
                      touched={touched.name}
                      maxLength={60}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.name}
                      touched={touched.name}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Contact First Name"
                      id="contactFirstName"
                      name="contactFirstName"
                      type="text"
                      error={errors.contactFirstName}
                      touched={touched.contactFirstName}
                      maxLength={50}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      data-testid="test-id-error"
                      error={errors.contactFirstName}
                      touched={touched.contactFirstName}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Contact Last Name"
                      id="contactLastName"
                      name="contactLastName"
                      type="text"
                      error={errors.contactLastName}
                      touched={touched.contactLastName}
                      maxLength={50}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.contactLastName}
                      touched={touched.contactLastName}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppEmailInput
                      label="Email address"
                      id="email"
                      name="email"
                      type="email"
                      placeholder=""
                      maxLength={320}
                      error={errors.email}
                      touched={touched.email}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Phone"
                      id="phone"
                      name="phone"
                      type="text"
                      minLength={10}
                      maxLength={10}
                      touched={touched.phone}
                      error={errors.phone}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.phone}
                      touched={touched.phone}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppSelectInput
                      label="Tier"
                      id="tier"
                      name="tier"
                      placeholder="Pick a tier"
                      val={barDetails?.tier}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      error={errors.tier}
                      touched={touched.tier}
                      optionArray={tierArr}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.tier}
                      touched={touched.tier}
                    />
                  </div>

                  <div
                    className="bar-create-dashboard-section__field"
                    style={{ display: editMode ? 'block' : 'none' }}
                  >
                    <div className="form-group google-address">
                      <label>Address</label>
                      <GooglePlaces
                        label="Address"
                        id="address"
                        name="address"
                        value={value}
                        touched={touched.address}
                        error={errors.address}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        onSetValue={handleSetValue}
                        onSetAddress={handleSetBarAddress}
                        onSetAddressPoints={handleSetAddressPoints}
                      />
                      <ValidationErrorTemplate
                        error={errors.address}
                        touched={touched.address}
                        isMapSelect={true}
                      />
                    </div>
                  </div>
                  <div
                    className="bar-details-dashboard-section__field"
                    style={{ display: editMode ? 'none' : 'block' }}
                  >
                    <AppTextInput
                      label="Address"
                      id="address"
                      name="address"
                      type="text"
                      placeholder="Street Address"
                      error={errors.address}
                      touched={touched.address}
                      maxLength={100}
                      disabled
                    />
                    <ValidationErrorTemplate
                      error={errors.address}
                      touched={touched.address}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Suburb"
                      id="suburb"
                      name="suburb"
                      type="text"
                      maxLength={60}
                      error={errors.suburb}
                      touched={touched.suburb || false}
                      disabled
                    />
                    <ValidationErrorTemplate
                      error={errors.suburb}
                      touched={touched.suburb}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="City"
                      id="city"
                      name="city"
                      maxLength={60}
                      type="text"
                      error={errors.city}
                      touched={touched.city || false}
                      disabled
                    />
                    <ValidationErrorTemplate
                      error={errors.city}
                      touched={touched.city}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Post Code"
                      id="postCode"
                      name="postCode"
                      type="text"
                      maxLength={4}
                      error={errors.postCode}
                      touched={touched.postCode || false}
                      disabled
                    />
                    <ValidationErrorTemplate
                      error={errors.postCode}
                      touched={touched.postCode}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Address 2"
                      id="address2"
                      name="address2"
                      type="text"
                      maxLength={100}
                      error={errors.address2}
                      touched={touched.address2}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.address2}
                      touched={touched.address2}
                    />
                  </div>
                </div>

                <div className="bar-details-dashboard-section__buttons">
                  {!editMode && (
                    <Button
                      data-testid="submit-btn"
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setEditMode(true);
                        setValue(barDetails ? barDetails.address : '');
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {editMode && (
                    <Button
                      variant="primary"
                      className="update-button"
                      type="submit"
                      disabled={!isValid || lionAdminData.isEditBarsLoading}
                    >
                      Update
                    </Button>
                  )}
                  {editMode && (
                    <Button
                      className="btn btn-primary btn-outline-secondary cancel-button"
                      type="button"
                      onClick={() => onCancelEditBarModalShow()}
                      style={{
                        display:
                          Object.keys(touched).length === 0 ? 'none' : 'block',
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {lionAdminData.isEditBarsLoading ? (
                    <div className="loader loading-icon">
                      <img
                        className="spinner"
                        src={loadIcon}
                        alt="Loading..."
                      />
                    </div>
                  ) : null}
                </div>
                {lionAdminData.isEditBarsSuccess ? (
                  <Alert variant="success" style={{ margin: '0 15px' }}>
                    {lionAdminData.editBarsMessage}
                  </Alert>
                ) : null}
                {lionAdminData.isEditBarsError ? (
                  <Alert variant="danger" style={{ margin: '0 15px' }}>
                    {lionAdminData.editBarsMessage}
                  </Alert>
                ) : null}
                {cancelEditBarModalShow && (
                  <CancelEditBarModal
                    hideModalShow={hideCancelEditBarModalShow}
                    onSubmitCancel={submitCancel}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BarDetails;
