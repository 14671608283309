export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
export const X_API_KEY = process.env.REACT_APP_API_KEY;
export const BASE_WEBSITE_URL = process.env.REACT_APP_BASE_WEBSITE_URL;
export const CONTENTFUL_API_KEY = process.env.REACT_APP_CONTENTFUL_API_KEY;
export const CONTENTFUL_ENV = process.env.REACT_APP_CONTENTFUL_ENV;
export const CONTENTFUL_SPACE_ID =  process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const MAX_PINTS = 2500;
export const PINT_INCREMENTAL = 100;
export const PASSWORD_MAX_LENGTH = 20;
export const INSTAGRAM_URL = 'https://instagram.com/guinnessnz';
export const MODAL_DIALOG_NAMES = {
  login:'login', registration: 'register', contactUs:'contactUs',
  termsAndCondition: 'termsAndCondition', employeeDetails: 'employeeDetails',
  employeeCreate: 'employeeCreate', memberInfo: 'memberInfo',
  memberDetails: 'memberDetails'
}
