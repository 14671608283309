import React, { useEffect } from 'react';
import './total-bars.scss';
import { fetchTotalBars } from '../../../actions/lionAdminAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';

const TotalBars: React.FC = () => {
  const dispatch = useDispatch();

  const totalBars = useSelector<
    AppState,
    AppState['lionAdminData']['totalBars']
  >((state) => state.lionAdminData.totalBars);

  useEffect(() => {
    dispatch(fetchTotalBars());
  }, [dispatch]);

  return <div className="total-bars__heading">TOTAL BARS: {totalBars}</div>;
};

export default TotalBars;
