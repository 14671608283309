import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import playButton from '../../assets/play_button.svg';
import harpWhite from '../../assets/harp-white.svg';
import queensAward from '../../assets/queens-award.svg';
import './magic-widget.scss'
import { IGuinnessMagicWidget } from './AboutGuinnessPage.interface';


export const MagicWidget: React.FC<IGuinnessMagicWidget> = (props) => {

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  const handleScroll = () => {
    const element = document.getElementById('magic-widget-container');
    const scroll_pos = element!.offsetTop;
    const y_scroll_pos = window.pageYOffset;
    if (y_scroll_pos > scroll_pos){
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }
  return(
    <div className="magic-widget-container" id="magic-widget-container">
      <div className="magic-widget-container__title">{props.guinnessMagicWidgetHeading}</div>
      <div className="divider-center"></div>
      <div className="magic-widget-container__para-1">{props.guinnessMagicWidgetDescription1}</div>

      <div className="magic-widget-container__para-2">{props.guinnessMagicWidgetDescription2}</div>
      <div className="magic-widget-container__video">
        <ReactPlayer
          url={props.guinnessMagicWidgetVideo}
          controls={false}
          playing={isPlaying}
          width='100%'
          height='100%'
          muted={true}
          loop={true}
          volume={0}
          playsinline={true}
        ></ReactPlayer>
      </div>
      <div className="magic-widget-container__subtitle">{props.guinnessMagicWidgetSubHeading}</div>
      <div className="magic-widget-container__para-3">{props.guinnessMagicWidgetDescription3}</div>
      <div className="magic-widget-container__icons">
        <div className="queens-award-container">
          <img src={props.guinnessMagicWidgetIcon1} className="queens-award"/>
        </div>
        <div className="harp-white-container">
          <img src={props.guinnessMagicWidgetIcon2} className="harp-white"/>
        </div>
      </div>
    </div>
  )
}