import * as Yup from 'yup';
import { maxText, numberRegex, pswdRegex } from '../../shared/utils/input-regex';
import { PASSWORD_MAX_LENGTH } from '../../shared/configs/appConfigs';

export const ForgotPswdConfirmValidationSchema = Yup.object().shape({
  otpCode: Yup.string()
    .nullable()
    .required()
    .matches(numberRegex, 'The code must be a number'),
  newPassword: Yup.string().required('This field is required')
    .matches(
      pswdRegex,
      'Password format is incorrect (Atleast 1 lowercase, uppercase, special character)'
    )
    .min(8, 'Too short. minimum 8 characters')
    .max(PASSWORD_MAX_LENGTH, `${maxText} ${PASSWORD_MAX_LENGTH}`),
  confirmPassword: Yup.string()
    .required('This field is required')
    .when('newPassword', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        'Both password need to be the same'
      ),
    }),
});
