import React from 'react';
import './terms-and-conditions.scss';
import { useHistory } from 'react-router-dom';

export const TermsAndCondition: React.FC = () => {
  const history = useHistory(); 
  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <div className="terms-and-conditions">
      <div className="terms-and-conditions__heading-section">
        <h2 className="terms-and-conditions-heading">
          GUINNESS 1759 CLUB TERMS & CONDITIONS
        </h2>

        <div
          onClick={goToPreviousPath}
          className="terms-and-conditions-close-btn"
        >
          &#x000D7;
        </div>
      </div>
      <ol className="terms-and-conditions__list">
        <li>
          Information on how to enter and all communications associated with the
          promotion form part of these conditions of entry. By participating
          entrants agree to be bound by these terms and conditions.
        </li>
        <li>
          Entry is open to New Zealand residents who are 18 years and over.
        </li>
        <li>
          To enter and be eligible to become a member of the 1759 Club you must
          purchase 100 pints of Guinness in one participating outlet over a 12-
          month period.
        </li>
        <li>
          All new and renewal registrations should be completed online at
          www.guinnessnz.co.nz
        </li>
        <li>
          There are 4 target levels in the 1759 Club. 100 pints, 500 pints,
          1,000 pints and 2,500 pints. At the attainment of level 100, 500,
          1,000 & 2,500 a member will be sent a gift. 500, 1,000 and 2,500 pints
          plus will receive a gift for St Patrick’s Day.
        </li>
        <li>
          Upon fulfillment of the target amount of pints, individuals or members
          provide completed records (stamp cards) to the bar to be levelled up.
        </li>
        <li>Gifts or rewards are not redeemable for cash.</li>
        <li>
          Entrants may be refused service of alcohol or provision of an
          alcoholic beverage prize if it would breach any relevant laws or codes
          including those relating to the Code of Practice for Responsible
          Service of Alcohol.
        </li>
        <li>
          The rewards provided will be determined by the Promoter, at the
          Promoter’s full discretion.
        </li>
        <li>
          Once rewards are delivered the items become the responsibility of the
          1759 Club member.
        </li>
        <li>
          The Promoter accepts no responsibility for lost or misplaced
          registration forms or membership cards; proof of sending will not be
          accepted as proof of registering.
        </li>
        <li>
          The Promoter accepts no responsibility for lost or misplaced rewards
          where members have changed delivery address without notifying the 1759
          Club Administrators. All registrations become the property of the
          Promoter.
        </li>
        <li>
          The Promoter reserves the right to amend or withdraw this promotion
          due to circumstances beyond its control.
        </li>
        <li>
          Details from all entries will be collected and used for the purposes
          of conducting this promotion (which may include disclosure to third
          parties for the purpose of processing and conducting the promotion)
          and for publicity purposes surrounding this promotion. By entering
          this promotion entrants consent to the use of their information as
          described. Entrants may access and/or change their data on their
          profile on www.guinnessnz.com. The Promoter will only use your
          personal details for marketing purposes outside those described above
          if you “opt in” and join the Guinness mailing list when registering
          otherwise all entries and personal details will be destroyed following
          the conclusion of the promotion.
        </li>
        <li>
          The Promoter is Lion, 27 Napier Street, Freemans Bay, Auckland 1010.
        </li>
      </ol>
    </div>
  );
};
