import React from 'react';
import { CustomModal } from 'src/shared/components/ui/modal/modal';
import { IModalConfig } from 'src/shared/types/modalInterface';

const CancelRegistrationModal: React.FC<{
  hideModalShow: () => void;
  onSubmitCancel: () => void;
  onResetForm: () => void;
}> = (props) => {
  const cancelRegistrationConfig: IModalConfig = {
    title: 'Cancel registration',
    showModal: true,
    modalSize: 'smallModal',
    closeModal: hideModal,
    body: (
      <p>
        Registration form will be cleared. Do you want to cancel registration?
      </p>
    ),
    primaryBtnText: 'Yes',
    secondaryBtnText: 'No',
    submitModal: clearForm,
  };

  function hideModal() {
    props.hideModalShow();
  }

  function clearForm() {
    props.onResetForm();
    props.onSubmitCancel();
  }

  return (
    <div>
      <CustomModal config={cancelRegistrationConfig}/>
    </div>
  );
};

export default CancelRegistrationModal;
