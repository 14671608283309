import React, { useState } from 'react';
import './perfect-pint.scss'
import ReactPlayer from 'react-player';
import playButton from '../../assets/play_button.svg'
import { IGuinnessPerfectPint } from './AboutGuinnessPage.interface';
import soundButton from '../../assets/volume.svg';
import soundOffButton from '../../assets/volume-off.svg';


export const PerfectPint: React.FC<IGuinnessPerfectPint> = (props) => {

  const [playEndReload, setPlayEndReload] = useState('');

  const [showSoundIcon, setShowSoundIcon] = useState(true);

  const [mute, setMute] = useState(false);

  const generateRandom = () => {
    const str = Math.random().toString(36).substring(7);
    setPlayEndReload(str);
  }

  const toggleMute = () => {
    setMute(!mute)
  }

  return(
    <div className="perfect-pint-container">
      <div className="perfect-pint-container__text-section">
        <div className="perfect-pint-container__text-section-one">
          <div className="pour-text">{props.guinnessPerfectPintHeading}</div>
          <div className="divider-center"></div>
          <div className="can-text">{props.guinnessPerfectPintSubHeading}</div>
        </div>
        <div className="perfect-pint-container__video-section-mobile" key={playEndReload}>
          <div className="sound-icon" onClick={toggleMute}>
            {
              !mute ? <img src={soundButton}/> : <img src={soundOffButton}/>
            }
          </div>
          <ReactPlayer
            url={props.guinnessPerfectPintVideo}
            controls={false}
            playing={true}
            playIcon={<button style={{backgroundColor: 'transparent', border: 'none'}}><img src={playButton}/></button>}
            light={props.guinnessPerfectPintVideoStill}
            width='100%'
            height='560px'
            muted={mute}
            onEnded={() => generateRandom()}
          ></ReactPlayer>
        </div>
        <div className="perfect-pint-container__text-section-two">
          <div className="para-one">
            {props.guinnessPerfectPintDescription1}
          </div>
          <div className="para-two">
            {props.guinnessPerfectPintDescription2}
          </div>
          <div className="para-three">
            {props.guinnessPerfectPintDescription3}
          </div>
        </div>
      </div>
      <div className="perfect-pint-container__video-section"
           key={playEndReload}
           onMouseMove={() => setShowSoundIcon(true)}
           onMouseLeave={() => setShowSoundIcon(false)}
      >
        <div className="sound-icon" style={{display: showSoundIcon ? 'block' : 'none'}}
             onClick={toggleMute}
        >
          {
            !mute ? <img src={soundButton}/> : <img src={soundOffButton}/>
          }
        </div>
        <ReactPlayer
          url={props.guinnessPerfectPintVideo}
          controls={false}
          playing={true}
          playIcon={<button style={{backgroundColor: 'transparent', border: 'none'}}><img src={playButton}/></button>}
          light={props.guinnessPerfectPintVideoStill}
          width='100%'
          height='560px'
          muted={mute}
          onEnded={() => generateRandom()}
          onPlay={() => setShowSoundIcon(false)}
        ></ReactPlayer>
      </div>
    </div>
  )
}