import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import validationSchema from './EmployeeDetailsValidationSchema';
import {
  AppEmailInput,
  AppTextInput,
} from '../../../shared/components/ui/form-input/FormInput';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import './employee-details.scss';
import { AppState } from '../../../reducers/rootReducer';
import {
  fetchEmployeeDetails,
  editEmployeeDetails,
  clearEmployeeEdit,
  clearBarEmployeeCache,
  editLionAdminDetails
} from '../../../actions/lionAdminAction';
import Alert from 'react-bootstrap/Alert';
import loadIcon from '../../../assets/global-element-spinner.svg';
import CancelEditEmployeeModal from './CancelEditEmployeeModal';
import SubmitEditEmployeeModal from './SubmitEditEmployeeModal';
import { IEmployeeDetailsPayload } from './EmployeeDetailsPayload';
import Switch from "react-switch";
import { getUserRole } from '../../../shared/utils/global-functions';

const EmployeeDetails: React.FC = () => {
  const [
    cancelEditEmployeeModalShow,
    setCancelEditEmployeeModalShow,
  ] = useState<boolean>(false);

  const [
    submitEditEmployeeModalShow,
    setSubmitEditEmployeeModalShow,
  ] = useState<boolean>(false);

  const [editMode, setEditMode] = useState(false);

  const valueRef = React.useRef<IEmployeeDetailsPayload | null>(null);

  const dispatch = useDispatch();

  const isOpenDialog = useSelector<AppState, AppState['commonData']['isOpenDialog']>(
    (state) => state.commonData.isOpenDialog
  );

  const lionAdminData = useSelector<AppState, AppState['lionAdminData']>(
    (state) => state.lionAdminData
  );

  const employeeDetails = useSelector<
    AppState,
    AppState['lionAdminData']['employeeDetails']
  >((state) => state.lionAdminData.employeeDetails);

  const [checked, setChecked] = useState(true);

  const [active, setActive] = useState(true);

  const selectedEmployeeId = useSelector<
    AppState,
    AppState['authData']['selectedBarId']
  >((state) => state.lionAdminData.selectedEmployeeId);

  React.useEffect(() => {
    dispatch(fetchEmployeeDetails());
  }, [dispatch, selectedEmployeeId]);

  React.useEffect(() => {
    if (employeeDetails) {
      setActive(employeeDetails.isActive!);
      setChecked(employeeDetails.isAdmin!);
    }
  }, [employeeDetails])

  React.useEffect(() => {
    return () => {
      dispatch(clearEmployeeEdit());
    };
  }, [dispatch]);

  function hideCancelEditEmployeeModalShow(): void {
    setCancelEditEmployeeModalShow(false);
  }

  function hideSubmitEditEmployeeModalShow(): void {
    setSubmitEditEmployeeModalShow(false);
  }

  function submitCancel(): void {
    cancelEdit();
    setEditMode(false);
  }

  function submitEdit(): void {
    setEditMode(false);
    if (valueRef.current!.barId == -1) {
      dispatch(editLionAdminDetails(valueRef.current!))
    } else {
      dispatch(editEmployeeDetails(valueRef.current!, employeeDetails!));
    }
  }

  const cancelEdit = () => {
    dispatch(clearBarEmployeeCache());
    dispatch(fetchEmployeeDetails());
  };

  function onCancelEditEmployeeModalShow() {
    setCancelEditEmployeeModalShow(true);
  }

  function onSubmitEditEmployeeModalShow(values:  IEmployeeDetailsPayload){
    values = {
      ...values,
      isActive: active,
      isAdmin: checked
    }
    valueRef.current = values;
    setSubmitEditEmployeeModalShow(true);
  }

  function handleChange() {
    setChecked(!checked);
  }

  return (
    <div className={isOpenDialog ? "bar-details-dashboard-section employee-details-dialog" : "bar-details-dashboard-section"}>
      <h3 className="bar-details-dashboard-section__heading">
        {employeeDetails?.barName}
        {lionAdminData.isEmployeeDetailsLoading ? (
          <div className="loader loading-icon">
            <img
              className="spinner"
              src={loadIcon}
              alt="Loading..."
            />
          </div>
        ) : null}
      </h3>

      <Formik
        initialValues={employeeDetails!}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={async (values) => {
          // setEditMode(false);
          // dispatch(editEmployeeDetails(values));
        }}
      >
        {({ values, errors, touched, isValid }) => {
          return (
            <Form>
              <div className="bar-details-dashboard-section__form">
                <div className="bar-details-dashboard-section__form-section">
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Bar Name"
                      id="barName"
                      name="barName"
                      type="text"
                      error={errors.barName}
                      touched={touched.barName}
                      maxLength={60}
                      disabled={true}
                    />
                    <ValidationErrorTemplate
                      error={errors.barName}
                      touched={touched.barName}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="First Name"
                      id="firstName"
                      name="firstName"
                      type="text"
                      error={errors.firstName}
                      touched={touched.firstName}
                      maxLength={50}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.firstName}
                      touched={touched.firstName}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppTextInput
                      label="Last Name"
                      id="lastName"
                      name="lastName"
                      type="text"
                      error={errors.lastName}
                      touched={touched.lastName}
                      maxLength={50}
                      disabled={!editMode}
                    />
                    <ValidationErrorTemplate
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <AppEmailInput
                      label="Email address"
                      id="email"
                      name="email"
                      type="email"
                      placeholder=""
                      maxLength={320}
                      error={errors.email}
                      touched={touched.email}
                      disabled={true}
                    />
                    <ValidationErrorTemplate
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>
                  <div className="bar-details-dashboard-section__field">
                    <div className="form-group">
                      <label>Status</label>
                      <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100px'}}>
                          <input data-testid='active-radio-btn' style={{transform: 'scale(1.75)'}} disabled={!editMode || (employeeDetails && employeeDetails.isAdmin!) || checked} type="radio" name="status" checked={active == true} value="Active" onChange={() => setActive(true)}/>Active
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100px'}}>
                          <input data-testid='inactive-radio-btn' style={{transform: 'scale(1.75)'}} disabled={!editMode || (employeeDetails && employeeDetails.isAdmin!) || checked} type="radio" name="status" checked={active !== true} value="Inactive" onChange={() => setActive(false)}/>Inactive
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    employeeDetails?.barId !== -1 && <div className="bar-details-dashboard-section__field">
                      <div className="form-group">
                        <div style={{marginTop: '38px', display: 'flex'}}>
                          <Switch disabled={!editMode || (employeeDetails && employeeDetails.isAdmin!) || active !== true} onChange={handleChange} checked={checked} uncheckedIcon={false} checkedIcon={false}/>
                          <span style={{marginLeft: '10px'}}>Make administrator</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="bar-details-dashboard-section__buttons">
                  {!editMode && (
                    <Button
                      data-testid="submit-btn"
                      variant="primary"
                      type="button"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {editMode && (
                    <Button
                      variant="primary"
                      className="update-button"
                      onClick={() => onSubmitEditEmployeeModalShow(values)}
                      disabled={!isValid || lionAdminData.isEditEmployeeLoading}
                    >
                      Update
                    </Button>
                  )}
                  {editMode && (
                    <Button
                      className="btn btn-primary btn-outline-secondary cancel-button"
                      type="button"
                      onClick={() => onCancelEditEmployeeModalShow()}
                      style={{
                        display:
                          Object.keys(touched).length === 0
                          && employeeDetails
                          && checked === employeeDetails.isAdmin
                          && active === employeeDetails.isActive
                            ? 'none' : 'block',
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {lionAdminData.isEditEmployeeLoading ? (
                    <div className="loader loading-icon">
                      <img
                        className="spinner"
                        src={loadIcon}
                        alt="Loading..."
                      />
                    </div>
                  ) : null}
                </div>
                {lionAdminData.isEditEmployeeSuccess ? (
                  <Alert variant="success" style={{ margin: '0 15px' }}>
                    {lionAdminData.editEmployeeMessage}
                  </Alert>
                ) : null}
                {lionAdminData.isEditEmployeeError ? (
                  <Alert variant="danger" style={{ margin: '0 15px' }}>
                    {lionAdminData.editEmployeeMessage}
                  </Alert>
                ) : null}
                {cancelEditEmployeeModalShow && (
                  <CancelEditEmployeeModal
                    hideModalShow={hideCancelEditEmployeeModalShow}
                    onSubmitCancel={submitCancel}
                  />
                )}
                {submitEditEmployeeModalShow && (
                  <SubmitEditEmployeeModal
                    hideModalShow={hideSubmitEditEmployeeModalShow}
                    onSubmit={submitEdit}
                    values={{...values, isActive: active, isAdmin: checked}}
                    employeeDetails={employeeDetails!}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmployeeDetails;
