import React, { useEffect, useState } from 'react';
import guinnessLogo from '../../assets/1759-club-logo-2-x.png';
import instagramIcon from '../../assets/icons/icons-social-instagram.svg';
import { Button } from 'react-bootstrap';
import { ContentfulClientConfigs } from '../../shared/configs/ContentfulClientConfigs';
import './home.scss';
import { INSTAGRAM_URL, MODAL_DIALOG_NAMES } from 'src/shared/configs/appConfigs';
import { LinkContainer } from 'react-router-bootstrap';
import registerClub from '../../assets/register-club.png';
import { showModal } from '../../actions/commonAction';
import { useDispatch } from 'react-redux';

interface HomeContentInterface {
  headerTitle: string;
  welcomeHeaderDescription: string;
  aboutGuinnessHeader: string;
  aboutGuinnessDescription: string;
  getSocialHeader: string;
  getSocialDescription: string
}
export const Home: React.FC = () => {
  document.body.style.backgroundColor = '#fff';
  const [clubLogo, setClubLogo] = useState('');
  const [discoverImage, setDiscoverImage] = useState('');
  const [homeContent, setHomeContent] = useState<HomeContentInterface>();
  const dispatch = useDispatch();

  useEffect(() => {
    ContentfulClientConfigs.getEntries<HomeContentInterface>({
      content_type: 'homeUnauthenticated',
    }).then(({ items }) => {
      const res = items[0].fields;
      setHomeContent(res);
    });

    ContentfulClientConfigs.getAsset('4TrIubfKZGWC3fGISiq6Gv').then((asset) => {
      setClubLogo(`${asset.fields.file.url}`);
    });

    ContentfulClientConfigs.getAsset('5jMwTCR0xu9UCrSZ0en1YF').then((asset) => {
      setDiscoverImage(`${asset.fields.file.url}`);
    });
  }, []);

  const onClickInstaIcon = () => {
    window.open(INSTAGRAM_URL, "_blank");
  }

  const onClickRegister = () => {
    dispatch(showModal(MODAL_DIALOG_NAMES.registration));
  };

  return (
    <div className="home-container">
      {/* Welcome Section */}
      <div className="welcome-section">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="col-12 text-center">
            <h4>{homeContent?.headerTitle}</h4>
            <img src={guinnessLogo} alt="1759 Club Logo" />
            <p className="mb-0 py-3 text-center welcome-desc">
              {homeContent?.welcomeHeaderDescription}
            </p>
            <Button
              className="welcome-section__club-button"
              variant="primary"
              onClick={() => onClickRegister()}
            >
              Register
            </Button>
          </div>
        </div>
      </div>
      <div className="about-guinness-section">
        <div className="about-guinness-section__image" style={{backgroundImage: 'url(' + discoverImage + ')'}}/>
        <div className="about-guinness-section__text">
          <div className="heading">{homeContent?.aboutGuinnessHeader}</div>
          <div className="divider-center"></div>
          <div className="para">
            {homeContent?.aboutGuinnessDescription}
          </div>
          <LinkContainer
            to="/about"
          >
            <div className="discover-button">
              <Button variant="primary">
                Discover More
              </Button>
            </div>
          </LinkContainer>
        </div>
      </div>
      {/* Get Social */}
      <div className="social-section">
        <div className="social-section-bg"></div>
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center container-md">
          <div className="col-12 col-md-6 text-center-sm">
            <h1>{homeContent?.getSocialHeader}</h1>
            <div className="divider"></div>
            <p>{homeContent?.getSocialDescription}</p>
            <div className="d-flex justify-content-center justify-content-md-start align-items-center">
              <Button variant="primary" onClick={() => onClickInstaIcon()}>
                Follow{' '}
                <img
                  className="pl-2"
                  src={instagramIcon}
                  alt="Instagram Icon"
                />
              </Button>
            </div>
          </div>
          <div className="col-12 col-md-6 guinness-cheers-bg">
            <img src={clubLogo} alt="guinness-cheers" />
          </div>
        </div>
      </div>

      <div className="home-register-club-container">
        <div className="home-register-club-container__club-image">
          <img src={registerClub}/>
        </div>
        <div className="home-register-club-container__club-text">
          We&#39;ve got more in store. Register below to find out about the Club.
        </div>
        <Button
          className="home-register-club-container__club-button"
          variant="primary"
          onClick={() => onClickRegister()}
        >
          Register
        </Button>
      </div>
    </div>
  );
};
