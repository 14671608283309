import React from 'react';
import './guinness-draught.scss';
import guinnessDraught from '../../assets/guinness-draught.png'
import { IGuinnessDraught } from './AboutGuinnessPage.interface';


export const GuinnessDraught: React.FC<IGuinnessDraught> = (props) => {
  return(
    <div className="guinness-draught-container">
      <div className="guinness-draught-container__image-section">
        <img src={props.guinnessDraughtImage}/>
      </div>
      <div className="guinness-draught-container__text-section">
        <div className="heading">{props.guinnessDraughtHeading}</div>
        <div className="table">
          <div className="table-left">{props.guinnessDraughtSubHeading}</div>
          <div className="table-right">
            {
              props.guinnessDraughtDescriptionList && props.guinnessDraughtDescriptionList.map((descPair, idx) => {
                return(
                  <div className="table-item" key={idx}>
                    <div className="key">{descPair.fields?.guinnessDraughtDescriptionKey}</div>
                    <div className="value">{descPair.fields?.guinnessDraughtDescriptionValue}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}