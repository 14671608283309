import React, { useEffect, useState } from 'react';
import './lion-admin-create.scss';
import { Form, Formik } from 'formik';
import validationSchema from './LionAdminCreateValidationSchema';
import {
  clearLionAdminCreate,
  createLionAdminDetails,
} from '../../../actions/lionAdminAction';
import ValidationErrorTemplate from '../../../shared/components/ui/validation-error/ValidationError';
import {
  AppEmailInput,
  AppTextInput,
} from '../../../shared/components/ui/form-input/FormInput';
import Button from 'react-bootstrap/Button';
import loadIcon from '../../../assets/global-element-spinner.svg';
import Alert from 'react-bootstrap/Alert';
import CancelLionAdminRegistrationModal from './CancelLionAdminRegistrationModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { ILionAdminCreatePayload } from './LionAdminCreatePayload';

const LionAdminCreate: React.FC = () => {
  const [
    cancelRegistrationModalShow,
    setCancelRegistrationModalShow,
  ] = useState<boolean>(false);

  const [lionAdminDetails, setLionAdminDetails] = useState<ILionAdminCreatePayload>({
    email: '',
    firstName: '',
    lastName: '',
  });

  const dispatch = useDispatch();

  const lionAdminData = useSelector<AppState, AppState['lionAdminData']>(
    (state) => state.lionAdminData
  );

  useEffect(() => {
    if (lionAdminData.isCreateLionAdminSuccess) {
      clearForm();
    }
  }, [lionAdminData.isCreateLionAdminSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearLionAdminCreate());
    };
  }, [dispatch]);

  function hideCancelRegistrationModalShow(): void {
    setCancelRegistrationModalShow(false);
  }

  function onCancelRegistrationModalShow() {
    setCancelRegistrationModalShow(true);
  }

  function clearForm() {
    setLionAdminDetails({
      email: '',
      firstName: '',
      lastName: '',
    });
  }

  function submitCancel(): void {
    clearForm();
  }

  return (
    <div className="lion-admin-create-dashboard-section">
      <h3 className="lion-admin-create-dashboard-section__heading">
        REGISTER LION ADMIN
      </h3>
      <Formik
        initialValues={lionAdminDetails!}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={async (values) => {
          setLionAdminDetails({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
          });
          dispatch(createLionAdminDetails(values));
        }}
      >
        {({ resetForm, errors, touched, isValid }) => {
          return (
            <Form>
              <div className="lion-admin-create-dashboard-section__form">
                <div className="lion-admin-create-dashboard-section__form-section">
                  <div className="lion-admin-create-dashboard-section__field">
                    <AppTextInput
                      label="First Name"
                      id="firstName"
                      name="firstName"
                      data-testid="admin-create-first-name"
                      type="text"
                      error={errors.firstName}
                      touched={touched.firstName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      data-testid="test-id-error"
                      error={errors.firstName}
                      touched={touched.firstName}
                    />
                  </div>
                  <div className="lion-admin-create-dashboard-section__field">
                    <AppTextInput
                      label="Last Name"
                      id="lastName"
                      name="lastName"
                      data-testid="admin-create-last-name"
                      type="text"
                      error={errors.lastName}
                      touched={touched.lastName}
                      maxLength={50}
                    />
                    <ValidationErrorTemplate
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                  </div>
                  <div className="lion-admin-create-dashboard-section__field">
                    <AppEmailInput
                      label="Email address"
                      id="email"
                      name="email"
                      data-testid="admin-create-email"
                      type="email"
                      maxLength={320}
                      error={errors.email}
                      touched={touched.email}
                    />
                    <ValidationErrorTemplate
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>
                </div>
                <div className="lion-admin-create-dashboard-section__buttons">
                  <Button
                    variant="primary"
                    type="submit"
                    className="register-button"
                    data-testid="register-button"
                    disabled={
                      !isValid || lionAdminData.isCreateLionAdminLoading
                    }
                  >
                    Register
                  </Button>

                  <Button
                    className="btn btn-primary btn-outline-secondary cancel-button"
                    type="button"
                    onClick={() => onCancelRegistrationModalShow()}
                    style={{ display: !isValid ? 'none' : 'block' }}
                  >
                    Cancel
                  </Button>
                  {lionAdminData.isCreateLionAdminLoading ? (
                    <div className="loader loading-icon">
                      <img
                        className="spinner"
                        src={loadIcon}
                        alt="Loading..."
                      />
                    </div>
                  ) : null}
                </div>
                {lionAdminData.isCreateLionAdminSuccess ? (
                  <Alert variant="success">
                    {lionAdminData.createLionAdminMessage}
                  </Alert>
                ) : null}
                {lionAdminData.isCreateLionAdminError ? (
                  <Alert variant="danger">
                    {lionAdminData.createLionAdminMessage}
                  </Alert>
                ) : null}
                {cancelRegistrationModalShow && (
                  <CancelLionAdminRegistrationModal
                    hideModalShow={hideCancelRegistrationModalShow}
                    onSubmitCancel={submitCancel}
                    onResetForm={resetForm}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LionAdminCreate;
