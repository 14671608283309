import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import {
  hideMemberDetails,
  setSearchBarPanel,
  setSearchBarEmployeePanel, saveMemberListPagination, clearMemberCache
} from '../../../actions/lionAdminAction';
import chevronUp from '../../../assets/icons/icons-system-chevron-up.svg';
import chevronRight from '../../../assets/icons/icons-system-chevron-right.svg';
import SearchMember from './SearchMember';
import MemberDetails from './MemberDetails';
import Dropdown from 'react-bootstrap/Dropdown';
import './member-element.scss';

const MemberElement: React.FC = () => {
  const dispatch = useDispatch();

  const lionAdminData = useSelector<AppState, AppState['lionAdminData']>(
    (state) => state.lionAdminData
  );

  const showMemberDetails = useSelector<
    AppState,
    AppState['lionAdminData']['showMemberDetails']
    >((state) => state.lionAdminData.showMemberDetails);

  const displaySearchMember = () => {
    dispatch(hideMemberDetails());
  };

  const switchToSearchBar = () => {
    dispatch(setSearchBarPanel());
    dispatch(clearMemberCache());
    dispatch(
      saveMemberListPagination(
        1,
        10,
        '',
        'asc',
        'memberId'
      )
    );
  };

  const switchToSearchEmployee = () => {
    dispatch(setSearchBarEmployeePanel());
    dispatch(clearMemberCache());
    dispatch(
      saveMemberListPagination(
        1,
        10,
        '',
        'asc',
        'memberId'
      )
    );
  };

  return (
    <div className="bar-member-element-dashboard-section">
      <div
        className="bar-member-element-dashboard-section__heading"
        data-testid="bar-member-element-heading-section"
        style={{display: !showMemberDetails ? "flex" : "none"}}
      >
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <span className="heading-bar-1">SEARCH &nbsp;</span>
            <span className="heading-bar-2">MEMBER</span>
            <img className="heading-icon" src={chevronUp}/>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={switchToSearchBar}>
              Bar Search
            </Dropdown.Item>
            <Dropdown.Item onClick={switchToSearchEmployee}>
              Employee Search
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        className="bar-member-element-dashboard-section-content"
        data-testid="bar-member-element-content-section"
      >
        {(showMemberDetails) && (
          <div
            onClick={displaySearchMember}
            className="bar-member-element-dashboard-section-content__link"
          >
            <img className="back-to-search-icon" src={chevronRight}/>
            <span className="back-link">Back</span>
          </div>
        )}
        {showMemberDetails && <MemberDetails />}
        <SearchMember />

      </div>
    </div>
  );
};

export default MemberElement;
