import axios from 'axios';
import { X_API_KEY } from '../configs/appConfigs';

axios.interceptors.request.use(
  (req) => {
    req.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    req.headers['x-api-key'] = X_API_KEY;
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
