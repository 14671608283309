import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import {
  setSearchBarPanel,
  setSearchBarEmployeePanel,
  setSearchBarMemberPanel,
  hideLionAdminCreate,
} from '../../../actions/lionAdminAction';
import chevronUp from '../../../assets/icons/icons-system-chevron-up.svg';
import chevronRight from '../../../assets/icons/icons-system-chevron-right.svg';
import LionAdminCreate from './LionAdminCreate';
import Dropdown from 'react-bootstrap/Dropdown';
import './lion-admin-element.scss';
import SearchBar from '../bar-details/SearchBar';

const LionAdminElement: React.FC = () => {
  const dispatch = useDispatch();

  const showLionAdminCreate = useSelector<
    AppState,
    AppState['lionAdminData']['showLionAdminCreate']
  >((state) => state.lionAdminData.showLionAdminCreate);

  const displaySearchBar = () => {
    dispatch(hideLionAdminCreate());
    dispatch(setSearchBarPanel());
  };

  const switchToSearchEmployee = () => {
    dispatch(hideLionAdminCreate());
    dispatch(setSearchBarEmployeePanel());
  };

  const switchToSearchMember = () => {
    dispatch(hideLionAdminCreate());
    dispatch(setSearchBarMemberPanel());
  };

  return (
    <div className="lion-admin-element-dashboard-section"> {
      !showLionAdminCreate && <>
        <div
          className="lion-admin-element-dashboard-section__heading"
          data-testid="lion-admin-element-heading-section"
        >
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <span className="heading-bar-1">SEARCH &nbsp;</span>
              <span className="heading-bar-2">BAR</span>
              <img className="heading-icon" src={chevronUp} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={switchToSearchEmployee}>
                Employee Search
              </Dropdown.Item>
              <Dropdown.Item onClick={switchToSearchMember}>
                Member Search
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <SearchBar/>
      </>
    }

      <div
        className="lion-admin-element-dashboard-section-content"
        data-testid="lion-admin-element-content-section"
      >
        {showLionAdminCreate && (
          <div
            onClick={displaySearchBar}
            className="lion-admin-element-dashboard-section-content__link"
          >
            <img className="back-to-search-icon" src={chevronRight} />
            {showLionAdminCreate && (
              <span className="back-link">Back</span>
            )}
          </div>
        )}
        {showLionAdminCreate && <LionAdminCreate />}
      </div>
    </div>
  );
};

export default LionAdminElement;
