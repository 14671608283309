import React, { useEffect, useState } from 'react';
import './guinness-glass.scss';
import { IAboutGuinnessIconsList } from './AboutGuinnessPage.interface';


export const GuinnessGlass: React.FC<IAboutGuinnessIconsList> = (props) => {

  if (props.aboutGuinnessIconsList) {
    return (
      <div className="guinness-glass-container">
        <div className="guinness-glass-container__item">
          <img src={props.aboutGuinnessIconsList[0].fields?.iconImage.fields.file.url}/>
          <p>{props.aboutGuinnessIconsList[0]?.fields?.iconText}</p>
        </div>
        <div className="guinness-glass-container__item">
          <img src={props.aboutGuinnessIconsList[1].fields?.iconImage.fields.file.url}/>
          <p>{props.aboutGuinnessIconsList[1]?.fields?.iconText}</p>
        </div>
        <div className="guinness-glass-container__item">
          <img src={props.aboutGuinnessIconsList[2].fields?.iconImage.fields.file.url} className="globe"/>
          <p className=" noBottomPadding">{props.aboutGuinnessIconsList[2]?.fields?.iconText}</p>
        </div>
      </div>
    )
  }

  return null;
}