import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IModalConfig } from '../../../types/modalInterface';
import { IModalPropsConfig } from '../../../types/modalPropsConfig';
import './modal.scss';
import modalClose from '../../../../assets/icons/icons-system-close-dialog.svg';

export const CustomModal: React.FC<IModalPropsConfig> = (props) => {
  const modalConfig: IModalConfig = props.config;

  const [isShowModal, setShow] = useState(false);

  const handleClose = () => {
    if (modalConfig.closeModal) {
      return modalConfig.closeModal();
    }
  };

  const onSubmitModal = () => {
    if (modalConfig.submitModal) {
      modalConfig.submitModal();
    }
    if (modalConfig.closeModal) {
      return modalConfig.closeModal();
    }
  };

  React.useEffect(() => {
    setShow(modalConfig.showModal);
  }, [modalConfig.showModal]);

  return (
    <div>
      <Modal
        show={isShowModal}
        className={modalConfig.modalSize}
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        <Modal.Header
          className={modalConfig.isImageTitle ? 'image-modal-title' : ''}
        >
          <div className={`title m-0 row justify-content-flex-start justify-content-center ${modalConfig.isImageTitle ? 'd-flex d-lg-none': ''}`}>
            {modalConfig.title && !modalConfig.isImageTitle && (
              <Modal.Title>{modalConfig.title}</Modal.Title>
            )}
            {modalConfig.title && modalConfig.isImageTitle && (
              <Modal.Title><img src={modalConfig?.title} alt="Logo Title"/></Modal.Title>
            )}
          </div>
          <img src={modalClose} onClick={handleClose} className="close"/>
        </Modal.Header>
        <Modal.Body>{modalConfig.body}</Modal.Body>
        {modalConfig.primaryBtnText && modalConfig.secondaryBtnText && (
          <Modal.Footer>
            <Button variant="primary" onClick={onSubmitModal}>
              {modalConfig.primaryBtnText}
            </Button>
            <Button className="btn btn-outline-secondary" onClick={handleClose}>
              {modalConfig.secondaryBtnText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};
