import React, { useState } from 'react';
import './employee-list.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { fetchEmployeeList, showModal } from '../../../actions/commonAction';
import { clearBarEmployeeCache, setSelectedEmployeeId } from '../../../actions/lionAdminAction';
import { MODAL_DIALOG_NAMES } from '../../../shared/configs/appConfigs';
import EmployeeDetailsModal from './EmployeeDetailsModal';
import EmployeeCreateModal from './EmployeeCreateModal';
import loadIcon from '../../../assets/global-element-spinner.svg';
import Button from 'react-bootstrap/Button';
import { getUserRole } from '../../../shared/utils/global-functions';

const EmployeeList: React.FC = () => {

  const [showInactive, setShowInactive] = useState(false);

  const dispatch = useDispatch();

  const commonData = useSelector<AppState, AppState['commonData']>(
    (state) => state.commonData
  );

  const employeeList = useSelector<AppState,
    AppState['commonData']['employeeList']>((state) => state.commonData.employeeList);

  const selectedBarId = useSelector<AppState,
    AppState['authData']['selectedBarId']>((state) => state.authData.selectedBarId);

  React.useEffect(() => {
    dispatch(fetchEmployeeList());
  }, [dispatch, selectedBarId]);

  const editEmployee = (employeeId: number) => {
    dispatch(clearBarEmployeeCache());
    dispatch(setSelectedEmployeeId(employeeId));
    dispatch(showModal(MODAL_DIALOG_NAMES.employeeDetails));
  };

  const createEmployee = () => {
    dispatch(showModal(MODAL_DIALOG_NAMES.employeeCreate));
  }

  return (
    <div className='bar-employee-list-dashboard-section'>
      <h4 className='bar-employee-list-dashboard-section__heading'>staff</h4>
      {
        commonData.isEmployeeListLoading
          ? <div className='center loading-section pt-2'>
            <img className='spinner' src={loadIcon} alt='Loading...' />
          </div>
          : <div>
            <div className='bar-employee-list-dashboard-section__content'>
              <div className='bar-employee-list-dashboard-section__content-heading'
                   data-testid='bar-employee-list-content-section'>
                Employees
              </div>
              <div className='bar-employee-list-dashboard-section__content-list'
                   data-testid='bar-employee-list-content-active-list'>
                {
                  (employeeList?.active.map((employee, id) => {
                    return (
                      <div className='item' key={id}>
                        <i className='fa fa-pencil pencil-icon' onClick={() => editEmployee(employee.id!)}></i>
                        <div className='item-name-column'>
                            <div className='name'>
                              {employee.role === 'BarAdmin' && (<span className='role'>Admin - </span>)} {employee.firstName} {employee.lastName}
                            </div>
                          <div className='email'>{employee.email}</div>
                        </div>
                      </div>
                    );
                  }))
                }
              </div>
              {
                getUserRole() && getUserRole().toLowerCase() === 'BarAdmin'.toLowerCase() &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Button variant="primary" className="add-employee-button" onClick={() => createEmployee()}>Add Employee</Button>
                </div>
              }
              <div style={{height: '1px', borderTop: '1px solid #DADADA', marginTop: '50px'}}></div>
            </div>
            <div className='bar-employee-list-dashboard-section__content'>
              <div className='collapsible'>
                <div className='bar-employee-list-dashboard-section__content-heading'>
                  Inactive Employees
                </div>
                <div className='bar-employee-list-dashboard-section__content-collapse-icon'>
                  {
                    showInactive
                      ?
                      <i className='fa fa-minus' style={{ cursor: 'pointer' }} onClick={() => setShowInactive(false)}/>
                      : <i className='fa fa-plus' data-testid='bar-employee-inactive-list-expand'
                           style={{ cursor: 'pointer' }} onClick={() => setShowInactive(true)}/>
                  }
                </div>
              </div>
              {
                showInactive && <div className='bar-employee-list-dashboard-section__content-list'
                                     data-testid='bar-employee-list-content-inactive-list'>
                  {
                    (employeeList?.inActive.length) === 0 ? <div style={{fontSize: '14px'}}>No inactive employees</div>
                    : (employeeList?.inActive.map((employee, id) => {
                      return (
                        <div className='item' key={id}>
                          <i className='fa fa-pencil pencil-icon' onClick={() => editEmployee(employee.id!)}/>
                          <div className='item-name-column'>
                            <div className='name'>{employee.firstName} {employee.lastName}</div>
                            <div className='email'>{employee.email}</div>
                          </div>
                        </div>
                      );
                    }))
                  }
                </div>
              }
            </div>
          </div>
      }
      <EmployeeCreateModal />
      <EmployeeDetailsModal />
    </div>
  );
};

export default EmployeeList;