import { AppActions } from './actions';
import axios from 'axios';
import { ILoginReqPayload } from '../features/login/LoginRequestPayload.interface';
import { Dispatch } from 'redux';
import { BASE_API_URL } from '../shared/configs/appConfigs';
import { getUserName, getExpiryTime } from '../shared/utils/global-functions';
import { IJwtResponse } from '../shared/types/jwtResponse';
import jwt_decode from 'jwt-decode';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

async function performAsyncLogin(
  dispatch: Dispatch<AppActions>,
  reqModalObj: ILoginReqPayload
) {
  try {
    const res = await axios({
      method: 'post',
      url: `${BASE_API_URL}user/login`,
      data: reqModalObj,
    });
    if (res && res.data) {
      const encodedToken = res.data.accessToken;
      const token: IJwtResponse = jwt_decode(encodedToken);
      localStorage.setItem('accessToken', encodedToken);
      localStorage.setItem('selectedBarId', token?.barid);
      dispatch({
        type: LOGIN_SUCCEEDED,
        payload: {
          selectedBarId: token?.role !== 'Admin' ? parseInt(token?.barid) : -1,
        },
      });
    }
  } catch (err) {
    let errorMessage = 'Internal Server Error. Cannot Login.';
    if (err && err.response && err.response.data) {
      errorMessage = err.response.data.message;
    }
    dispatch({
      type: LOGIN_FAILED,
      payload: {
        authErrorMessage: errorMessage,
      },
    });
  }
}

export const performLogIn = (
  reqModalObj: ILoginReqPayload
): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: LOGIN_STARTED,
    });
    return performAsyncLogin(dispatch, reqModalObj);
  };
};

export const CHECK_ALREADY_LOGGED_IN = 'CHECK_ALREADY_LOGGED_IN';
export const checkIfAlreadyLoggedIn = (): AppActions => {
  let alreadyLoggedIn = false;
  if (localStorage.getItem('accessToken') !== null) {
    const exp = getExpiryTime();
    const expirationTime = (exp * 1000) - 60000;
    if (Date.now() < expirationTime) {
      alreadyLoggedIn = true;
    }
  }
  return {
    type: CHECK_ALREADY_LOGGED_IN,
    payload: {
      isAuth: alreadyLoggedIn,
    },
  };
};

export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const clearLogin = (): AppActions => {
  return {
    type: CLEAR_LOGIN,
  };
};

export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export async function performAsyncLogout(dispatch: Dispatch<AppActions>) {
  try {
    await axios({
      method: 'post',
      url: `${BASE_API_URL}user/logout`,
      data: { username: getUserName() },
    });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('selectedBarId');
    dispatch({
      type: LOGOUT_SUCCEEDED,
    });
  } catch (err) {
    dispatch({
      type: LOGOUT_FAILED,
    });
  }
}

export const performLogOut = (): ((dispatch: Dispatch) => Promise<void>) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: LOGOUT_STARTED,
    });
    return performAsyncLogout(dispatch);
  };
};

export const SET_ABOVE_EIGHTEEN = 'SET_ABOVE_EIGHTEEN';
export const setAboveEighteen = (): AppActions => {
  return {
    type: SET_ABOVE_EIGHTEEN,
  };
};

export const SET_SELECTED_BAR_ID = 'SET_SELECTED_BAR_ID';
export const setSelectedBarId = (selectedBarId: number): AppActions => {
  return {
    type: SET_SELECTED_BAR_ID,
    payload: {
      selectedBarId: selectedBarId,
    },
  };
};

export const SET_NOT_ABOVE_EIGHTEEN = 'SET_NOT_ABOVE_EIGHTEEN';
export const setNotAboveEighteen = (
  isDOBValid: boolean,
  isExpired = false
): AppActions => {
  return {
    type: SET_NOT_ABOVE_EIGHTEEN,
    payload: {
      ageErrorMessage: isExpired
        ? ''
        : isDOBValid
        ? 'You should be 18 years or older'
        : 'The DOB is incorrect',
    },
  };
};
